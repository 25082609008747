<template>
    <v-btn @click="pushChangeContent">Изменить</v-btn>
</template>

<script>
export default {
  props: ['submenu',
    'svgPath',
    'id_submenu',
    'id_svgPath',
    'change'
  ],
  data () {
    let id_list = []
    if (this.id_submenu) {
      id_list.push(this.id_submenu)
    }
    if (this.id_svgPath) {
      id_list.push(this.id_svgPath)
    }
    let _svgPath = this.valueDict(this.svgPath, this.id_svgPath)
    let _submenu = this.getT18nData(this.submenu, this.id_submenu)
    let promiseStore = this.$store
    let letThis = this
    new Promise(function (resolve, reject) {
      promiseStore.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = promiseStore.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_svgPath)) {
              _svgPath['value_rus'] = cont_item['value_rus']
              _svgPath['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_submenu)) {
              _submenu['value_rus'] = cont_item['value_rus']
              _submenu['value_eng'] = cont_item['value_eng']
            }
          }
        })
    })
    return {
      svgPathData: _svgPath,
      submenuData: _submenu
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'submenu': this.submenuData,
        'svgPath': this.svgPathData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'Submenu',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  }
}
</script>