<template>
   <v-card class="mx-auto borderCardMobile pb-12"
           width="100%"
           height="100%"
           outlined
   >
       <v-row style="padding-top: 48px; padding-bottom: 24px;">
           <v-col>
               <v-list-item two-line class="text-center pl-2 pr-2">
                   <v-list-item-content class="my-auto">
                       <v-list-item-title class="headlineCardSlogan headlineCardSloganMobile mt-4 mt-lg-4 mt-sm-4">
                           {{ titleComputed }}
                       </v-list-item-title>
                       <v-list-item-subtitle class="subtitleCardSlogan mt-8">
                           {{ subTitleComputed }}
                       </v-list-item-subtitle>
                   </v-list-item-content>
               </v-list-item>
           </v-col>
       </v-row>
       <numbers-mobile
          :text="$t('message.contentNumeric1')"
          big-num="25"
          small-num="000"
          symbol=" +"
          :svgPath= "svgNum1"
          id_text="19"
          id_big-num="20"
          id_small-num="21"
          id_unit="22"
          id_symbol="23"
          id_svgPath="24"
       />
       <numbers-mobile
          :text="$t('message.contentNumeric2')"
          big-num="3"
          small-num="000"
          :unit="$t('message.contentUnit2')"
          symbol=" +"
          :svgPath="svgNum2"
          id_text="1"
          id_big-num="2"
          id_small-num="3"
          id_unit="4"
          id_symbol="5"
          id_svgPath="6"
       />
     <numbers-mobile
          :text="$t('message.contentNumeric3')"
          big-num="1"
          :unit="$t('message.contentUnit3')"
          symbol=" +"
          :svgPath="svgNum3"
          id_text="7"
          id_big-num="8"
          id_small-num="9"
          id_unit="10"
          id_symbol="11"
          id_svgPath="12"
       />
     <numbers-mobile
          :text="$t('message.contentNumeric4')"
          big-num="95"
          symbol=" +"
          :svgPath="svgNum4"
          id_text="13"
          id_big-num="14"
          id_small-num="15"
          id_unit="16"
          id_symbol="17"
          id_svgPath="18"
       />
    </v-card>
</template>
<script>
import '../../plugins/i18n.js'
import { mdiAccountMultipleOutline, mdiBasketOutline, mdiCurrencyRub, mdiStore } from '@mdi/js'
import NumbersMobile from "@/components/ui/NumbersMobile.vue";
export default {
  props: [
    'title',
    'subTitle',
    'id_title',
    'id_subTitle',
    'change'
  ],
  components: { NumbersMobile },
  data () {
    let changeData = this.change !== undefined ? this.change : true
    let id_list = [this.id_title,
      this.id_subTitle
    ].filter(id => id !== undefined)
    let _title = this.getT18nData(this.title, this.id_title)
    let _subTitle = this.getT18nData(this.subTitle, this.id_subTitle)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let cont_item of content) {
          if (cont_item['id_content'] === parseInt(this.id_title)) {
            _title['value_rus'] = cont_item['value_rus']
            _title['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_subTitle)) {
            _subTitle['value_rus'] = cont_item['value_rus']
            _subTitle['value_eng'] = cont_item['value_eng']
          }
        }
      })
    return {
      changeData: changeData,
      titleData: _title,
      subTitleData: _subTitle,
      svgNum1: mdiBasketOutline,
      svgNum2: mdiStore,
      svgNum3: mdiCurrencyRub,
      svgNum4: mdiAccountMultipleOutline,
      absolute: true,
      overlay: false
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': this.id_content }
    }
  },
  computed: {
    titleComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.titleData['value_rus'] = this.title }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.titleData['value_eng'] = this.title }
      if (this.$i18n.locale == 'ru') { return this.titleData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.titleData['value_eng'] }
    },
    subTitleComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.subTitleData['value_rus'] = this.subTitle }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.subTitleData['value_eng'] = this.subTitle }
      if (this.$i18n.locale == 'ru') { return this.subTitleData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.subTitleData['value_eng'] }
    }
  }
}
</script>
