import axios from 'axios'

// state
export const state = {
  post: false,
  postLoad: false,
  postLoadState: ''
}

// getters
export const getters = {
  post: state => state.post,
  postLoad: state => state.postLoad,
  postLoadState: state => state.postLoadState
}

// mutations
export const mutations = {
  'SET_LOADSTATE' (state, { form, value }) { state[form + 'LoadState'] = value },
  'SHOW_LOADER' (state, { form, value }) { state[form + 'Load'] = value },
  'SHOW_FORM' (state, { form, value }) { state[form] = value }

}

// actions
export const actions = {
  'SEND_POST': async function ({ commit }, { name, pharm_man, post_man, email, text, photo, phone, good }) {
    commit('SET_LOADSTATE', { 'form': 'post', 'value': 'Пожалуйста, подождите' })
    commit('SHOW_LOADER', { 'form': 'post', 'value': true })
    let form_data = new FormData()
    form_data.append('name', name)
    form_data.append('pharm_man', pharm_man)
    form_data.append('post_man', post_man)
    form_data.append('email', email)
    form_data.append('text', text)
    form_data.append('photo', photo)
    form_data.append('phone', phone)
    form_data.append('good', good)
    axios.post('/api/add_post', form_data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(function (response) {
        commit('SET_LOADSTATE', { 'form': 'post', 'value': 'Отзыв успешно отправлен, он появится на сайте позже' })
      })
      .catch(function (error) {
        commit('SET_LOADSTATE', { 'form': 'post', 'value': 'Не удалось отправить отзыв' })
      })
  },
  'SHOW_FORM': async function ({ commit }, form) {
    commit('SHOW_FORM', { 'form': form, 'value': true })
  },
  'HIDE_FORM': async function ({ commit }, form) {
    commit('SHOW_FORM', { 'form': form, 'value': false })
  }
}
