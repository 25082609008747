import * as types from '../mutation-types'
import axios from 'axios'

// state
export const state = {
  count: 0,
  limit: 9,
  offset: 0,
  filters: [],
  news: [],
  news_on_page: 9,
}

// getters
export const getters = {
  count: state => state.count,
  limit: state => state.limit,
  offset: state => state.offset,
  filters: state => state.filters,
  news: state => state.news,
  news_on_page: state => state.news_on_page,
  page_count: state => {
    let _page_count = Math.floor(state.count / state.news_on_page)
    if (state.count % state.news_on_page > 0)
        _page_count += 1
      return _page_count
  }
}

// mutations
export const mutations = {
  [types.SET_NEWS] (state, { news }) { state.news = news },
  [types.SET_NEWS_COUNT] (state, {count}) {state.count = count},
  [types.SET_LIMIT_AND_OFFSET] (state, { limit, offset, filters }) {
    if (typeof (filters) !== 'undefined') {
      state.filters = filters
    }
    state.limit = limit
    state.offset = offset
  }
}

// actions
export const actions = {
  'GET_COUNT': async function ({ commit }){
    const { data } = await axios.post(
      '/api/news_count'
    )
    commit(types.SET_NEWS_COUNT, {count: data['result']})
  },
  'GET_NEWS': async function ({ commit }, { filter, limit, offset }) {
    commit(types.SET_LIMIT_AND_OFFSET, {limit, offset, filter})
    const { data } = await axios.post(
      '/api/news',
      { 'filters': state.filters, 'limit': limit, 'offset': offset }
    )
    commit(types.SET_NEWS, { news: data['result'] })
  }
}
