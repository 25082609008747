<template>
  <swiper-slide v-if="show">
    <a v-if="!thumbsData" :href="pathImgComputed" target="_blank">
      <img height="400px" :src="pathImgComputed"/>
    </a>
    <div v-if="!thumbsData  && isAuth">
      <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
      <v-btn v-if="this.changeData && this.addedData" @click="deleteAddedContent">Удалить</v-btn>
      <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать</v-btn>
      <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать</v-btn>
    </div>
    <a v-if="thumbsData" :href="pathImgComputed" target="_blank">
      <img :src="pathImgComputed"/>
    </a>
  </swiper-slide>
</template>

<script>

import { swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'LicenseSlide',
  components: { swiperSlide },
  props: ['pathImg',
    'id_pathImg',
    'added',
    'change',
    'thumbs',
    'hidden'
  ],
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let thumbsData = this.thumbs !== undefined ? this.thumbs : false
    let id_list = []
    if (this.id_pathImg) {
      id_list.push(this.id_pathImg)
    }
    let _pathImg = this.valueDict(this.pathImg, this.id_pathImg)
    let _srcImg = this.valueDict(this.pathImg, this.id_pathImg)
    _srcImg['value_rus'] = '../../../public' + _srcImg['value_rus']
    _srcImg['value_eng'] = '../../../public' + _srcImg['value_eng']
    _srcImg['file'] = '../../../public' + _srcImg['file']
    let letThis = this
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = letThis.$store.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_pathImg)) {
              _pathImg['value_rus'] = cont_item['value_rus']
              _pathImg['value_eng'] = cont_item['value_eng']
              _srcImg['value_rus'] = cont_item['value_rus']
              _srcImg['value_eng'] = cont_item['value_eng']
              if (cont_item['file']) {
                _srcImg['file'] = cont_item['file']
                _pathImg['file'] = cont_item['file']
              }
            }
          }
          letThis.checkContent = true
        })
    })
    return {
      checkContent: false,
      deleted: false,
      thumbsData: thumbsData,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      pathImgData: _pathImg,
    }
  },
  methods: {
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'file': value,
        'id_content': id_content }
    },
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' },
    pushChangeContent () {
      let content_to_change = { 'pathImg': this.pathImgData }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'CardLogo',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_pathImg]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_pathImg]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_pathImg]
      })
      this.hiddenData = true
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    show () {
      return !this.deleted
    },
    pathImgComputed () {
      if (!this.changeData) {
        if (File.prototype.isPrototypeOf(this.pathImg)) {
          this.pathImgData['value_rus'] = URL.createObjectURL(this.pathImg)
          this.pathImgData['value_eng'] = URL.createObjectURL(this.pathImg)
          this.pathImgData['file'] = URL.createObjectURL(this.pathImg)
        } else {
          this.pathImgData['value_rus'] = this.pathImg
          this.pathImgData['value_eng'] = this.pathImg
        }
      }
      if (this.pathImgData['file']) { return this.pathImgData['file'] }
      if (this.$i18n.locale == 'ru') { return this.pathImgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.pathImgData['value_eng'] }
    },
    srcImgComputed () {
      if (!this.changeData) {
        if (File.prototype.isPrototypeOf(this.pathImg)) {
          this.srcImgData['value_rus'] = URL.createObjectURL(this.pathImg)
          this.srcImgData['value_eng'] = URL.createObjectURL(this.pathImg)
          this.srcImgData['file'] = URL.createObjectURL(this.pathImg)
        } else {
          this.srcImgData['value_rus'] = this.pathImg
          this.srcImgData['value_eng'] = this.pathImg
        }
      }
      if (this.srcImgData['file']) { return this.srcImgData['file'] }
      if (this.$i18n.locale == 'ru') { return this.srcImgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.srcImgData['value_eng'] }
    }
  }
}
</script>

<style scoped>

</style>