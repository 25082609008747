<template>
  <div class="products-submenu">
    <v-btn-toggle v-for="item in categories"
                  :key="item.id"
    >
      <v-btn v-if="lang=='ru'"
             @click="getProductGroup(item)"
             class="mx-auto"
             outlined
      >
        {{ item.name_rus }}
      </v-btn>
      <v-btn v-else
             @click="getProductGroup(item)"
             class="mx-auto"
             outlined
      >
        {{ item.name_en }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>

import '../../plugins/i18n'
export default {
  components: { },
  name: 'ProductsSubmenu',
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    categories () {
      return this.$store.getters['groups/get_data']
    }
  },
  created () {
    this.$store.dispatch('groups/GET_DATA')
  },
  methods: {
    getProductGroup (item) {
      this.$store.dispatch('products/SET_FILTER', { filters: [item.id] })
      this.$store.dispatch('products/SET_QUANTITY', { filters: [item.id] })
      if (!(this.$route.name == 'products_with_group' && this.$route.params.id_group == item.id)) {
        this.$router.push({ name: 'products_with_group', params: { id_group: item.id } })
      };
    }
  }
}
</script>
