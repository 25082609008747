<template>
   <v-card class="mx-auto borderCard text-center"
           width="100%"
           height="100%"
           outlined
   >
       <v-icon class="mt-lg-9 mt-md-5 mt-sm-0">{{ svgPathComputed }}</v-icon>
       <v-list-item two-line class="cardItem">
           <v-list-item-content>
               <v-list-item-title class="headlineCardChiliad mb-lg-2 mb-md-1 mt-lg-1 mt-md-1">{{ bigNumComputed }}
                   <span class="headlineCardHundred">{{ smallNumComputed }}</span>
                   <span class="headlineCardHundred"> {{ unitComputed }}</span>
                   <span class="headlineCardHundred"> {{ symbolComputed }}</span>
               </v-list-item-title>
               <v-list-item class="subtitleCard mt-lg-2 mt-md-0 mb-lg-6 mb-md-5 mb-sm-12 text-center">
                   <p class="mx-auto">{{ textComputed }}</p>
               </v-list-item>
               <div v-if="isAuth">
                 <v-btn v-if="changeData" @click="pushChangeContent">Изменить</v-btn>
               </div>
        </v-list-item-content>
      </v-list-item>
   </v-card>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: [ 'text',
    'bigNum',
    'smallNum',
    'unit',
    'symbol',
    'svgPath',
    'id_text',
    'id_bigNum',
    'id_smallNum',
    'id_unit',
    'id_symbol',
    'id_svgPath',
    'change'
  ],
  data () {
    let changeData = this.change !== undefined ? this.change : true
    let id_list = [this.id_text,
      this.id_bigNum,
      this.id_smallNum,
      this.id_unit,
      this.id_symbol,
      this.id_svgPath
    ].filter(id => id !== undefined)
    let _text = this.getT18nData(this.text, this.id_text)
    let _bigNum = this.valueDict(this.bigNum, this.id_bigNum)
    let _smallNum = this.valueDict(this.smallNum, this.id_smallNum)
    let _unit = this.valueDict(this.unit, this.id_unit)
    let _symbol = this.valueDict(this.symbol, this.id_symbol)
    let _svgPath = this.valueDict(this.svgPath, this.id_svgPath)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let cont_item of content) {
          if (cont_item['id_content'] === parseInt(this.id_text)) {
            _text['value_rus'] = cont_item['value_rus']
            _text['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_bigNum)) {
            _bigNum['value_rus'] = cont_item['value_rus']
            _bigNum['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_smallNum)) {
            _smallNum['value_rus'] = cont_item['value_rus']
            _smallNum['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_unit)) {
            _unit['value_rus'] = cont_item['value_rus']
            _unit['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_symbol)) {
            _symbol['value_rus'] = cont_item['value_rus']
            _symbol['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_svgPath)) {
            _svgPath['value_rus'] = cont_item['value_rus']
            _svgPath['value_eng'] = cont_item['value_eng']
          }
        }
      })
    return {
      textData: _text,
      bigNumData: _bigNum,
      smallNumData: _smallNum,
      unitData: _unit,
      symbolData: _symbol,
      svgPathData: _svgPath,
      changeData: changeData
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': this.id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'text': this.textData,
        'bigNum': this.bigNumData,
        'smallNum': this.smallNumData,
        'unit': this.unitData,
        'symbol': this.symbolData,
        'svgPath': this.svgPathData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'CardsWithNumbers',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  },
  computed: {
    textComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textData['value_rus'] = this.text }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textData['value_eng'] = this.text }
      if (this.$i18n.locale == 'ru') { return this.textData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textData['value_eng'] }
    },
    bigNumComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.bigNumData['value_rus'] = this.bigNum }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.bigNumData['value_eng'] = this.bigNum }
      if (this.$i18n.locale == 'ru') { return this.bigNumData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.bigNumData['value_eng'] }
    },
    smallNumComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.smallNumData['value_rus'] = this.smallNum }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.smallNumData['value_eng'] = this.smallNum }
      if (this.$i18n.locale == 'ru') { return this.smallNumData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.smallNumData['value_eng'] }
    },
    unitComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.unitData['value_rus'] = this.unit }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.unitData['value_eng'] = this.unit }
      if (this.$i18n.locale == 'ru') { return this.unitData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.unitData['value_eng'] }
    },
    symbolComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.symbolData['value_rus'] = this.symbol }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.symbolData['value_eng'] = this.symbol }
      if (this.$i18n.locale == 'ru') { return this.symbolData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.symbolData['value_eng'] }
    },
    svgPathComputed () {
      if (!this.changeData) {
        this.svgPathData['value_rus'] = this.svgPath
        this.svgPathData['value_eng'] = this.svgPath
      }
      if (this.$i18n.locale == 'ru') { return this.svgPathData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.svgPathData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>
