<template>
  <v-toolbar class="menu-toolbar">
    <div class="mobileMenuPlace">
      <v-menu bottom transition="scroll-x-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="mobileMenu">
            <v-icon color="#298B94">mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-btn icon>
            <v-icon color="#298B94">mdi-close</v-icon>
          </v-btn>
        </v-list>
        <hr>
        <v-list style="height: 100vh; width: 82vh!important;">
          <v-btn-toggle v-model="toggle_exclusive" style="flex-direction: column;">
            <v-list-item class="list">
              <icon-btn-mobile
                      to_name="about_company"
                      :submenu="$t('message.submenu1')"
                      :id_submenu="71"
                      btn_class="menuBtnMobile"
                      color="cyan darken-4"
              />
            </v-list-item>
            <v-list-item>
              <icon-btn-mobile
                      to_name="management"
                      :submenu="$t('message.aboutUsSubmenu1')"
                      :svg-path="svgPath1"
                      :id_submenu="52"
                      :id_svg-path="53"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
              />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="documents"
                      :submenu="$t('message.aboutUsSubmenu2')"
                      :svg-path="svgPath2"
                      :id_submenu="54"
                      :id_svg-path="55"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="types_of_activities"
                      :submenu="$t('message.aboutUsSubmenu3')"
                      :svg-path="svgPath3"
                      :id_submenu="56"
                      :id_svg-path="57"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="anticorruption_policy"
                      :submenu="$t('message.aboutUsSubmenu4')"
                      :svg-path="svgPath4"
                      :id_submenu="58"
                      :id_svg-path="59"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="news"
                      :submenu="$t('message.aboutUsSubmenu5')"
                      :svg-path="svgPath5"
                      :id_submenu="60"
                      :id_svg-path="61"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="products"
                      :submenu="$t('message.submenu5')"
                      :id_submenu="73"
                      btn_class="menuBtnMobile"
                      color="cyan darken-4"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="partnership"
                      :submenu="$t('message.submenu2')"
                      :id_submenu="75"
                      btn_class="menuBtnMobile"
                      color="cyan darken-4"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="logistics"
                      :submenu="$t('message.partnershipSubmenu1')"
                      :svg-path="svgPath6"
                      :id_submenu="62"
                      :id_svg-path="63"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="for_suppliers"
                      :submenu="$t('message.partnershipSubmenu2')"
                      :svg-path="svgPath7"
                      :id_submenu="64"
                      :id_svg-path="65"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="our_partners"
                      :submenu="$t('message.partnershipSubmenu3')"
                      :svg-path="svgPath8"
                      :id_submenu="66"
                      :id_svg-path="67"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
                />
            </v-list-item>
            <v-list-item style="display: none">
                <icon-btn-mobile
                      to_name="our_clients"
                      :submenu="$t('message.partnershipSubmenu4')"
                      :svg-path="svgPath9"
                      :id_submenu="68"
                      :id_svg-path="69"
                      btn_class="menuBtnMobile undermenuBtnMobile"
                      color="blue-grey darken-2"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="career"
                      :submenu="$t('message.submenu3')"
                      :id_submenu="77"
                      btn_class="menuBtnMobile"
                      color="cyan darken-4"
                />
            </v-list-item>
            <v-list-item>
                <icon-btn-mobile
                      to_name="contacts"
                      :submenu="$t('message.submenu4')"
                      :id_submenu="79"
                      btn_class="menuBtnMobile"
                      color="cyan darken-4"
                />
            </v-list-item>
          </v-btn-toggle>
        </v-list>
      </v-menu>
    </div>
    <div class="mobileLogoPlace">
      <v-btn :to="{ name: 'main',
                params: { main: '/' },
               }"
              icon
              :ripple="false"
              class="logo"
      >
        <img src="../../../public/img/logos/logo22.png"/>
      </v-btn>
    </div>
    <div class="mobileFeedBack">
      <v-btn icon @click.stop="dialog = true" class="mobileMenu">
        <v-icon color="#BACF34">mdi-email-edit-outline</v-icon>
      </v-btn>
        <v-row justify="center">
          <v-dialog v-model="dialog"
                    max-width="568"
          >
            <v-card class="pa-8">
              <v-card-title class="headline" style="color:#009688">
                Обратная связь
              </v-card-title>
              <v-card-text>
                <Feedback></Feedback>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
    </div>
    <div class="flex-grow-1"></div>
    <v-toolbar-items class="no-indentation">
      <v-btn-toggle v-model="toggle_exclusive"
                    class="menuBtnToggle"
      >
       <icon-btn v-if="!isAuth"
         to_name="about_company"
         :svg-path="svgBtnPath1"
         :submenu="$t('message.submenu1')"
         :id_svg-path="70"
         :id_submenu="71"
       />
       <div v-if="isAuth">
         <icon-btn
           to_name="about_company"
           :svg-path="svgBtnPath1"
           :submenu="$t('message.submenu1')"
           :id_svg-path="70"
           :id_submenu="71"
         />
         <change-icon-btn-click
           to_name="about_company"
           :svg-path="svgBtnPath1"
           :submenu="$t('message.submenu1')"
           :id_svg-path="70"
           :id_submenu="71"
         />
       </div>
       <icon-btn v-if="!isAuth"
           to_name="products"
           :svg-path="svgPath10"
           :submenu="$t('message.submenu5')"
           :id_svg-path="72"
           :id_submenu="73"
         />
       <div v-if="isAuth">
         <icon-btn
           to_name="products"
           :svg-path="svgPath10"
           :submenu="$t('message.submenu5')"
           :id_svg-path="72"
           :id_submenu="73"
         />
         <change-icon-btn-click
           v-if="isAuth"
           to_name="products"
           :svg-path="svgPath10"
           :submenu="$t('message.submenu5')"
           :id_svg-path="72"
           :id_submenu="73"
         />
       </div>
       <icon-btn v-if="!isAuth"
           to_name="partnership"
           :svg-path="svgBtnPath2"
           :submenu="$t('message.submenu2')"
           :id_svg-path="74"
           :id_submenu="75"
         />
       <div v-if="isAuth">
         <icon-btn
           to_name="partnership"
           :svg-path="svgBtnPath2"
           :submenu="$t('message.submenu2')"
           :id_svg-path="74"
           :id_submenu="75"
         />
         <change-icon-btn-click
           v-if="isAuth"
           to_name="partnership"
           :svg-path="svgBtnPath2"
           :submenu="$t('message.submenu2')"
           :id_svg-path="74"
           :id_submenu="75"
         />
       </div>
       <icon-btn v-if="!isAuth"
           to_name="career"
           :svg-path="svgBtnPath3"
           :submenu="$t('message.submenu3')"
           :id_svg-path="76"
           :id_submenu="77"
         />
       <div v-if="isAuth">
         <icon-btn
           to_name="career"
           :svg-path="svgBtnPath3"
           :submenu="$t('message.submenu3')"
           :id_svg-path="76"
           :id_submenu="77"
         />
         <change-icon-btn-click
           v-if="isAuth"
           to_name="career"
           :svg-path="svgBtnPath3"
           :submenu="$t('message.submenu3')"
           :id_svg-path="76"
           :id_submenu="77"
         />
       </div>
       <icon-btn v-if="!isAuth"
           to_name="contacts"
           :svg-path="svgBtnPath4"
           :submenu="$t('message.submenu4')"
           :id_svg-path="78"
           :id_submenu="79"
         />
       <div v-if="isAuth">
         <icon-btn
           to_name="contacts"
           :svg-path="svgBtnPath4"
           :submenu="$t('message.submenu4')"
           :id_svg-path="78"
           :id_submenu="79"
         />
         <change-icon-btn-click
           v-if="isAuth"
           to_name="contacts"
           :svg-path="svgBtnPath4"
           :submenu="$t('message.submenu4')"
           :id_svg-path="78"
           :id_submenu="79"
         />
       </div>
      </v-btn-toggle>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
import '../../plugins/i18n.js'
import Feedback from '../ui/FeedbackForm'
import IconBtn from "@/components/ui/IconBtn.vue"
import ChangeIconBtnClick from "@/components/ui/ChangeContent/ChangeIconBtnClick.vue"
import IconBtnMobile from "@/components/ui/IconBtnMobile.vue";
import {
  mdiHomeOutline,
  mdiHexagonMultipleOutline,
  mdiHumanMaleHeight,
  mdiPhoneLogOutline,
  mdiAccountCheckOutline,
  mdiFileDocumentBoxCheckOutline,
  mdiEqualizerOutline,
  mdiShieldCheckOutline,
  mdiBullhornOutline,
  mdiTruckFast,
  mdiCarInfo,
  mdiAccountTieOutline,
  mdiAccountHeartOutline,
  mdiFolderOpenOutline,
  mdiFolderHeartOutline
} from '@mdi/js'
export default {
  components: {IconBtnMobile, IconBtn, Feedback, ChangeIconBtnClick },
  data () {
    return {
      dialog: false,
      toggle_exclusive: undefined,
      locale: 'ru',
      absolute: true,
      overlay: false,
      svgPath1: mdiAccountCheckOutline,
      svgPath2: mdiFileDocumentBoxCheckOutline,
      svgPath3: mdiEqualizerOutline,
      svgPath4: mdiShieldCheckOutline,
      svgPath5: mdiBullhornOutline,
      svgPath6: mdiTruckFast,
      svgPath7: mdiCarInfo,
      svgPath8: mdiAccountTieOutline,
      svgPath9: mdiAccountHeartOutline,
      svgPath10: mdiFolderOpenOutline,
      svgBtnPath1: mdiHomeOutline,
      svgBtnPath2: mdiHexagonMultipleOutline,
      svgBtnPath3: mdiHumanMaleHeight,
      svgBtnPath4: mdiPhoneLogOutline
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>
