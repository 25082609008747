<template>
  <transition name="page" mode="out-in">
    <router-view/>
  </transition>
</template>

<script>
export default {
  name: 'Child'
}
</script>
<style lang="scss">
  .page-enter-active,
  .page-leave-active {
    transition: opacity .2s;
  }

  .page-enter,
  .page-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .2s
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

</style>
