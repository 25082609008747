import axios from 'axios';

import * as types from '../mutation-types';

// state
export const state = {
  data : {}
};

// getters
export const getters = {
  my_data : (state) => (slider) => {
    var value = state.data[slider];
    if (value === undefined) {
      return []
    }
    return state.data[slider];
  }
};

// mutations
export const mutations = {
  SET_DATA(state, {data, slider}) {
    state.data[slider] = data
    state.data = Object.assign({}, state.data);
  },
};

// actions
export const actions = {
  'SET_SLIDER' : async function({commit, state}, {slider, filters}) {
    var newFilter = {filters : filters, limit : 99999, offset : 0};
    var path = '/api/product_group';
    const {data} = await axios.post(path, newFilter);
    commit('SET_DATA', {data : data['result'], slider : slider});
  }
};
