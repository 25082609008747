<template>
  <div style="width: 100%!important;">
    <v-btn-toggle class="menuBtnToggle">
      <div class="subMenuBlock">
        <Submenu :submenu="$t('message.aboutUsSubmenu1')"
                 :push="pushManagement"
                 :svg-path="svgPath1"
                 :id_submenu="52"
                 :id_svg-path="53"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.aboutUsSubmenu1')"
                :svg-path="svgPath1"
                :id_submenu="52"
                :id_svg-path="53"
        />
      </div>
      <div class="subMenuBlock">
        <Submenu :submenu="$t('message.aboutUsSubmenu2')"
                 :push="pushDocuments"
                 :svg-path="svgPath2"
                 :id_submenu="54"
                 :id_svg-path="55"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.aboutUsSubmenu2')"
                :svg-path="svgPath2"
                :id_submenu="54"
                :id_svg-path="55"
        />
      </div>
      <div class="subMenuBlock">
        <Submenu :submenu="$t('message.aboutUsSubmenu3')"
                 :push="pushTypesOfActivities"
                 :svg-path="svgPath3"
                 :id_submenu="56"
                 :id_svg-path="57"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.aboutUsSubmenu3')"
                :svg-path="svgPath3"
                :id_submenu="56"
                :id_svg-path="57"
        />
      </div>
      <div class="subMenuBlock">
        <Submenu :submenu="$t('message.aboutUsSubmenu4')"
                 :push="pushAnticorruptionPolicy"
                 :svg-path="svgPath4"
                 :id_submenu="58"
                 :id_svg-path="59"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.aboutUsSubmenu4')"
                :svg-path="svgPath4"
                :id_submenu="58"
                :id_svg-path="59"
        />
      </div>
      <div class="subMenuBlock">
        <Submenu :submenu="$t('message.aboutUsSubmenu5')"
                 :push="pushAllNews"
                 :svg-path="svgPath5"
                 :id_submenu="60"
                 :id_svg-path="61"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.aboutUsSubmenu5')"
                :svg-path="svgPath5"
                :id_submenu="60"
                :id_svg-path="61"
        />
      </div>
    </v-btn-toggle>
  </div>
</template>
<script>
import Submenu from '../ui/Submenu'
import ChangeSubmenuClick from "@/components/ui/ChangeContent/ChangeSubmenuClick.vue";
import { mdiAccountCheckOutline,
  mdiFileDocumentBoxCheckOutline,
  mdiEqualizerOutline,
  mdiShieldCheckOutline,
  mdiBullhornOutline,
} from '@mdi/js'
export default {
  components: { Submenu, ChangeSubmenuClick },
  props: ['toggle_none'],
  name: 'AboutCompanySubmenu',
  data () {
    return {
      svgPath1: mdiAccountCheckOutline,
      svgPath2: mdiFileDocumentBoxCheckOutline,
      svgPath3: mdiEqualizerOutline,
      svgPath4: mdiShieldCheckOutline,
      svgPath5: mdiBullhornOutline
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  },
  methods: {
    pushManagement () {
      this.$router.push('/management')
    },
    pushDocuments () {
      this.$router.push('/documents')
    },
    pushTypesOfActivities () {
      this.$router.push('/types_of_activities')
    },
    pushAnticorruptionPolicy () {
      this.$router.push('/anticorruption_policy')
    },
    pushAllNews () {
      this.$router.push('/news')
    }
  }
}
</script>
