<template>
  <div>
    <div>
      <a v-if="is_link" :href="imgSrcComputed" target="">
        <img :src="imgSrcComputed" :class="imgClass"/>
      </a>
      <img v-else :src="imgSrcComputed" :class="imgClass"/>
    </div>
    <div v-if="isAuth">
      <v-btn v-if="changeData" @click="pushChangeContent">Изменить</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentImage',
  props: [
    'imgSrc',
    'id_imgSrc',
    'is_link',
    'imgClass',
    'change'
  ],
  data () {
    let changeData = this.change !== undefined ? this.change : true
    let id_list = [this.id_imgSrc].filter(id => id !== undefined)
    let _imgSrc = this.valueDict(this.imgSrc, this.id_imgSrc)
    let letThis = this
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = letThis.$store.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_imgSrc)) {
              _imgSrc['value_rus'] = cont_item['value_rus']
              _imgSrc['value_eng'] = cont_item['value_eng']
              if (cont_item['file']) {
                _imgSrc['file'] = cont_item['file']
              }
            }
          }
          letThis.checkContent = true
        })
    })
    return {
      checkContent: false,
      deleted: false,
      changeData: changeData,
      imgSrcData: _imgSrc
    }
  },
  methods: {
    valueDict (value, id_content) {
      return {
        'value_rus': value,
        'value_eng': value,
        'file': value,
        'id_content': id_content
      }
    },
    pushChangeContent () {
      let content_to_change = { 'pathImg': this.imgSrcData }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'CardLogo',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  },
  computed: {
    imgSrcComputed () {
      if (!this.changeData) {
        if (File.prototype.isPrototypeOf(this.imgSrc)) {
          this.imgSrcData['value_rus'] = URL.createObjectURL(this.imgSrc)
          this.imgSrcData['value_eng'] = URL.createObjectURL(this.imgSrc)
          this.imgSrcData['file'] = URL.createObjectURL(this.imgSrc)
        } else {
          this.imgSrcData['value_rus'] = this.imgSrc
          this.imgSrcData['value_eng'] = this.imgSrc
        }
      }
      if (this.imgSrcData['file']) {
        return this.imgSrcData['file']
      }
      if (this.$i18n.locale == 'ru') {
        return this.imgSrcData['value_rus']
      }
      if (this.$i18n.locale == 'en-US') {
        return this.imgSrcData['value_eng']
      }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>
