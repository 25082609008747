import axios from 'axios'
import Cookies from 'js-cookie'
import * as types from '../mutation-types'

// state
export const state = {
  user: null,
  currentChanegeUser: null,
  userList: [],
  settings: null,
  token: Cookies.get('token')
}

// getters
export const getters = {
  user: state => state.user,
  currentChanegeUser: state => state.currentChanegeUser,
  userList: state => state.userList,
  settings: state => state.settings,
  token: state => state.token,
  check: state => state.user !== null
}

// mutations
export const mutations = {
  [types.SAVE_TOKEN] (state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null,
      SameSite: 'Strict' })
  },
  [types.GET_TOKEN_FAIL] (state, ctx) { console.log(ctx) },
  [types.GET_TOKEN_SUCCESS] (state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null,
      SameSite: 'Strict' })
    this.dispatch('auth/getUserSettings', { 'token': token })
  },
  [types.GET_USER_SETTINGS_SUCCES] (state, { settings }) { state.settings = settings },
  [types.SET_CURRENT_CHANGE_USER] (state, { settings, token }) {
    settings['token'] = token
    state.currentChanegeUser = settings
  },
  [types.GET_USER_LIST_SUCCES] (state, { userList }) { state.userList = userList },
  [types.FETCH_USER_SUCCESS] (state, { user }) { state.user = user },
  [types.LOGOUT] (state) {
    state.user = null
    state.settings = null
    state.token = null
    Cookies.remove('token')
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.token = null
    Cookies.remove('token')
  },

  [types.LOGOUT] (state) {
    state.user = null
    state.settings = null
    state.token = null

    Cookies.remove('token')
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user
  }
}

// actions
export const actions = {
  async  getUserList  ({ commit }, ctx) {
    try {
      const { data } = await axios.post('/api/user/get_list', { 'token': this.state.auth.token })
      if (!data.error) {
        commit(types.GET_USER_LIST_SUCCES, { userList: data.result })
      }
    } catch (e) {
      commit(types.GET_TOKEN_FAIL, ctx)
    }
  },
  async setCurrentChanegeUser ({ commit }, ctx) {
    if (ctx.token === '') {
      commit(
        types.SET_CURRENT_CHANGE_USER,
        {
          settings: {
            username: 'новый пользователь',
            password: '',
            permission: [
              { permission: 'showUsers', is_active: false },
              { permission: 'showReferenceBooks', is_active: false },
              { permission: 'showRegisterFields', is_active: false },
              { permission: 'showDeleteButtonInRegister', is_active: false },
              { permission: 'showPrintInfoOrganization', is_active: false },
              { permission: 'showPrintDirection', is_active: false },
              { permission: 'showRegisterFields', is_active: false },
              { permission: 'showPrintDirection', is_active: false },
              { permission: 'showAddButtonInRegister', is_active: false },
              { permission: 'showWaitingList', is_active: false }
            ],
            required_filters: [],
            default_values: [],
            disable_fields: [],
            print_settings: [],
            hidden_fields: []
          },
          token: ctx.token
        }
      )
      return
    }
    try {
      const { data } = await axios.post('/api/user/get_settings', ctx)
      commit(types.SET_CURRENT_CHANGE_USER, { settings: data.result, token: ctx.token })
    } catch (e) {
      commit(types.GET_TOKEN_FAIL, ctx)
    }
  },
  async  getUserSettings  ({ commit }, ctx) {
    try {
      const { data } = await axios.post('/api/user/get_settings', ctx)
      commit(types.GET_USER_SETTINGS_SUCCES, { settings: data.result })
    } catch (e) {
      commit(types.GET_TOKEN_FAIL, ctx)
    }
  },
  async  logout  ({ commit }, ctx) {
    commit(types.LOGOUT)
  },
  async  getToken  ({ commit }, ctx) {
    try {
      console.log('getToken')
      console.log(ctx)
      const { data } = await axios.post('/api/user/get_token', ctx)
      console.log('getToken', data)
      commit(types.GET_TOKEN_SUCCESS, { token: data.result })
    } catch (e) {
      console.log(e)
      commit(types.GET_TOKEN_FAIL, ctx)
    }
  },
  changeUser ({ commit }, ctx) {
    try {
      console.log('getToken')
      console.log(ctx)
      const { data } = axios.post('/api/user/change_user', ctx)
      console.log(data)
    } catch (e) {
    }
  },
  saveToken ({ commit, dispatch }, payload) {
    commit(types.SAVE_TOKEN, payload)
  },

  async fetchUser ({ commit }) {
    try {
      const { data } = await axios.get('/api/settings/user/info/')
      commit(types.FETCH_USER_SUCCESS, { user: data })
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
    }
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  async fetchOauthUrl (ctx, { provider }) {
    const { data } = await axios.post(`/api/oauth/${provider}`)

    return data.url
  }
}
