<template>
    <div v-if="loadedDefaultContent && loadedAddedContent">
        <swiper
            ref="swiper"
            :options="swiperOptionEn"
            class="no-indentation mainSwiper">
        <div class="parallax-bg"
             slot="parallax-bg"
             data-swiper-parallax="-23%"
             ></div>
        <main-slider-slide v-for="(content, index) in contentToShow" :key="index"

            :slide-header="$t(content['slideHeader'])"
            :slide-text="$t(content['slideText'])"
            :slide-btn-text ="$t(content['slideBtnText'])"
            :slide-btn-link="content['slideBtnLink']"
            :id_slideHeader="content['id_slideHeader']"
            :id_slideText="content['id_slideText']"
            :id_slideBtnText="content['id_slideBtnText']"
            :hidden="content['hidden']"
            :added="content['added']"
            @slideUpdated="updateSlider(index)"
        />
        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
      <v-btn v-if="isAuth" @click="add_content">Добавить слайд</v-btn>
    </div>
</template>

<script>
import '../../plugins/i18n'
import 'swiper/dist/css/swiper.css'
import MainSliderSlide from "@/components/ui/MainSliderSlide.vue";
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import MainSlisderSlide from "@/components/ui/MainSliderSlide.vue";
export default {
  components: {
      MainSlisderSlide,
    swiper,
    swiperSlide
  },
  data () {
    let addedContent = []
    let defaultContent = [
      {
        slideHeader: 'message.sliderHeader1',
        slideText: 'message.sliderText1',
        slideBtnText: 'message.sliderButton1',
        slideBtnLink: 'about_company',
        id_slideHeader: 116,
        id_slideText: 117,
        id_slideBtnText: 118,
        id_slideBtnLink: 345,
        hidden: true
      },
      {
        slideHeader: 'message.sliderHeader2',
        slideText: 'message.sliderText2',
        slideBtnText: 'message.sliderButton2',
        slideBtnLink: '/types_of_activities',
        id_slideHeader: 119,
        id_slideText: 120,
        id_slideBtnText: 121,
        id_slideBtnLink: 346,
        hidden: true
      },
      {
        slideHeader: 'message.sliderHeader3',
        slideText: 'message.sliderText3',
        slideBtnText: 'message.sliderButton3',
        slideBtnLink: '/partnership',
        id_slideHeader: 122,
        id_slideText: 123,
        id_slideBtnText: 124,
        id_slideBtnLink: 347,
        hidden: true
      }
    ]
    let letThis = this
    let idList = defaultContent.map(cont => [cont['id_slideHeader'], cont['id_slideText'], cont['id_slideBtnText'], cont['id_slideBtnLink']])
    letThis.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': idList })
      .then(() => {
        let hiddenContent = letThis.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          letThis.defaultContent[ind]['hidden'] = hiddenContent[ind]
        }
        letThis.loadedDefaultContent = true
      })
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
        'type': 'MainSliderSlide'
      }).then(() => {
        addedContent = letThis.$store.getters['contentChanges/added_content_by_type']('MainSliderSlide')
        letThis.addedContent = addedContent
        letThis.loadedAddedContent = true
      })
    })
    return {
      loadedAddedContent: false,
      loadedDefaultContent: false,
      defaultContent: defaultContent,
      addedContent: addedContent,
      swiperOptionEn: {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        parallax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionRu: {
        speed: 600,
        loop: true,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        parallax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    contentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent
      } else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_slideHeader'] = this.get_field_id(content,'slideHeader')
        newAddedContent['id_slideText'] = this.get_field_id(content,'slideText')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    }
  },
  methods: {
    updateSlider (index) {
      if (index === 0) {
        let firstSlide = this.$refs.swiper.swiper.slides[1]
        let firstSlideHeader = firstSlide.querySelector('.swiperHeader')
        let firstSlideText = firstSlide.querySelector('.swiperText')
        let afterLastSlide = this.$refs.swiper.swiper.slides[this.$refs.swiper.swiper.slides.length - 1]
        afterLastSlide.querySelector('.swiperHeader').textContent = firstSlideHeader.textContent
        afterLastSlide.querySelector('.swiperText').textContent = firstSlideText.textContent
        let firstSlideBtn = firstSlide.querySelector('.swiperBtn').children[0]
        if (firstSlideBtn === undefined) {
          return
        }
        if (firstSlideBtn.tagName === 'BUTTON') {
          afterLastSlide.querySelector('.swiperBtn').children[0].textContent = firstSlideBtn.textContent
        }
      }
      if (index === this.contentToShow.length - 1) {
        let lastSlide = this.$refs.swiper.swiper.slides[this.$refs.swiper.swiper.slides.length - 2]
        let lastSlideHeader = lastSlide.querySelector('.swiperHeader')
        let lastSlideText = lastSlide.querySelector('.swiperText')
        let beforeFirstSlide = this.$refs.swiper.swiper.slides[0]
        beforeFirstSlide.querySelector('.swiperHeader').textContent = lastSlideHeader.textContent
        beforeFirstSlide.querySelector('.swiperText').textContent = lastSlideText.textContent
        let lastSlideBtn = lastSlide.querySelector('.swiperBtn').children[0]
        if (lastSlideBtn === undefined) {
          return
        }
        if (lastSlideBtn.tagName === 'BUTTON') {
          beforeFirstSlide.querySelector('.swiperBtn').children[0].textContent = lastSlideBtn.textContent
        }
      }
    },
    pushAboutCompany () {
      this.$router.push('about_company')
    },
    pushTypesOfActivities () {
      this.$router.push('/types_of_activities')
    },
    pushPartnership () {
      this.$router.push('/partnership')
    },
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'MainSliderSlide',
        'field_list': ['slideHeader', 'slideText', 'slideBtnLink', 'slideBtnText']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  },
  updated () {
    if (!this.loadedAddedContent || !this.loadedDefaultContent || this.$refs.swiper === undefined) {
      return
    }
    let contentToShow = this.contentToShow
    let swiper = this.$refs.swiper.swiper
    swiper.on('transitionEnd', function () {
      if (swiper.activeIndex == contentToShow.length + 1) {
        swiper.slideTo(1, 1)
      }
      if (swiper.activeIndex == 0) {
        swiper.slideTo(contentToShow.length, 1)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .swiper-slide {
    font-size: 18px;
    color:#000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
    background-color: transparent!important;
    justify-content: space-around!important;
  }
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image:  url(../../../public/img/slider/background1.png);
    opacity: 0.3;
  }
  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
  }
  .swiper-slide .subtitle {
    font-size: 21px;
  }
  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  }
</style>
