import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: store.getters['lang/locale'],
  messages: {
    'ru': {
      message: {
        wait: 'Пожалуйста, подождите',
        companyName: 'Фарм-Инновации',
        slogan: 'с заботой о Вашем здоровье',
        linkView: 'Смотреть',
        submenu0: 'Главная',
        submenu1: 'О компании',
        submenu2: 'Партнерство',
        submenu3: 'Карьера',
        submenu4: 'Контакты',
        submenu5: 'Продукция',
        submenu6: 'Мы в соц. сетях',
        sliderHeader1: 'Компания',
        sliderHeader2: 'Деятельность',
        sliderHeader3: 'Партнёрство',
        sliderText1: 'ООО "Фарм-Инновации" официальный дистрибьютор готовых лекарственных средств, БАДов, изделий ' +
            'медицинского назначения и косметической продукции ведущих отечественных и зарубежных производителей. ' +
            'Входит в группу компаний "Фармацевтика".',
        sliderText2: 'ООО "Фарм-Инновации" - ведущий игрок регионального фармацевтического рынка. Главное направление' +
            'деятельности компании - оптовая торговля лекарственными средствами и товарами медицинского назначения в ' +
            'Центральном Федеральном округе.',
        sliderText3: 'Основным партнером компании ООО "Фарм-Инновации" является ФГБУ «Фонд содействия развитию малых форм предприятий в научно-технической сфере»',
        sliderButton1: 'Узнать подробнее',
        sliderButton2: 'Узнать подробнее',
        sliderButton3: 'Узнать подробнее',
        allNews: 'Все новости',
        contentUnit1: 'тыс.',
        contentUnit2: 'кв.м',
        contentUnit3: 'млрд.руб.',
        contentUnit4: 'куб.м',
        contentUnit5: '%',
        contentUnit6: 'млн.куб.м',
        contentNumericHead: 'Компания в цифрах',
        contentNumeric1: 'Более 25 000 товаров в ассортименте',
        contentNumeric2: 'Высокотехнологичные, лицензированные склады',
        contentNumeric3: 'Ежегодный оборот: более 1 млрд. руб.',
        contentNumeric4: 'Более 95 сотрудников',
        contentNumeric5: 'Доля на рынке',
        contentNumeric6: 'Охват ООО Фарм-Инновации',
        contentNumeric7: 'Площадь складских помещений: более 3000 кв.м.',
        contentNumeric8: 'Более 50 аптек',
        contentNumeric9: 'Более 100 производителей',
        contentNum1: '25000',
        contentNum2: '3000',
        contentNum3: '1',
        contentNum4: '95',
        contentNum5: 'Достаточная',
        contentNum6: 'Россия, Белоруссия',
        contentNum7: '3000',
        contentNum8: '50',
        contentNum9: '100',
        aboutUsSubmenu1: 'Руководство',
        aboutUsManagementFIO1: 'Сингх Кумар Абхай',
        aboutUsManagementPost1: 'Стратег',
        aboutUsManagementFIO2: 'Лукьяненко Нина Антоновна',
        aboutUsManagementPost2: 'Генеральный директор',
        aboutUsManagementFIO3: 'Байкова Лилия Леонидовна',
        aboutUsManagementPost3: 'Финансовый директор',
        aboutUsManagementFIO4: 'Старовойтов Геннадий Николаевич',
        aboutUsManagementPost4: 'Директор по развитию',
        aboutUsManagementFIO5: 'Шевкова Валерия Вячеславовна',
        aboutUsManagementPost5: 'Начальник отдела оптовых продаж',
        aboutUsManagementFIO6: 'Семейкин Алексей Вадимович',
        aboutUsManagementPost6: 'Начальник департамента закупок',
        aboutUsManagementFIO7: 'Шкодина Дина Станиславовна',
        aboutUsManagementPost7: 'Начальник департамента аптечных продаж',
        aboutUsManagementFIO8: 'Иванов Петр Николаевич',
        aboutUsManagementPost8: 'Зам. генерального директора по логистике',
        aboutUsManagementFIO9: 'Остроухова Наталья Алексеевна',
        aboutUsManagementPost9: 'Зам. генерального директора по управлению региональной сетью и продажами',
        aboutUsSubmenu2: 'Документы',
        aboutUsSubmenu3: 'Виды деятельности',
        aboutUsSubmenu31: 'Лицензии и виды деятельности',
        aboutUsTypesOfActivitiesSveden: 'Сведения о видах деятельности организации в соответствии с данными ЕГРЮЛ',
        aboutUsTypesOfActivitiesEGRUL: 'Единый государственный реестр юридических лиц ФНС России',
        aboutUsTypesOfActivitiesOsnTitle: 'Основной',
        aboutUsTypesOfActivitiesOsn: 'Торговля оптовая фармацевтической продукцией',
        aboutUsTypesOfActivitiesDop: 'Дополнительные',
        aboutUsTypesOfActivitiesDop1: 'Торговля розничная лекарственными средствами в специализированных магазинах (аптеках)',
        aboutUsTypesOfActivitiesDop2: 'Торговля розничная изделиями, применяемыми в медицинских целях, ортопедическими изделиями в специализированных магазинах',
        aboutUsTypesOfActivitiesDop3: 'Торговля розничная косметическими и товарами личной гигиены в специализированных магазинах',
        aboutUsTypesOfActivitiesDop4: 'Исследование конъюнктуры рынка и изучение общественного мнения',
        aboutUsTypesOfActivitiesDop5: 'Производство лекарственных препаратов и материалов, применяемых в медицинских целях',
        aboutUsSubmenu4: 'Антикоррупционная политика',
        aboutUsSubmenu5: 'Новости компании',
        aboutUsText1: 'Главное направление деятельности компании - оптовая торговля лекарственными средствами и изделиями ' +
            'медицинского назначения в Центральном Федеральном округе.',
        aboutUsText2: 'Компания плотно сотрудничает с лечебными учреждениями региона, обеспечивая поставки необходимых ' +
            'лекарственных препаратов по лучшим ценам.',
        aboutUsText3: 'Для максимально быстрого обеспечения покупателей необходимыми препаратами, а также с целью гарантии ' +
            'безопасности и правильного хранения лекарственных средств, на территории Смоленского района компания построила ' +
            'сверхсовременный многоэтажный фармацевтический склад, оборудованный по всем требованиям законодательства РФ ' +
            'и стандартам GMP.',
        aboutUsText4: 'GMP (Good Manufacturing Practice, Надлежащая производственная практика) – международный стандарт, ' +
            'который устанавливает требования к производству и контролю качества лекарственных средств для человека и ' +
            'животных, а также специальные требования к производству активных фармацевтических субстанций и отдельных ' +
            'видов лекарственных средств. Стандарт GMP регулирует и оценивает параметры производства и лабораторной проверки.',
        aboutUsText5: 'Национальным аналогом GMP в РФ является стандарт ГОСТ Р 52249–2009 «Правила производства и контроля ' +
            'качества лекарственных средств», текст которого идентичен GMP.',
        aboutUsSubTitle1: 'Учредительные документы',
        aboutUsSubTitle2: 'Внутренние документы общества',
        aboutUsSubTitle3: 'Юридические документы',
        aboutUsDocument1: 'Устав ООО "Фарм-Инновации"',
        aboutUsDocument2: 'Антикоррупционная политика ООО “Фарм-Инновации”',
        aboutUsDocument3: 'Кодекс деловой этики ООО "Фарм-Инновации"',
        aboutUsDocument5: 'Свидетельство о присвоении ОГРН',
        aboutUsDocument7: 'Федеральная лицензия на осуществление фармацевтической деятельности № ФС-99-02-005815 от 16 февраля 2017 года',
        aboutUsDocument8: 'Уведомление о снятии с учета российской организации в налоговом органе ООО "Фарм-Инновации" от 13.09.2019',
        aboutUsDocument9: 'Свидетельство о постановке на учет в налоговом органе ООО "Фарм-Инновации" от 13.09.2019',
        aboutUsDocument10: 'Лист записи Единого государственного реестра юридицеских лиц',
        aboutUsDocument1HREF: '/file/Ustav_ru.pdf',
        aboutUsDocument2HREF: '/file/Anticorruption_ru.pdf',
        aboutUsDocument3HREF: '/file/Codex_ru.pdf',
        aboutUsDocument5HREF: '/file/OGRN_ru.jpg',
        aboutUsDocument7HREF: '/file/License_ru.pdf',
        aboutUsDocument8HREF: '/file/Uvedomlenie_o_snyatii_c_ucheta_13.09.19_ru.pdf',
        aboutUsDocument9HREF: '/file/Svidetelstvo_o_postanovke_na_uchet_13.09.19_ru.pdf',
        aboutUsDocument10HREF: '/file/EGRUL_ru.pdf',
        aboutUsAntiCorruption1: 'Деятельность нашей компании построена на поддержании авторитета и доверия к ней ' +
            'обязательными к исполнению всеми сотрудниками инструкциями, утвержденными в следующих внутренних документах:',
        aboutUsAntiCorruption2: '\n' +
            '                  Для осуществления эффективной политики руководству компании необходимо знать обо всех нарушениях.\n' +
            '                  Если вам стало известно о каких-либо фактах совершения коррупционных действий или склонения к их\n' +
            '                  совершению сотрудниками нашей компании, либо третьими лицами от лица «Фарм-Инноваций», либо о фактах\n' +
            '                  совершения коррупционных действий контрагентами или третьими лицами в отношении интересов\n' +
            '                  «Фарм-Инновации», пожалуйста, напишите нам на ',
        aboutUsAntiCorruption3: 'Мы гарантируем конфиденциальность вашего обращения.',
        aboutUsAntiCorruption4: 'По возможности, пожалуйста, укажите в своем обращении следующую информацию:',
        aboutUsAntiCorruption5: 'Ваши контактные данные (ФИО, телефон, email);',
        aboutUsAntiCorruption6: 'ФИО и должность сотрудника, которого склоняют к совершению коррупционных правонарушений\n' +
            '                              или который участвовал в совершении коррупционных правонарушений;',
        aboutUsAntiCorruption7: 'описание обстоятельств, при которых стало известно о совершении или склонении к\n' +
            '                              совершению коррупционного правонарушения (дата, место, время и тд);',
        aboutUsAntiCorruption8: 'подробное описание факта коррупционного правонарушения;',
        aboutUsAntiCorruption9: 'сведения о лице, склоняющем к коррупционному правонарушению;',
        aboutUsAntiCorruption10: 'информация об отказе (согласии) принять предложение.',
        partnershipHead1: 'Преимущества',
        partnershipHead2: 'Партнерство',
        partnershipSubHead1: 'Географическое преимущество',
        partnershipSubHead2: 'Бизнес-модель продвижения новых дженериков в Российской Федерации',
        partnershipSubHead3: 'Сервисы',
        partnershipSubHead4: 'Рекламные возможности',
        partnershipSubHead5: 'Предложить товар',
        partnershipSubHead6: 'Отчетность',
        partnershipSchemeSRC: '/img/scheme/Business-model-RU.png',
        partnershipSubmenu1: 'Логистика',
        partnershipSubmenu2: 'Поставщикам',
        partnershipSubmenu3: 'Наши партнеры',
        partnershipSubmenu4: 'Наши клиенты',
        partnershipText1: 'Смоленская область - важнейший транспортный и коммуникационный узел. Через него проходят ' +
            'кратчайшие автомобильные и железнодорожные магистрали, связывающие Западную Европу с Центральной Россией. ' +
            'Такое расположение дает уникальные возможности с точки зрения оптимизации логистических процессов нашей ' +
            'компании',
        partnershipText2: 'Благодаря продуманной логистике, компания ООО "Фарм-Инновации" выбирает наиболее эффективные ' +
            'варианты обеспечения товаром нужного качества и количества, в нужное время, в нужном месте и с минимальными ' +
            'затратами.',
        partnershipText3: 'В зависимости от поставленной цели, компания применяет различные логистические системы:',
        partnershipText4: '- закупочная логистика;',
        partnershipText5: '- распределительная логистика;',
        partnershipText6: '- сбытовая логистика;',
        partnershipText7: '- транспортная логистика;',
        partnershipText8: '- складская логистика;',
        partnershipText9: '- информационная логистика.',
        partnershipText10: 'крупнейший в Смоленской области онлайн сервис поиска и заказа',
        partnershipText11: 'лекарственных средств, БАДов, изделий медицинского назначения и косметической продукции.',
        partnershipText12: 'Площадка Интернет-аптеки предоставляет широкий спектр рекламных услуг для продвижения товара',
        partnershipText13: 'заказ',
        partnershipText14: '- формирование пользователем Интернет-аптеки заявки на товар с последующим его выкупом в одном из\n' +
            '                  аптечных пунктов сети "Городская аптека". Товар и аптечный пункт выбирается пользователем самостоятельно\n' +
            '                  из представленных на сайте',
        partnershipText15: 'Любые виды рекламы на собственных площадках и на сайтах партнёров',
        partnershipText16: 'Продвижение товаров и услуг на платформах социальных сетей компании и партнёров',
        partnershipText17: 'Баннерная реклама во многих точках города Смоленска и Смоленской области',
        partnershipText18: 'Любые виды наружной и прямой рекламы',
        partnershipText19: 'По вопросам продвижения ассортимента на ',
        partnershipText20: 'напишите нам',
        partnershipText21: 'или позвоните по телефону ',
        partnershipText22: 'Мы всегда открыты для взаимовыгодного партнерства.\n' +
            '                  Для начала сотрудничества с нашей компанией Вы можете сделать заявку на включение товара в наш прайс-лист,\n' +
            '                  заполнив следующую форму',
        partnershipText23: 'Для формирования заявки на получение информации по продажам и товарному запасу, пожалуйста, заполните форму. ' +
            'На все ваши вопросы мы с удовольствием ответим по телефону',
        partnershipText24: 'Основным партнером компании ООО "Фарм-Инновации" является ФГБУ «Фонд содействия развитию малых форм предприятий в научно-технической сфере»',
        partnershipLogisticsNumericHead: 'Логистическая система в цифрах',
        partnershipWarehouseNumericHead: 'Складская система в цифрах',
        partnershipLogisticsSubHead1: 'Складские мощности и технологии',
        partnershipNumeric1: 'Более 1000 точек доставки в год',
        partnershipNumeric2: 'Среднее количество точек доставки по Смоленску и области в день',
        partnershipNumeric3: 'Автомобилей для доставки в день',
        partnershipNumeric4: 'Объем обработанного товара',
        partnershipNumeric5: 'Затраты на складскую логистику',
        partnershipNumeric7: 'Общая ёмкость склада',
        partnershipNumeric8: 'Объем поступлений на склад',
        partnershipNumeric9: 'Отгрузка со склада',
        partnershipForSuppliersForm1: 'Название товара',
        partnershipForSuppliersForm2: 'Компания',
        partnershipForSuppliersForm3: 'Сайт вашей компании',
        partnershipForSuppliersForm4: 'Регион',
        partnershipForSuppliersForm5: 'Контактное лицо',
        partnershipForSuppliersForm6: 'Код страны',
        partnershipForSuppliersForm6Error: 'Выберите код страны',
        partnershipForSuppliersForm7: 'Телефон',
        partnershipForSuppliersForm7Example: 'пример:',
        partnershipForSuppliersForm8: 'Прикрепить файлы',
        partnershipForSuppliersForm9: 'Комментарий',
        partnershipForSuppliersForm10: 'Отправить',
        partnershipForSuppliersForm11: 'Содержание заявки',
        partnershipForSuppliersForm12: 'Сформировать заявку',
        partnershipForSuppliersForm13: 'Заявка сформирована!',
        partnershipOurPartnersSubHead1: 'Крупнейшие поставщики ГЛС',
        partnershipOurPartnersText: 'Более 100 ведущих фармпроизводителей',
        partnershipOurPartnersSubHead2: 'Крупнейшие поставщики НЛС',
        partnershipOurPartnersSubHead3: 'Крупнейшие поставщики <<...>>',
        careerSubHead: 'Карьера в компании',
        careerSubTitle1: 'Мы предлагаем:',
        careerSubTitle2: 'Вопросы по трудоустройству?',
        careerSubTitle3: 'Вакансии',
        careerText1: 'В ООО "Фарм-Инновации" каждый сотрудник – полноценный партнер. Мы создаем комфортные условия работы, ' +
            'дружественную атмосферу, возможности для роста и построения карьеры, достойно вознаграждаем сотрудников и ' +
            'признаем тех, кто добивается высоких результатов.',
        careerText2: 'Карьерный и профессиональный рост',
        careerText3: 'Достойную заработную плату',
        careerText4: ' Всестороннее обучение и развитие',
        careerText5: 'Контактный телефон:',
        careerText6: 'Актуальную информацию о вакансиях компании Вы можете посмотреть на сайте',
        careerText7: 'Вы также можете прислать ваше резюме, используя следующую форму:',
        careerWorkForm1: 'ФИО',
        careerWorkForm2: 'Вакансия',
        careerWorkForm3: 'Город',
        careerWorkForm4: 'Прикрепить файлы',
        careerWorkForm5: 'Текст сообщения',
        careerWorkForm6: 'Отправить резюме',
        careerWorkForm7: 'Нажимая кнопку «Отправить», вы соглашаетесь с',
        careerWorkForm8: ' политикой обработки персональных данных',
        careerWorkForm9: 'Ваше резюме отправлено!',
        feedBackForm: 'Обратная связь',
        feedBackForm1: 'Как к Вам обращаться?',
        feedBackForm2: 'Укажите Email для обратной связи',
        feedBackForm3: 'Текст сообщения',
        feedBackForm4: 'Сообщение отправлено',
        contactSubTitle1: 'Центральный офис',
        contactSubTitle2: 'Центральный склад',
        contactText1: 'Россия, Смоленская обл. д. Михновка ул. Солнечная, д.1 оф.1,',
        contactText2: 'Россия, Смоленская область, Смоленский район, д. Михновка, ул. Солнечная, д.1, оф.1',
        contactPhone: 'Телефон',
        contactPhoneNum: '+7(4812)30-24-66',
        contactText3: 'Будем рады Вашим отзывам и предложениям!',
        contactText4: 'Напишите нам',
        contactText5: 'Либо заполнив',
        contactText6: 'форму обратной связи',
        contactInfo: 'Все права защищены ©, 2019-2020 ООО “Фарм-Инновации”. Верстка и программирование: ',
        contactITMosaic: ' “ИТ-Мозаика”',
        productsPageProductRecipe: 'По рецепту',
        productsPageProductNoRecipe: 'Без рецепта',
        productsPageProductLabel1: 'Производитель',
        productsPageProductLabel2: 'Страна производитель',
        productsPageProductLabel3: 'Категории',
        productsPageProductLabel4: 'Действующее вещество (МНН)',
        productsPageProductSubHead1: 'Заказать онлайн:',
        productsPageSliderTab1: 'Инструкция',
        productsPageSliderTab2: 'Сертификаты',
        productsPageSliderTab3: 'Отзывы',
        productSertificateDownloadLabel: 'Скачать',
        productInstructionSubHead1: 'Состав:',
        productInstructionSubHead2: 'Фармакотерапевтическая группа:',
        productInstructionSubHead3: 'Фармакологические свойства:',
        productInstructionSubHead4: 'Показания к применению:',
        productInstructionSubHead5: 'Противопоказания:',
        productInstructionSubHead6: 'Применение при беременности и в период грудного вскармливания:',
        productInstructionSubHead7: 'Способ применения и дозы:',
        productInstructionSubHead8: 'Побочные действия:',
        productInstructionSubHead9: 'Передозировка:',
        productInstructionSubHead10: 'Взаимодействие с другими лекарственными препаратами:',
        productInstructionSubHead11: 'Особые указания:',
        productInstructionSubHead12: 'Форма выпуска:',
        productInstructionSubHead13: 'Условия хранения:',
        productInstructionSubHead14: 'Срок годности:',
        productInstructionSubHead15: 'Условия отпуска:',
        productPostsHead1: 'Отзывы врачей',
        productPostsHead2: 'Отзывы пациентов',
        productPostsHead3: 'Отзывов на данный продукт пока нет',
        productPostsBtnLabel: 'Оставить отзыв',
        productsSliderProductsLabel1: 'Подробнее',
        server_error: 'Ошибка на сервере',
        auth_error: 'Ошибка аутентификации'
      }
    },
    'en-US': {
      message: {
        wait: 'Plaese, wait',
        companyName: 'Pharm-Innovation',
        slogan: 'with care for your health!',
        linkView: 'View',
        submenu0: 'Main',
        submenu1: 'About company',
        submenu2: 'Partnership',
        submenu3: 'Career',
        submenu4: 'Contact info',
        submenu5: 'Products',
        submenu6: 'In the soc.networks',
        sliderHeader1: 'Company',
        sliderHeader2: 'Activity',
        sliderHeader3: 'Partnership',
        sliderText1: 'Pharm-Innovation LLC is the official distributor of finished medicines, dietary supplements, medical devices and cosmetic products of leading domestic and foreign manufacturers.',
        sliderText2: 'LLC Pharm-Innovation is a leading player in the regional pharmaceutical market. The main activity of the company is the wholesale trade of medicines and medical products in the Central Federal District.',
        sliderText3: 'The main partner of Pharm-Innovations LLC is Federal State Budgetary Institution Fund for Assistance to the Development of Small Forms of Enterprises in the Scientific and Technical Sphere',
        sliderButton1: 'More details',
        sliderButton2: 'More details',
        sliderButton3: 'More details',
        allNews: 'All news',
        contentUnit1: 'thou.',
        contentUnit4: 'cbm',
        contentNumericHead: 'Digits',
        contentNumeric1: 'More than 25 000 products in assortment',
        contentNumeric2: 'High-tech, licensed warehouses',
        contentUnit2: 'sqm',
        contentNumeric3: 'Annual turnover: more than 1 billion rubles',
        contentUnit3: 'bn rub',
        contentNumeric4: 'Number of employees: more than 95 people',
        contentNumeric5: 'Market share',
        contentUnit5: '%',
        contentNumeric6: 'Coverage of LLC Pharm-innovation',
        contentUnit6: 'mln cbm',
        contentNumeric7: 'Total area of warehouse premises: more than 3000 sqm',
        contentNumeric8: 'More then 50 pharmacies',
        contentNumeric9: 'Manufacturers',
        contentNum1: '25000',
        contentNum2: '3000',
        contentNum3: '1',
        contentNum4: '95',
        contentNum5: 'Sufficient',
        contentNum6: 'Russia, Belarus',
        contentNum7: '3000',
        contentNum8: '50',
        contentNum9: '100',
        aboutUsSubmenu1: 'Management',
        aboutUsManagementFIO1: 'Syngh Abhay Kumar',
        aboutUsManagementPost1: 'Strategist',
        aboutUsManagementFIO2: 'Lukyanenko Nina Antonovna',
        aboutUsManagementPost2: 'General Director',
        aboutUsManagementFIO3: 'Baykova Lilia Leonidovna',
        aboutUsManagementPost3: 'Chief Financial Officer',
        aboutUsManagementFIO4: 'Starovoitov Gennady Nikolaevich',
        aboutUsManagementPost4: 'Development Director',
        aboutUsManagementFIO5: 'Shevkova Valeria Vyacheslavovna',
        aboutUsManagementPost5: 'Head of Wholesale Department',
        aboutUsManagementFIO6: 'Semeykin Alexey Vadimovich',
        aboutUsManagementPost6: 'Head of Purchasing Department',
        aboutUsManagementFIO7: 'Shkodina Dina Stanislavovna',
        aboutUsManagementPost7: 'Head of Pharmaceutical Sales Department',
        aboutUsManagementFIO8: 'Ivanov Peter Nikolaevich',
        aboutUsManagementPost8: 'Deputy General Director for Logistics',
        aboutUsManagementFIO9: 'Ostroukhova Natalya Alekseevna',
        aboutUsManagementPost9: 'Deputy General Director for Regional Network and Sales Management',
        aboutUsSubmenu2: 'Documents',
        aboutUsSubmenu3: 'Types of activities',
        aboutUsSubmenu31: 'Licenses and types of activities',
        aboutUsTypesOfActivitiesSveden: 'Data on types of activities of the organization according to EGRUL',
        aboutUsTypesOfActivitiesEGRUL: 'Unified State Register of Legal Entities of the Federal Tax Service of Russia',
        aboutUsTypesOfActivitiesOsnTitle: 'Main',
        aboutUsTypesOfActivitiesOsn: 'Wholesale of pharmaceutical goods',
        aboutUsTypesOfActivitiesDop: 'Additional',
        aboutUsTypesOfActivitiesDop1: 'Retailing drugs in specialized stores (pharmacies)',
        aboutUsTypesOfActivitiesDop2: 'Retail trade of medical and orthopedic goods in specialized stores',
        aboutUsTypesOfActivitiesDop3: 'Retail trade of cosmetic and toilet articles in specialized stores',
        aboutUsTypesOfActivitiesDop4: 'Market research and public opinion polls',
        aboutUsTypesOfActivitiesDop5: 'Production of medicines and materials used for medical purposes',
        aboutUsSubmenu4: 'Anti-corruption policy',
        aboutUsSubmenu5: 'News',
        aboutUsText1: 'The main activity of the company is the wholesale trade of medicines and medical products in ' +
            'the Central Federal District.',
        aboutUsText2: 'The company closely cooperates with medical institutions in the region, ensuring the supply of ' +
            'necessary medicines at the best prices according to the results of auctions.',
        aboutUsText3: 'For the fastest possible provision of buyers with necessary medicines, as well as to guarantee safety ' +
            'and proper storage of medicines, the company built an ultra-modern multi-storey pharmaceutical warehouse in ' +
            'the Smolensk region, equipped  in accordance with all requirements of Russian legislation and GMP standards.',
        aboutUsText4: 'GMP (Good Manufacturing Practice) is an international standard that establishes requirements for ' +
            'the production and quality control of medicines for humans and animals, as well as special requirements for ' +
            'the production of active pharmaceutical substances and certain types of medicines. The GMP standard regulates ' +
            'and evaluates production and laboratory verification parameters.',
        aboutUsText5: 'The national analogue of GMP in the Russian Federation is the standard ГОСТ Р 52249-2009 ' +
            '“Rules for the production and quality control of medicines”, the text of which is identical to GMP',
        aboutUsSubTitle1: 'Constituent documents',
        // aboutUsSubTitle2: 'Internal documents of the company',
        aboutUsSubTitle2: '',
        aboutUsSubTitle3: 'Legal Documents',
        aboutUsDocument1: 'Charter of LLC Pharm-innovation',
        // aboutUsDocument2: 'Anti-corruption policy of Pharm-Innovation LLC',
        // aboutUsDocument3: 'Code of Business Conduct of Pharm-Innovation LLC',
        aboutUsDocument2: '',
        aboutUsDocument3: '',
        aboutUsDocument5: 'PSRN assignment certificate',
        aboutUsDocument7: 'Federal Pharmaceutical License No ФС-99-02-005815 dd 16.02.2017',
        // aboutUsDocument8: 'Notification of deregistration of a Russian organization in a tax authority of Pharm-Innovation LLC dd 13.09.2019',
        // aboutUsDocument9: 'Certificate of registration with the tax authority of Pharm-Innovation LLC dd 13.09.2019',
        aboutUsDocument8: '',
        aboutUsDocument9: '',
        aboutUsDocument10: 'Record sheet of the Unified State Register of Legal Entities',
        aboutUsDocument1HREF: '/file/Ustav_en.pdf',
        aboutUsDocument2HREF: '/file/Anticorruption_ru.pdf',
        aboutUsDocument3HREF: '/file/Codex_ru.pdf',
        aboutUsDocument5HREF: '/file/OGRN_en.pdf',
        aboutUsDocument7HREF: '/file/License_en.pdf',
        aboutUsDocument8HREF: '/file/Uvedomlenie_o_snyatii_c_ucheta_13.09.19_ru.pdf',
        aboutUsDocument9HREF: '/file/Svidetelstvo_o_postanovke_na_uchet_13.09.19_ru.pdf',
        aboutUsDocument10HREF: '/file/EGRUL_en.pdf',
        aboutUsAntiCorruption1: 'The activity of our company is built on maintaining credibility and trust in it with ' +
            'binding instructions for all employees approved in the following internal documents:',
        aboutUsAntiCorruption2: 'To implement an effective policy, company management needs to be aware of all ' +
            'violations. If you become aware of any facts of corruption or incitement to commit them by employees of our ' +
            'company, or by third parties on behalf of Pharm-Innovation, or of facts of corruption by contractors or third ' +
            'parties regarding the interests of Pharm-Innovation please write to us at ',
        aboutUsAntiCorruption3: 'We guarantee the confidentiality of your appeal.',
        aboutUsAntiCorruption4: 'If possible, please indicate in your appeal the following information:',
        aboutUsAntiCorruption5: 'Your contact information (name, phone, email);',
        aboutUsAntiCorruption6: 'name and position of the employee who is persuaded to commit corruption offenses or who ' +
            'participated in the commission of corruption offenses;',
        aboutUsAntiCorruption7: 'a description of the circumstances in which it became known about the commission or ' +
            'incitement to commit a corruption offense (date, place, time, etc.);',
        aboutUsAntiCorruption8: 'a detailed description of the fact of a corruption offense;',
        aboutUsAntiCorruption9: 'information about the person who incites to a corruption offense;',
        aboutUsAntiCorruption10: 'information about the refusal (consent) to accept the offer.',
        partnershipHead1: 'Advantages',
        partnershipHead2: 'Partnership',
        partnershipSubHead1: 'Geographical advantage',
        partnershipSubHead2: 'Business model of promoting new generic drugs in the Russian Federation',
        partnershipSubHead3: 'Services',
        partnershipSubHead4: 'Advertising opportunities',
        partnershipSubHead5: 'Suggest a product',
        partnershipSubHead6: 'Reporting',
        partnershipSchemeSRC: '/img/scheme/Business-model-EN.png',
        partnershipSubmenu1: 'Logistics',
        partnershipSubmenu2: 'For Suppliers',
        partnershipSubmenu3: 'Our partners',
        partnershipSubmenu4: 'Our clients',
        partnershipText1: 'Smolensk region is the most important transport and communication hub. The shortest ' +
            'highways and railways connecting Western Europe with Central Russia pass through it. This arrangement ' +
            'provides unique opportunities in terms of optimizing the logistics processes of our company.',
        partnershipText2: 'Thanks to thoughtful logistics, the company Pharm-Innovation LLC chooses the most effective ' +
            'options for providing goods with the right quality and quantity, at the right time, in the right place and ' +
            'at the lowest cost.',
        partnershipText3: 'Depending on the goal, the company uses various logistics systems:',
        partnershipText4: '- purchasing logistics;',
        partnershipText5: '- distribution logistics;',
        partnershipText6: '- sales logistics;',
        partnershipText7: '- transport logistics;',
        partnershipText8: '- warehouse logistics;',
        partnershipText9: '- information logistics.',
        partnershipText10: 'the largest online service in the Smolensk region for searching and ordering',
        partnershipText11: 'medicines, dietary supplements, medical devices and cosmetic products',
        partnershipText12: 'The online pharmacy platform provides a wide range of advertising services for product promotion.',
        partnershipText13: 'order',
        partnershipText14: '- the formation by the user of an online pharmacy of an application for a product with its ' +
            'subsequent redemption at one of the pharmacy points of the City Pharmacy network. The product and pharmacy ' +
            'is selected by the user independently from those presented on the site',
        partnershipText15: 'Any types of advertising on their own sites and on partner sites',
        partnershipText16: 'Promotion of goods and services on the platforms of social networks of the company and partners',
        partnershipText17: 'Banner advertising in many points of the city of Smolensk and the Smolensk region',
        partnershipText18: 'Any types of outdoor and direct advertising',
        partnershipText19: 'Regarding the promotion of the assortment at ',
        partnershipText20: 'write to us',
        partnershipText21: 'or call ',
        partnershipText22: 'We are always open for mutually beneficial partnership. To start cooperation with our company, ' +
            'you can make an application for inclusion of goods in our price list by filling out the following form',
        partnershipText23: 'To generate an application for information on sales and inventory, please fill out the form.' +
            'We will be happy to answer all your questions by phone',
        partnershipText24: 'The main partner of Pharm-Innovations LLC is Federal State Budgetary Institution Fund for Assistance to the Development of Small Forms of Enterprises in the Scientific and Technical Sphere',
        partnershipSubTitle: 'Logistics',
        partnershipLogisticsNumericHead: 'Logistics system in numbers',
        partnershipWarehouseNumericHead: 'Warehouse system in numbers',
        partnershipNumeric1: 'Over 1000 delivery points per year',
        partnershipNumeric2: 'The average number of delivery points in Smolensk and the region per day',
        partnershipNumeric3: 'Number of vehicles used for the delivery per day',
        partnershipNumeric4: 'Volume of product handled',
        partnershipNumeric5: 'Expenses for warehouse logistics',
        partnershipNumeric7: 'Total capacity of the warehouse',
        partnershipNumeric8: 'Input volume in the warehouse',
        partnershipNumeric9: 'Shipment from the warehouse',
        partnershipLogisticsSubHead1: 'Warehouse capacity and technologies',
        partnershipForSuppliersForm1: 'Product Name',
        partnershipForSuppliersForm2: 'Company',
        partnershipForSuppliersForm3: 'Your company website',
        partnershipForSuppliersForm4: 'Region',
        partnershipForSuppliersForm5: 'Contact person',
        partnershipForSuppliersForm6: 'Country code',
        partnershipForSuppliersForm6Error: 'Choose country code',
        partnershipForSuppliersForm7: 'Phone number',
        partnershipForSuppliersForm7Example: 'example:',
        partnershipForSuppliersForm8: 'Attach files',
        partnershipForSuppliersForm9: 'Comment',
        partnershipForSuppliersForm10: 'Send',
        partnershipForSuppliersForm11: 'Application Content',
        partnershipForSuppliersForm12: 'To form a request',
        partnershipForSuppliersForm13: 'Application is formed!',
        partnershipOurPartnersSubHead1: 'Key Medicine Suppliers',
        partnershipOurPartnersText: 'Over 100 leading pharmaceutical manufacturers',
        partnershipOurPartnersSubHead2: 'Key Non-Medcines Suppliers',
        partnershipOurPartnersSubHead3: 'Key <<<...>> Suppliers',
        careerSubHead: 'Company career',
        careerSubTitle1: 'We offer:',
        careerSubTitle2: 'Questions about employment?',
        careerSubTitle3: 'Jobs',
        careerText1: 'At Pharm-Innovation LLC, each employee is a full-fledged partner. We create comfortable working ' +
            'conditions, a friendly atmosphere, opportunities for growth and career building, adequately reward ' +
            'employees and recognize those who achieve high results.',
        careerText2: 'Career and professional growth',
        careerText3: 'Decent salary',
        careerText4: 'Comprehensive training and development',
        careerText5: 'Contact number:',
        careerText6: 'You can look up-to-date information on company vacancies on the website',
        careerText7: 'You can also send your resume using the following form:',
        careerWorkForm1: 'Full name',
        careerWorkForm2: 'Vacancy',
        careerWorkForm3: 'City',
        careerWorkForm4: 'Attach files',
        careerWorkForm5: 'Message text',
        careerWorkForm6: 'Send CV',
        careerWorkForm7: 'By clicking the "Send" button, you agree to',
        careerWorkForm8: ' the policy of processing personal data',
        careerWorkForm9: 'Your resume has been sent!',
        feedBackForm: 'Feedback',
        feedBackForm1: 'How can I call you?',
        feedBackForm2: 'Indicate Email for feedback',
        feedBackForm3: 'Message text',
        feedBackForm4: 'Message sent',
        contactSubTitle1: 'Main office',
        contactSubTitle2: 'Main warehouse',
        contactText1: 'Russia, с. Smolensk, 28a Kirova street',
        contactText2: 'Russia, Smolensk region, Smolensky district, Mikhnovka village, Solnechnaya str., 1, off. 1',
        contactPhone: 'Phone',
        contactPhoneNum: '+7(4812)30-24-66',
        contactText3: 'We welcome your feedback and suggestions!',
        contactText4: 'Email us',
        contactText5: 'Or by filling out',
        contactText6: 'the feedback form',
        contactInfo: 'All rights reserved ©, 2019-2020 LLC Pharm-innovation. Site creation: ',
        contactITMosaic: ' “IT-Mosaic”',
        productsPageProductRecipe: 'With recipe',
        productsPageProductNoRecipe: 'Without recipe',
        productsPageProductLabel1: 'Manufacturer',
        productsPageProductLabel2: 'Manufacturer country',
        productsPageProductLabel3: 'Categories',
        productsPageProductLabel4: 'Active ingredient (INN)',
        productsPageProductSubHead1: 'Order online:',
        productsPageSliderTab1: 'Instruction',
        productsPageSliderTab2: 'Certificates',
        productsPageSliderTab3: 'Reviews',
        productSertificateDownloadLabel: 'Download',
        productInstructionSubHead1: 'Composition:',
        productInstructionSubHead2: 'Pharmacotherapeutic group:',
        productInstructionSubHead3: 'Pharmacological properties:',
        productInstructionSubHead4: 'Indications for use:',
        productInstructionSubHead5: 'Contraindications:',
        productInstructionSubHead6: 'Use during pregnancy and during breastfeeding:',
        productInstructionSubHead7: 'Dosage and administration:',
        productInstructionSubHead8: 'Side effects:',
        productInstructionSubHead9: 'Overdose:',
        productInstructionSubHead10: 'Interaction with other drugs:',
        productInstructionSubHead11: 'Special instructions:',
        productInstructionSubHead12: 'Release form:',
        productInstructionSubHead13: 'Storage conditions:',
        productInstructionSubHead14: 'Best before date:',
        productInstructionSubHead15: 'Holiday conditions:',
        productPostsHead1: 'Reviews of doctors',
        productPostsHead2: 'Patient reviews',
        productPostsHead3: 'There are no reviews for this product yet',
        productPostsBtnLabel: 'Give feedback',
        productsSliderProductsLabel1: 'More',
        server_error: 'Server_error',
        auth_error: 'Ошибка аутентификации'
      }
    }
  }
})

/**
 * @param {String} locale
 */
export async function loadMessages (locale) {
  if (Object.keys(i18n.getLocaleMessage(locale)).length === 0) {
    const messages = await import(/* webpackChunkName: "[request]" */ `@/lang/${locale}`)
    i18n.setLocaleMessage(locale, messages)
  }

  if (i18n.locale !== locale) {
    i18n.locale = locale
  }
}

(async function () {
  await loadMessages(store.getters['lang/locale'])
})()

export default i18n
