<template>
  <v-footer color="#3B3B3B"
            padless
  >
    <v-container fluid>
      <v-row class="no-indentation" style="margin-top:72px">
        <v-col offset-lg="1" offset-md="1" offset-sm="1" col="2" lg="2" md="2" sm="5">
          <v-row class="no-indentation">
            <v-col class="no-indentation">
              <push-link v-if="showPushLink1Header || isAuth"
                  push-link="/about_company"
                  :link-text="$t('message.submenu1')"
                  :id_push-link="351"
                  :id_link-text="352"
                  link-class="footerHeading"
                  :hidden="!showPushLink1Header"
              />
            </v-col>
          </v-row>
          <div v-if="loadedAddedPushLink1 && loadedDefaultPushLink1">
            <v-row class="no-indentation" v-for="(content, index) in pushLink1ToShow" :key="index">
              <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                <div>
                  <push-link
                      :push-link="content['pushLink']"
                      :link-text="content['linkText']"
                      :id_push-link="content['id_pushLink']"
                      :id_link-text="content['id_linkText']"
                      :added="content['added']"
                      :hidden="content['hidden']"
                      link-class="footerSubHeading"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('PushLinkFooter1',['pushLink', 'linkText'], addedPushLink1 )"> Добавить ссылку</v-btn>
          <div v-if="loadedAddedImgContent1">
          <v-row v-for="content in imgContent1ToShow" :key="content['id_pathImg']" class="no-indentation">
              <img-content
                  :path-img="content['pathImg']"
                  :id_path-img="content['id_pathImg']"
                  div-class="no-indentation mt-lg-6 mt-md-6 mt-sm-5 mt-4 text-center py-2 mobileSubHeading footerLogo fond"
                  img-class="logo"
                  :added="content['added']"
                  :hidden="content['hidden']"
              />
          </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('ImgContentFooter1',['pathImg'], addedImgContent1 )"> Добавить картинку</v-btn>
        </v-col>
        <v-col col="2" lg="2" md="2" sm="6">
          <v-row class="no-indentation">
            <v-col class="no-indentation">
              <push-link v-if="showPushLink2Header || isAuth"
                  push-link="/partnership"
                  :link-text="$t('message.submenu2')"
                  :id_push-link="365"
                  :id_link-text="366"
                  link-class="footerHeading"
                  :hidden="!showPushLink2Header"
              />
            </v-col>
          </v-row>
          <div v-if="loadedAddedPushLink2 && loadedDefaultPushLink2">
            <v-row class="no-indentation" v-for="(content, index) in pushLink2ToShow" :key="index">
              <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                <push-link
                    :push-link="content['pushLink']"
                    :link-text="content['linkText']"
                    :id_push-link="content['id_pushLink']"
                    :id_link-text="content['id_linkText']"
                    :added="content['added']"
                    :hidden="content['hidden']"
                    link-class="footerSubHeading"
                />
              </v-col>
            </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('PushLinkFooter2',['pushLink', 'linkText'], addedPushLink2 )"> Добавить ссылку</v-btn>
          <div v-if="loadedAddedImgContent2 && loadedDefaultImgContent2">
            <v-row v-for="content in imgContent2ToShow" :key="content['id_pathImg']" class="no-indentation">
                <img-content
                    :path-img="content['pathImg']"
                    :id_path-img="content['id_pathImg']"
                    div-class="no-indentation mt-lg-6 mt-md-6 mt-sm-5 mt-4 text-center py-2 mobileSubHeading footerLogo fond"
                    img-class="logo"
                    :added="content['added']"
                    :hidden="content['hidden']"
                />
            </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('ImgContentFooter2',['pathImg'], addedImgContent2 )"> Добавить картинку</v-btn>
        </v-col>
        <v-col offset-lg="0" offset-md="0" offset-sm="1" col="2" lg="2" md="2" sm="5">
          <div v-if="showPushLink3Header || isAuth">
            <v-row class="no-indentation">
              <v-col class="no-indentation">
                <push-link v-if="showPushLink3Header"
                    push-link="/career"
                    :link-text="$t('message.submenu3')"
                    :id_push-link="373"
                    :id_link-text="374"
                    link-class="footerHeading"
                    :hidden="!showPushLink3Header"
                />
              </v-col>
            </v-row>
          </div>
          <div v-if="loadedAddedPushLink3">
            <v-row class="no-indentation" v-for="(content, index) in pushLink3ToShow" :key="index">
              <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                <push-link
                    :push-link="content['pushLink']"
                    :link-text="content['linkText']"
                    :id_push-link="content['id_pushLink']"
                    :id_link-text="content['id_linkText']"
                    :added="content['added']"
                    :hidden="content['hidden']"
                    link-class="footerSubHeading"
                />
              </v-col>
            </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('PushLinkFooter3',['pushLink', 'linkText'], addedPushLink3 )"> Добавить ссылку</v-btn>
          <div v-if="loadedAddedImgContent3 && loadedDefaultImgContent3">
            <v-row v-for="content in imgContent3ToShow" :key="content['id_pathImg']" class="no-indentation">
                <img-content
                    :path-img="content['pathImg']"
                    :id_path-img="content['id_pathImg']"
                    div-class="no-indentation mt-lg-6 mt-md-6 mt-sm-5 mt-4 text-center py-2 mobileSubHeading footerLogo"
                    img-class="logo"
                    :added="content['added']"
                    :hidden="content['hidden']"
                />
            </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('ImgContentFooter3',['pathImg'], addedImgContent3 )"> Добавить картинку</v-btn>
        </v-col>
        <v-col col="2" lg="2" md="4" sm="6">
          <div v-if="showPushLink4Header || isAuth">
            <v-row class="no-indentation">
              <v-col class="no-indentation">
                <push-link v-if="showPushLink4Header"
                    push-link="/contacts"
                    :link-text="$t('message.submenu4')"
                    :id_push-link="375"
                    :id_link-text="376"
                    link-class="footerHeading"
                    :hidden="!showPushLink4Header"
                />
              </v-col>
            </v-row>
          </div>
          <div v-if="loadedAddedPushLink4">
            <v-row class="no-indentation" v-for="(content, index) in pushLink4ToShow" :key="index">
              <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3 mobileSubHeading">
                <push-link
                    :push-link="content['pushLink']"
                    :link-text="content['linkText']"
                    :id_push-link="content['id_pushLink']"
                    :id_link-text="content['id_linkText']"
                    :added="content['added']"
                    :hidden="content['hidden']"
                    link-class="footerSubHeading"
                />
              </v-col>
            </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('PushLinkFooter4',['pushLink', 'linkText'], addedPushLink4 )"> Добавить ссылку</v-btn>
          <div v-if="loadedAddedImgContent4">
            <v-row v-for="content in imgContent4ToShow" :key="content['id_pathImg']" class="no-indentation">
                <img-content
                    :path-img="content['pathImg']"
                    :id_path-img="content['id_pathImg']"
                    div-class="no-indentation mt-lg-6 mt-md-6 mt-sm-5 mt-4 text-center py-2 mobileSubHeading footerLogo fond"
                    img-class="logo"
                    :added="content['added']"
                    :hidden="content['hidden']"
                />
            </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('ImgContentFooter4',['pathImg'], addedImgContent4 )"> Добавить картинку</v-btn>
          <div v-if="showContentText || isAuth">
            <v-row class="no-indentation">
              <v-col class="no-indentation mt-lg-5 mt-md-5 mt-sm-3">
                <content-text
                  :content="$t('message.contactText2')"
                  :id_content="379"
                  div-class="mobileSubHeading"
                  text-class="footerContacts"
                  :hidden="!showContentText"
                />
              </v-col>
            </v-row>
          </div>
          <div v-if="loadedAddedSVGLinkText && loadedDefaultSVGLinkText">
            <v-row class="no-indentation" v-for="(content, index) in SVGLinkTextToShow" :key="index">
              <v-col class="no-indentation mt-lg-3 mt-md-3 mt-sm-2 mt-2">
                <s-v-g-link-text
                    :link="content['link']"
                    :svg-path="content['svgPath']"
                    :text="content['text']"
                    :id_link="content['id_link']"
                    :id_svg-path="content['id_svgPath']"
                    :id_text="content['id_text']"
                    :added="content['added']"
                    :hidden="content['hidden']"
                />
              </v-col>
            </v-row>
          </div>
          <v-btn v-if="isAuth" @click="add_content('SVGLinkText',['link', 'svgPath', 'text'], addedSVGLinkText )"> Добавить SVG ссылку</v-btn>
          <v-row class="no-indentation">
            <v-col class="no-indentation mt-lg-3 mt-md-3 mt-sm-2 mt-2 d-flex">
              <div v-if="loadedDefaultFooterImgLinks && loadedAddedFooterImgLinks">
                <footer-img-links v-for="(content, index) in footerImgLinksToShow" :key="index"
                  :path-img="content['pathImg']"
                  :link="content['link']"
                  :id_path-img="content['id_pathImg']"
                  :id_link="content['id_link']"
                  :added="content['added']"
                  :hidden="content['hidden']"
                />
              </div>
              <v-btn v-if="isAuth" @click="add_content('FooterImgLinks',['pathImg','link'], addedFooterImgLinks )"> Добавить картинку с ссылкой</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-btn absolute
               dark
               fab
               top
               right
               color="#BACF34"
               x-large
               @click.stop="showFeedbackForm()"
          >
            <v-icon>{{ svgEmail }}</v-icon>
          </v-btn>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog"
                    max-width="568"
        >
          <v-card class="pa-8">
            <v-card-title class="headline" style="color: #009688!important;">
              {{ $t('message.feedBackForm') }}
            </v-card-title>
            <v-card-text>
              <feedback
            :name-label="$t('message.feedBackForm1')"
            :email-label="$t('message.feedBackForm2')"
            :text-label="$t('message.feedBackForm3')"
            name-svg="perm_identity"
            email-svg="email"
            text-svg="comment"
            :id_name-label="237"
            :id_email-label="238"
            :id_text-label="239"
            :id_name-svg="240"
            :id_email-svg="241"
            :id_text-svg="242"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <hr class="my-6 hrComponent">
      <v-row>
        <v-col offset-lg="1" offset-md="1" offset-sm="1" col="12">
          <content-text
              :content="phoneText"
              :id_content="380"
              text-class="footerContactNumber"
          />
        </v-col>
      </v-row>
      <v-row class="mb-6">
          <v-col offset-lg="1" offset-md="1" offset-sm="1" col="12" class="footerInfo">
            <footer-icon-text
                :text-before="$t('message.contactInfo')"
                :text-after="$t('message.contactITMosaic')"
                svg-path="extension"
                :id_text-before="389"
                :id_text-after="390"
                div-class="footerInfo"
            />
          </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import '../../plugins/i18n.js'
import Feedback from '../ui/FeedbackForm'
import { mdiEmailOutline, mdiChevronUp, mdiBorderNone } from '@mdi/js'
import WorkForm from "@/components/ui/WorkForm.vue";
import PushLink from "@/components/ui/PushLink.vue";
import ImgContent from "@/components/ui/ImgContent.vue";
import ContentText from "@/components/ui/ContentText.vue";
import FooterImgLinks from "@/components/ui/FooterImgLinks.vue";
import SVGLinkText from "@/components/ui/SVGLinkText.vue";
import FooterIconText from "@/components/ui/FooterIconText.vue";
export default {
  components: {SVGLinkText,
    ContentText,
    ImgContent,
    WorkForm,
    Feedback,
    PushLink,
    FooterImgLinks,
    FooterIconText
  },
  data () {
    let addedPushLink1 = []
    let pushLink1IdList = [[351, 352]]
    let defaultPushLink1 = [{
      pushLink: '/products',
      linkText: this.$t('message.submenu5'),
      id_pushLink: 353,
      id_linkText: 354
    },
    {
      pushLink: '/management',
      linkText: this.$t('message.aboutUsSubmenu1'),
      id_pushLink: 355,
      id_linkText: 356
    },
    {
      pushLink: '/documents',
      linkText: this.$t('message.aboutUsSubmenu2'),
      id_pushLink: 357,
      id_linkText: 358
    },
    {
      pushLink: '/types_of_activities',
      linkText: this.$t('message.aboutUsSubmenu3'),
      id_pushLink: 359,
      id_linkText: 360
    },
    {
      pushLink: '/anticorruption_policy',
      linkText: this.$t('message.aboutUsSubmenu4'),
      id_pushLink: 361,
      id_linkText: 362
    },
    {
      pushLink: '/news',
      linkText: this.$t('message.aboutUsSubmenu5'),
      id_pushLink: 363,
      id_linkText: 364
    }
    ]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': pushLink1IdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showPushLink1Header = !hiddenContent[0]
      })
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'PushLinkFooter1' })
      .then(() => {
        addedPushLink1 = this.$store.getters['contentChanges/added_content_by_type']('PushLinkFooter1')
        this.addedPushLink1 = addedPushLink1
        this.loadedAddedPushLink1 = true
      })
    let defaultPushLink1IdList = defaultPushLink1.map(cont => [cont['id_linkText'], cont['id_pushLink']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultPushLink1IdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultPushLink1[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultPushLink1 = true
      })

    let addedPushLink2 = []
    let pushLink2IdList = [[365, 366]]
    let defaultPushLink2 = [{
      pushLink: '/logistics',
      linkText: this.$t('message.partnershipSubmenu1'),
      id_pushLink: 367,
      id_linkText: 368
    },
    {
      pushLink: '/for_suppliers',
      linkText: this.$t('message.partnershipSubmenu2'),
      id_pushLink: 369,
      id_linkText: 370
    },
    {
      pushLink: '/our_partners',
      linkText: this.$t('message.partnershipSubmenu3'),
      id_pushLink: 371,
      id_linkText: 372
    }
    ]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': pushLink2IdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showPushLink2Header = !hiddenContent[0]
      })
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'PushLinkFooter2' })
      .then(() => {
        addedPushLink2 = this.$store.getters['contentChanges/added_content_by_type']('PushLinkFooter2')
        this.addedPushLink2 = addedPushLink2
        this.loadedAddedPushLink2 = true
      })
    let defaultPushLink2IdList = defaultPushLink2.map(cont => [cont['id_linkText'], cont['id_pushLink']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultPushLink2IdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultPushLink2[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultPushLink2 = true
      })

    let addedPushLink3 = []
    let pushLink3IdList = [[373, 374]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': pushLink3IdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showPushLink3Header = !hiddenContent[0]
      })
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'PushLinkFooter3' })
      .then(() => {
        addedPushLink3 = this.$store.getters['contentChanges/added_content_by_type']('PushLinkFooter3')
        this.addedPushLink3 = addedPushLink3
        this.loadedAddedPushLink3 = true
      })

    let addedPushLink4 = []
    let pushLink4IdList = [[375, 376]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': pushLink4IdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showPushLink4Header = !hiddenContent[0]
      })
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'PushLinkFooter4' })
      .then(() => {
        addedPushLink4 = this.$store.getters['contentChanges/added_content_by_type']('PushLinkFooter4')
        this.addedPushLink4 = addedPushLink4
        this.loadedAddedPushLink4 = true
      })

    let addedImgContent1 = []
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'ImgContentFooter1' })
      .then(() => {
        addedImgContent1 = this.$store.getters['contentChanges/added_content_by_type']('ImgContentFooter1')
        this.addedImgContent1 = addedImgContent1
        this.loadedAddedImgContent1 = true
      })

    let addedImgContent2 = []
    let defaultImgContent2 = [{
      pathImg: './img/logos/Innovation_fund.svg',
      id_pathImg: 377
    }]
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'ImgContentFooter2' })
      .then(() => {
        addedImgContent2 = this.$store.getters['contentChanges/added_content_by_type']('ImgContentFooter2')
        this.addedImgContent2 = addedImgContent2
        this.loadedAddedImgContent2 = true
      })
    let defaultImgContent2IdList = defaultImgContent2.map(cont => [cont['id_pathImg']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultImgContent2IdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultImgContent2[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultImgContent2 = true
      })

    let addedImgContent3 = []
    let defaultImgContent3 = [{
      pathImg: './img/logos/logo22.png',
      id_pathImg: 378
    }]
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'ImgContentFooter3' })
      .then(() => {
        addedImgContent3 = this.$store.getters['contentChanges/added_content_by_type']('ImgContentFooter3')
        this.addedImgContent3 = addedImgContent3
        this.loadedAddedImgContent3 = true
      })
    let defaultImgContent3IdList = defaultImgContent3.map(cont => [cont['id_pathImg']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultImgContent3IdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultImgContent3[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultImgContent3 = true
      })

    let phoneText = { 'value_rus':
      '<p><a href="tel:' + this.$t('message.contactPhoneNum', 'ru') + '">' +
      this.$t('message.contactPhoneNum', 'ru') + '</a></p>',
    'value_eng':
      '<p><a href="tel:' + this.$t('message.contactPhoneNum', 'en-US') + '">' +
      this.$t('message.contactPhoneNum', 'en-US') + '</a></p>'
    }
    let addedImgContent4 = []
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'ImgContentFooter4' })
      .then(() => {
        addedImgContent4 = this.$store.getters['contentChanges/added_content_by_type']('ImgContentFooter4')
        this.addedImgContent4 = addedImgContent4
        this.loadedAddedImgContent4 = true
      })

    let addedFooterImgLinks = []
    let defaultFooterImgLinks = [{
      pathImg: './img/icons/vk.svg',
      link: 'https://vk.com/farminn',
      id_pathImg: 381,
      id_link: 382
    }]
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'FooterImgLinks' })
      .then(() => {
        addedPushLink1 = this.$store.getters['contentChanges/added_content_by_type']('FooterImgLinks')
        this.addedFooterImgLinks = addedFooterImgLinks
        this.loadedAddedFooterImgLinks = true
      })
    let defaultFooterImgLinksIdList = defaultFooterImgLinks.map(cont => [cont['id_link'], cont['id_pathImg']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultFooterImgLinksIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultFooterImgLinks[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultFooterImgLinks = true
      })

    let addedSVGLinkText = []
    let defaultSVGLinkText = [{
      text: this.$t('message.contactPhoneNum'),
      svgPath: 'phone',
      link: '"tel:' + this.$t('message.contactPhoneNum') + '"',
      id_text: 383,
      id_link: 384,
      id_svgPath: 385
    },
    {
      text: 'farminn@farminn.ru',
      svgPath: 'email',
      link: 'mailto:farminn@farminn.ru',
      id_text: 386,
      id_link: 387,
      id_svgPath: 388
    }
    ]
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'SVGLinkText' })
      .then(() => {
        addedSVGLinkText = this.$store.getters['contentChanges/added_content_by_type']('SVGLinkText')
        this.addedSVGLinkText = addedSVGLinkText
        this.loadedAddedSVGLinkText = true
      })
    let defaultSVGLinkTextIdList = defaultSVGLinkText.map(cont => [cont['id_text'], cont['id_link'], cont['id_svgPath']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultSVGLinkTextIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultSVGLinkText[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultSVGLinkText = true
      })

    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': [[379]] })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showContentText = !hiddenContent[0]
      })

    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': [[380]] })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showContentText2 = !hiddenContent[0]
      })

    return {
      defaultPushLink1: defaultPushLink1,
      addedPushLink1: addedPushLink1,
      loadedAddedPushLink1: false,
      loadedDefaultPushLink1: false,
      showPushLink1Header: false,

      defaultPushLink2: defaultPushLink2,
      addedPushLink2: addedPushLink2,
      loadedAddedPushLink2: false,
      loadedDefaultPushLink2: false,
      showPushLink2Header: false,

      addedPushLink3: addedPushLink3,
      loadedAddedPushLink3: false,
      showPushLink3Header: false,

      addedPushLink4: addedPushLink4,
      loadedAddedPushLink4: false,
      showPushLink4Header: false,

      addedImgContent1: addedImgContent1,
      loadedAddedImgContent1: false,

      defaultImgContent2: defaultImgContent2,
      addedImgContent2: addedImgContent2,
      loadedAddedImgContent2: false,
      loadedDefaultImgContent2: false,

      defaultImgContent3: defaultImgContent3,
      addedImgContent3: addedImgContent3,
      loadedAddedImgContent3: false,
      loadedDefaultImgContent3: false,

      addedImgContent4: addedImgContent4,
      loadedAddedImgContent4: false,

      defaultFooterImgLinks: defaultFooterImgLinks,
      addedFooterImgLinks: addedFooterImgLinks,
      loadedAddedFooterImgLinks: false,
      loadedDefaultFooterImgLinks: false,

      defaultSVGLinkText: defaultSVGLinkText,
      addedSVGLinkText: addedSVGLinkText,
      loadedAddedSVGLinkText: false,
      loadedDefaultSVGLinkText: false,

      showContentText:false,
      showContentText2:false,

      phoneText: phoneText,
      svgEmail: mdiEmailOutline,
      svgUp: mdiChevronUp,
      svgIcon: mdiBorderNone
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    pushLink1ToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultPushLink1
      } else {
        defaultContentToShow = this.defaultPushLink1.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedPushLink1) {
        let newAddedContent = {}
        newAddedContent['id_pushLink'] = this.get_field_id(content, 'pushLink')
        newAddedContent['id_linkText'] = this.get_field_id(content, 'linkText')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    pushLink2ToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultPushLink2
      } else {
        defaultContentToShow = this.defaultPushLink2.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedPushLink2) {
        let newAddedContent = {}
        newAddedContent['id_pushLink'] = this.get_field_id(content, 'pushLink')
        newAddedContent['id_linkText'] = this.get_field_id(content, 'linkText')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    pushLink3ToShow () {
      let showContent = []
      for (let content of this.addedPushLink3) {
        let newAddedContent = {}
        newAddedContent['id_pushLink'] = this.get_field_id(content, 'pushLink')
        newAddedContent['id_linkText'] = this.get_field_id(content, 'linkText')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    pushLink4ToShow () {
      let showContent = []
      for (let content of this.addedPushLink4) {
        let newAddedContent = {}
        newAddedContent['id_pushLink'] = this.get_field_id(content, 'pushLink')
        newAddedContent['id_linkText'] = this.get_field_id(content, 'linkText')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    imgContent1ToShow () {
      let showContent = []
      for (let content of this.addedImgContent1) {
        let newAddedContent = {}
        newAddedContent['id_pathImg'] = this.get_field_id(content, 'pathImg')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    imgContent2ToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultImgContent2
      } else {
        defaultContentToShow = this.defaultImgContent2.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedImgContent2) {
        let newAddedContent = {}
        newAddedContent['id_pathImg'] = this.get_field_id(content, 'pathImg')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    imgContent3ToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultImgContent3
      } else {
        defaultContentToShow = this.defaultImgContent3.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedImgContent3) {
        let newAddedContent = {}
        newAddedContent['id_pathImg'] = this.get_field_id(content, 'pathImg')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    imgContent4ToShow () {
      let showContent = []
      for (let content of this.addedImgContent4) {
        let newAddedContent = {}
        newAddedContent['id_pathImg'] = this.get_field_id(content, 'pathImg')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    footerImgLinksToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultFooterImgLinks
      } else {
        defaultContentToShow = this.defaultFooterImgLinks.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedFooterImgLinks) {
        let newAddedContent = {}
        newAddedContent['id_link'] = this.get_field_id(content, 'link')
        newAddedContent['id_pathImg'] = this.get_field_id(content, 'pathImg')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    SVGLinkTextToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultSVGLinkText
      } else {
        defaultContentToShow = this.defaultSVGLinkText.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedSVGLinkText) {
        let newAddedContent = {}
        newAddedContent['id_link'] = this.get_field_id(content, 'link')
        newAddedContent['id_text'] = this.get_field_id(content, 'text')
        newAddedContent['id_svgPath'] = this.get_field_id(content, 'svgPath')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    dialog: {
      get () { return this.$store.state['mail']['feedback'] },
      set (value) {
        if (value === false) {
          this.$store.dispatch('mail/HIDE_FORM', 'feedback')
        }
      }
    }
  },
  methods: {
    showFeedbackForm () {
      this.$store.dispatch('mail/SHOW_FORM', 'feedback')
    },
    hideFeedbackForm () {
      this.$store.dispatch('mail/HIDE_FORM', 'feedback')
    },
    add_content: async function (type, fieldList, addingList) {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': type,
        'field_list': fieldList
      }).then(() => {
        let lastAddedContent = this.$store.getters['contentChanges/last_added_content']
        addingList.push(lastAddedContent)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  }
}
</script>
