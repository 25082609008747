<template>
  <v-container class="product-instruction pa-0 ml-0">
    <v-expansion-panels accordion class="mt-4 product-expansion-panels">
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead1')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.compound}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead2')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.pharm_group}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead3')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.pharm_properties}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel >
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead4')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.indications_for_use}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead5')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.contraindications}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead6')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.app_during_pregnancy}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead7')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.dosage_and_administration}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead8')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.side_effects}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead9')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.overdose}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead10')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.inter_with_other_drugs}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead11')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.special_instructions}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead12')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.release_form}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead13')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.storage_conditions}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead14')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.best_before_date}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="product-expansion-panel">
        <v-expansion-panel-header expand-icon="mdi-chevron-down">
          <div class="product__label">{{$t('message.productInstructionSubHead15')}}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="product-expansion-panel-content">
          <div>{{instruction.holiday_conditions}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  name: 'ProductInstruction',
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    instruction: function () {
      let bdInstraction = this.$store.state['products']['current']['instruction']
      let resInstraction = {
        compound: bdInstraction['compound_rus'],
        description: bdInstraction['description_rus'],
        pharm_group: bdInstraction['pharm_group_rus'],
        pharm_properties: bdInstraction['pharm_properties_rus'],
        indications_for_use: bdInstraction['indications_for_use_rus'],
        contraindications: bdInstraction['contraindications_rus'],
        app_during_pregnancy: bdInstraction['app_during_pregnancy_rus'],
        dosage_and_administration: bdInstraction['dosage_and_administration_rus'],
        side_effects: bdInstraction['side_effects_rus'],
        overdose: bdInstraction['overdose_rus'],
        inter_with_other_drugs: bdInstraction['inter_with_other_drugs_rus'],
        special_instructions: bdInstraction['special_instructions_rus'],
        release_form: bdInstraction['release_form_rus'],
        storage_conditions: bdInstraction['storage_conditions_rus'],
        best_before_date: bdInstraction['best_before_date_rus'],
        holiday_conditions: bdInstraction['holiday_conditions_rus'] }
      if (this.lang === 'en-US') {
        resInstraction = {
          compounds: bdInstraction['compound_en'],
          description: bdInstraction['description_en'],
          pharm_group: bdInstraction['pharm_group_en'],
          pharm_properties: bdInstraction['pharm_properties_en'],
          indications_for_use: bdInstraction['indications_for_use_en'],
          contraindications: bdInstraction['contraindications_en'],
          app_during_pregnancy: bdInstraction['app_during_pregnancy_en'],
          dosage_and_administration: bdInstraction['dosage_and_administration_en'],
          side_effects: bdInstraction['side_effects_en'],
          overdose: bdInstraction['overdose_en'],
          inter_with_other_drugs: bdInstraction['inter_with_other_drugs_en'],
          special_instructions: bdInstraction['special_instructions_en'],
          release_form: bdInstraction['release_form_en'],
          storage_conditions: bdInstraction['storage_conditions_en'],
          best_before_date: bdInstraction['best_before_date_en'],
          holiday_conditions: bdInstraction['holiday_conditions_en']
        }
      }
      return resInstraction
    }
  }
}
</script>

<style scoped>

</style>