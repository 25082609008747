import axios from 'axios'

import * as types from '../mutation-types'

// state
export const state = {
  quantity: 0,
  limit: 100,
  offset: 0,
  filters: [],
  instruction: {},
  data: [],
  current: {}
}

// getters
export const getters = {
  quantity: state => state.quantity,
  limit: state => state.limit,
  offset: state => state.offset,
  filters: state => state.filters,
  instruction: state => state.instruction,
  get_data: state => state.data,
  get_current: state => state.current,
  product_by_id: state => id => state.data.filter(product => product.id == id)[0]
}

// mutations
export const mutations = {
  SET_QUANTITY (state, quantity) { state.quantity = quantity },
  SET_CURRENT (state, current) { state.current = current },
  SET_INSTRUCTION (state,
    instruction) { state.current['instruction'] = instruction },
  SET_DATA (state, { data }) { state.data = data },
  SET_FILTER (state, { limit, offset, filters }) {
    if (typeof (filters) !== 'undefined') {
      state.filters = filters
    }
    if (typeof (limit) !== 'undefined') {
      state.limit = limit
    }
    if (typeof (offset) !== 'undefined') {
      state.offset = offset
    }
    this.dispatch('products/GET_DATA', {
      'filters': state.filters,
      'limit': state.limit,
      'offset': state.offset
    })
  },
  SET_CERTIFICATE (state, certificate) {
    state.current.certificate = certificate
  },
  SET_CERTIFICATE_IMG (state, { dataurl, certificate_id }) {
    for (let certificate of state.current.certificate) {
      if (certificate.certificate_id === certificate_id) {
        certificate.img_urls = dataurl
      }
    }
  }
}

// actions
export const actions = {
  'SET_FILTER': async function ({ commit, state }, { filters, limit, offset }) {
    const limit_ = limit !== undefined ? limit : state.limit
    const offset_ = offset !== undefined ? offset : state.offset
    var newFilter = { filters: filters, limit: limit_, offset: offset_ }
    commit('SET_FILTER', newFilter)
    var path = filters.length === 0 ? '/api/products' : '/api/product_group'
    const { data } = await axios.post(path, newFilter)
    commit('SET_DATA', { data: data['result'] })
  },
  'GET_DATA': async function ({ commit, state }) {
    var path =
        state.filters.length === 0 ? '/api/products' : '/api/product_group'
    const { data } = await axios.post(path, {
      'filters': state.filters,
      'limit': state.limit,
      'offset': state.offset
    })
    commit('SET_DATA', { data: data['result'] })
  },
  'SET_CURRENT': async function ({ commit, state }, current) {
    const { data } =
        await axios.post('/api/current_product', { 'filters': [ current.id ] })
    commit('SET_CURRENT', data['result'])
    // commit('SET_INSTRUCTION', instruction);
  },
  'SET_QUANTITY': async function ({ commit, state }) {
    let { data } = await axios.post('/api/product_quantity', {
      'filters': state.filters,
      'limit': state.limit,
      'offset': state.offset })
    commit('SET_QUANTITY', data['result'])
  },
  'SET_SERTIFICATE': async function ({ commit, state }) {
    let response = await axios.post('/api/certificate_list', {
      'id': state.current.id })
    let certificate_list = response.data['result']
    commit('SET_CERTIFICATE', certificate_list)
    for (let certificate of certificate_list) {
      axios.post('/api/certificate_image', {
        'certificate_id': certificate.certificate_id }).then(function (response) {
        let img_urls = response.data.result
        for (let i = 0; i < img_urls.length; i++) {
          img_urls[i].page_num = i
        }
        commit('SET_CERTIFICATE_IMG', { 'dataurl': response.data.result, 'certificate_id': certificate.certificate_id })
      })
    }
  }
}
