<template>
  <v-card v-if="show"
         class="managementCard"
         width="100%"
         max-width="95%"
  >
    <v-row class="no-indentation">
      <v-col class="no-indentation text-center management-avatar">
        <v-avatar>
          <img :src='pathImgComputed'/>
        </v-avatar>
      </v-col>
      <v-col class="no-indentation managementDetail">
        <p class="managementFIO">
          {{ fioComputed }}
        </p>
        <p class="managementPost">
          {{ postComputed }}
        </p>
      </v-col>
      <div v-if="isAuth">
        <v-btn v-if="changeData" @click="pushChangeContent">Изменить</v-btn>
        <v-btn v-if="changeData && addedData" @click="deleteAddedContent">Удалить</v-btn>
        <v-btn v-if="changeData && hiddenData && !addedData" @click="showContent">Показать</v-btn>
        <v-btn v-if="changeData && !hiddenData && !addedData" @click="hideContent">Спрятать</v-btn>
      </div>
    </v-row>
  </v-card>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: ['pathImg',
    'fio',
    'post',
    'id_pathImg',
    'id_fio',
    'id_post',
    'added',
    'change',
    'hidden'
  ],
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let id_list = []
    if (this.id_pathImg) {
      id_list.push(this.id_pathImg)
    }
    if (this.id_fio) {
      id_list.push(this.id_fio)
    }
    if (this.id_post) {
      id_list.push(this.id_post)
    }
    let _pathImg = this.valueDict(this.pathImg, this.id_pathImg)
    let _fio = this.getT18nData(this.fio, this.id_fio)
    let _post = this.getT18nData(this.post, this.id_post)
    let promiseStore = this.$store
    let letThis = this
    new Promise(function (resolve, reject) {
      promiseStore.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = promiseStore.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_pathImg)) {
              _pathImg['value_rus'] = cont_item['value_rus']
              _pathImg['value_eng'] = cont_item['value_eng']
              if (cont_item['file']) { _pathImg['file'] = cont_item['file'] }
            }
            if (cont_item['id_content'] === parseInt(letThis.id_fio)) {
              _fio['value_rus'] = cont_item['value_rus']
              _fio['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_post)) {
              _post['value_rus'] = cont_item['value_rus']
              _post['value_eng'] = cont_item['value_eng']
            }
          }
          letThis.checkContent = true
        })
    })
    return {
      checkContent: false,
      deleted: false,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      pathImgData: _pathImg,
      fioData: _fio,
      postData: _post,
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'file': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'pathImg': this.pathImgData,
        'fio': this.fioData,
        'post': this.postData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'CardManagement',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_pathImg, this.id_fio, this.id_post]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_pathImg, this.id_fio, this.id_post]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_pathImg, this.id_fio, this.id_post]
      })
      this.hiddenData = true
    }
  },
  computed: {
    pathImgComputed () {
      if (!this.changeData) {
        if (File.prototype.isPrototypeOf(this.pathImg)) {
          this.pathImgData['value_rus'] = URL.createObjectURL(this.pathImg)
          this.pathImgData['value_eng'] = URL.createObjectURL(this.pathImg)
          this.pathImgData['file'] = URL.createObjectURL(this.pathImg)
        } else {
          this.pathImgData['value_rus'] = this.pathImg
          this.pathImgData['value_eng'] = this.pathImg
        }
      }
      if (this.pathImgData['file']) { return this.pathImgData['file'] }
      if (this.$i18n.locale == 'ru') { return this.pathImgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.pathImgData['value_eng'] }
    },
    fioComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.fioData['value_rus'] = this.fio }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.fioData['value_eng'] = this.fio }
      if (this.$i18n.locale == 'ru') { return this.fioData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.fioData['value_eng'] }
    },
    postComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.postData['value_rus'] = this.post }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.postData['value_eng'] = this.post }
      if (this.$i18n.locale == 'ru') { return this.postData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.postData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    show () {
      if ( (!this.checkContent) || this.deleted ) {
        return false
      }
      return true
    }
  }
}
</script>
