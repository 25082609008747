<template>
  <div>
    <div :class="divClass">
     <iframe :src="frameSrcComputed"/>
    </div>
    <div v-if="isAuth">
      <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentFrame",
  props: [
    'divClass',
    'frameSrc',
    'change',
    'id_frameSrc'
  ],
  data () {
    let changeData = this.change !== undefined ? this.change : true
    let id_list = [this.id_frameSrc].filter(id => id !== undefined)
    let _frameSrc = this.valueDict(this.frameSrc, this.id_frameSrc)
    let letThis = this
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = letThis.$store.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_frameSrc)) {
              _frameSrc['value_rus'] = cont_item['value_rus']
              _frameSrc['value_eng'] = cont_item['value_eng']
            }
          }
          letThis.checkContent = true
        })
    })
    return {
      checkContent: false,
      changeData: changeData,
      frameSrcData: _frameSrc
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      if (i18n_data == '') {
        return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
      }
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'frameSrc': this.frameSrcData }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'ContentFrame',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  },
  computed: {
    frameSrcComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.frameSrcData['value_rus'] = this.frameSrc }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.frameSrcData['value_eng'] = this.frameSrc }
      if (this.$i18n.locale == 'ru') { return this.frameSrcData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.frameSrcData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>

<style scoped>

</style>