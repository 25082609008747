<template>
  <v-col cols="12" lg="4" md="4" sm="12" class="mt-2 newsImg">
      <a :href="pathA" target="_blank">
          <v-img :src="pathImg" class="mx-lg-0 mx-md-0 mx-sm-auto" aspect-ratio="2"/>
      </a>
  </v-col>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: ['pathA', 'pathImg'],
  data () {
    return {
      locale: 'ru'
    }
  }
}
</script>
