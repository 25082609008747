<template>
  <div>
    <v-fab-transition v-if="offsetTop>5">
      <v-btn dark
        fab
        bottom
        left
        color="#298B94"
        x-large
        fixed
        @click="$vuetify.goTo(target, options)"
        v-show="!hidden"
        class="button-up"
      >
        <v-icon>{{ svgUp }}</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
import { mdiChevronUp } from '@mdi/js'
export default {
  props: ['offsetTop'],
  name: 'ButtonUp',
  data () {
    return {
      svgUp: mdiChevronUp,
      hidden: false,
      type: 'number',
      number: 0
    }
  },
  methods: {

  },
  computed: {
    target () {
      const value = this[this.type]
      if (!isNaN(value)) return Number(value)
      else return { value }
    },
    options () {
      return {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic'
      }
    }
  }
}
</script>
