<template>
  <v-container fluid class=" product-posts__item pa-0 ml-0">
    <v-row class="ma-0">
      <v-col lg="3" md="4" sm="5" class="pl-0">
        <v-img v-if="check_photo"
               v-on:error="check_photo = false"
               class="item-img"
               :src="photo"
        />
        <v-img v-else
               src="../../../public/img/icons/placeholder.png"
        />
        <p class="item-name">
          {{ name }}
        </p>
        <p class="item-post_man">
          <i>{{post_man}}</i>
        </p>
      </v-col>
      <v-col lg="9" md="8" sm="7" class="pr-0">
        <p class="item-date_post">
          {{ formatDate(date_post) }}
        </p>
        <p class="item-text">
          {{ text }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Product_post',
  props: ['name', 'text', 'pharm_man', 'post_man', 'email', 'photo', 'phone', 'created_view', 'display_on'],
  data () {
    return {
      check_photo: !!this.photo
    }
  },
  computed: {
    date_post () {
      return this.$props.created_view.slice(0, 10)
    }
  },
  methods: {
    formatDate (datePost) {
      if (!datePost) return null
      const [year, month, day] = datePost.split('-')
      return `${day}.${month}.${year}`
    }
  }
}
</script>

<style scoped>

</style>
