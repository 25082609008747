<template>
  <div class="contentText">
    <v-form ref="form"
            v-model="valid"
            lazy-validation
    >
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12" class="no-indentation">
          <v-text-field v-model="name"
                        :counter="50"
                        :rules="nameRules"
                        :label="nameLabelComputed"
                        class="inputStyle"
                        :prepend-inner-icon="nameSvgComputed"
                        required
                        color="teal"
                        outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12" class="no-indentation">
          <v-text-field v-model="email"
                        :rules="emailRules"
                        :label="emailLabelComputed"
                        :prepend-inner-icon="emailSvgComputed"
                        class="inputStyle"
                        required
                        color="teal"
                        outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12" class="no-indentation">
          <v-textarea class="inputStyle mt-2"
                      v-model="text"
                      :rules="textRules"
                      :label="textLabelComputed"
                      outlined
                      color="teal"
                      rows="6"
                      :prepend-inner-icon="textSvgComputed"
          />
        </v-col>
      </v-row>
      <div v-if="isAuth">
        <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
      </div>
      <v-row v-if="this.changeData">
        <v-col cols="12" lg="12" md="12" sm="12" class="no-indentation">
          <v-btn outlined
                 x-large
                 block
                 color="teal"
                 v-on:click="send()"
          >
            {{ $t('message.partnershipForSuppliersForm10') }}
          </v-btn>
          <v-dialog v-model="dialog"
                    hide-overlay
                    width="300"
          >
            <v-card>
              <v-card-text class="pt-6 pb-8 circle-check">
                <i class="material-icons mr-5 mt-3 ml-2">check_circle</i>
                <p>{{ $t('message.feedBackForm4') }}</p>
              </v-card-text>
            </v-card>
          </v-dialog>
          <AlertLoading :loading="dialog_loading"/>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import AlertLoading from './AlertLoading.vue'
export default {
  components: { AlertLoading },
  props: [ 'nameLabel',
    'emailLabel',
    'textLabel',
    'nameSvg',
    'emailSvg',
    'textSvg',

    'id_nameLabel',
    'id_emailLabel',
    'id_textLabel',
    'id_nameSvg',
    'id_emailSvg',
    'id_textSvg',
    'change'
  ],
  methods: {
    updatePhone: function (value) { console.log(value) },
    send: async function () {
      await this.$refs.form.validate()
      if (this.valid) {
        this.$store.dispatch('mail/SEND_FEDBACK', {
          'name': this.name,
          'email': this.email,
          'text': this.text })
      }
    },
    getT18nData (i18n_data, id_content) {
      if (i18n_data == '') {
        return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
      }
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'nameLabel': this.nameLabelData,
        'emailLabel': this.emailLabelData,
        'textLabel': this.textLabelData,
        'nameSvg': this.nameSvgData,
        'emailSvg': this.emailSvgData,
        'textSvg': this.textSvgData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'FeedbackForm',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  },
  computed: {
    nameLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.nameLabelData['value_rus'] = this.nameLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.nameLabelData['value_eng'] = this.nameLabel }
      if (this.$i18n.locale == 'ru') { return this.nameLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.nameLabelData['value_eng'] }
    },
    emailLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.emailLabelData['value_rus'] = this.emailLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.emailLabelData['value_eng'] = this.emailLabel }
      if (this.$i18n.locale == 'ru') { return this.emailLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.emailLabelData['value_eng'] }
    },
    textLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textLabelData['value_rus'] = this.textLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textLabelData['value_eng'] = this.textLabel }
      if (this.$i18n.locale == 'ru') { return this.textLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textLabelData['value_eng'] }
    },
    nameSvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.nameSvgData['value_rus'] = this.nameSvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.nameSvgData['value_eng'] = this.nameSvg }
      if (this.$i18n.locale == 'ru') { return this.nameSvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.nameSvgData['value_eng'] }
    },
    emailSvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.emailSvgData['value_rus'] = this.emailSvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.emailSvgData['value_eng'] = this.emailSvg }
      if (this.$i18n.locale == 'ru') { return this.emailSvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.emailSvgData['value_eng'] }
    },
    textSvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textSvgData['value_rus'] = this.textSvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textSvgData['value_eng'] = this.textSvg }
      if (this.$i18n.locale == 'ru') { return this.textSvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textSvgData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    dialog: {
      get () {
        if (this.$store.getters['mail/feedbackStatus'] === 'ok') {
          this.$refs.form.reset()
          this.$store.dispatch('mail/SET_READY', 'feedback')
        }
        return this.$store.state['mail']['feedbackLoad']
      },
      set (value) {
        this.$store.dispatch('mail/HIDE_FORM', 'feedbackLoad')
      }
    },
    dialog_loading: {
      get () {
        return this.$store.getters['mail/feedbackLoading']
      }
    }
  },
  name: 'FeedbackForm',
  data () {
    let id_list = [this.id_nameLabel,
      this.id_emailLabel,
      this.id_textLabel,
      this.id_nameSvg,
      this.id_emailSvg,
      this.id_textSvg].filter(id => id !== undefined)
    let changeData = this.change !== undefined ? this.change : true
    let _nameLabel = this.getT18nData(this.nameLabel, this.id_nameLabel)
    let _emailLabel = this.getT18nData(this.emailLabel, this.id_emailLabel)
    let _textLabel = this.getT18nData(this.textLabel, this.id_textLabel)
    let _nameSvg = this.valueDict(this.nameSvg, this.id_nameSvg)
    let _emailSvg = this.valueDict(this.emailSvg, this.id_emailSvg)
    let _textSvg = this.valueDict(this.textSvg, this.id_textSvg)
    let letThis = this
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = letThis.$store.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_nameLabel)) {
              _nameLabel['value_rus'] = cont_item['value_rus']
              _nameLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_emailLabel)) {
              _emailLabel['value_rus'] = cont_item['value_rus']
              _emailLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_textLabel)) {
              _textLabel['value_rus'] = cont_item['value_rus']
              _textLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_nameSvg)) {
              _nameSvg['value_rus'] = cont_item['value_rus']
              _nameSvg['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_emailSvg)) {
              _emailSvg['value_rus'] = cont_item['value_rus']
              _emailSvg['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_textSvg)) {
              _textSvg['value_rus'] = cont_item['value_rus']
              _textSvg['value_eng'] = cont_item['value_eng']
            }
          }
          letThis.checkContent = true
        })
    })
    return {
      checkContent: false,
      nameLabelData: _nameLabel,
      emailLabelData: _emailLabel,
      textLabelData: _textLabel,
      nameSvgData: _nameSvg,
      emailSvgData: _emailSvg,
      textSvgData: _textSvg,
      changeData: changeData,
      valid: true,
      name: '',
      text: '',
      nameRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 50) || 'Имя должно содержать не более 50 символов'
      ],
      email: '',
      emailRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\.\w{2,4}/.test(v) || 'Электронная почта должна быть действительной'
      ],
      textRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 200) || 'Имя должно содержать не более 200 символов'
      ]
    }
  }
}
</script>
