export const state = {
  show: false,
  message: ''
}

export const getters = {
  show: state => state.show,
  message: state => state.message
}

export const mutations = {
  SET_SHOW (state, value) {
    state.show = value
  },
  SET_MESSAGE (state, value) {
    state.message = value
  }
}

export const actions = {
  'SET_DEFAULT':
    async function ({ commit }) {
      commit('SET_SHOW', false)
      commit('SET_MESSAGE', '')
    },
  'SHOW_ERROR':
    async function ({ commit }) {
      commit('SET_SHOW', true)
    },
  'SET_MSG':
    async function ({ commit }, message) {
      commit('SET_MESSAGE', message)
    }
}
