<template>
  <v-content>
    <child/>
  </v-content>
</template>

<script>

export default {
  name: 'default'
}
</script>
