<template>
  <div style="width: 100%!important;">
    <v-btn-toggle class="menuBtnToggle">
      <div class="subMenuBlock">
        <Submenu :submenu="$t('message.partnershipSubmenu1')"
                 :push="pushLogistics"
                 :svg-path="svgPath1"
                 :id_submenu="62"
                 :id_svg-path="63"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.partnershipSubmenu1')"
                :svg-path="svgPath1"
                :id_submenu="62"
                :id_svg-path="63"
        />
      </div>
      <div class="subMenuBlock">
        <Submenu :submenu="$t('message.partnershipSubmenu2')"
                 :push="pushForSuppliers"
                 :svg-path="svgPath2"
                 :id_submenu="64"
                 :id_svg-path="65"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.partnershipSubmenu2')"
                :svg-path="svgPath2"
                :id_submenu="64"
                :id_svg-path="65"
        />
      </div>
      <div class="subMenuBlock">
        <Submenu :submenu="$t('message.partnershipSubmenu3')"
                 :push="pushOurPartners"
                 :svg-path="svgPath3"
                 :id_submenu="66"
                 :id_svg-path="67"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.partnershipSubmenu3')"
                :svg-path="svgPath3"
                :id_submenu="66"
                :id_svg-path="67"
        />
      </div>
      <div class="subMenuBlock" style="display:none">
        <Submenu :submenu="$t('message.partnershipSubmenu4')"
                 :push="pushOurClients"
                 :svg-path="svgPath4"
                 :id_submenu="68"
                 :id_svg-path="69"
        />
        <change-submenu-click
                v-if="isAuth"
                :submenu="$t('message.partnershipSubmenu4')"
                :svg-path="svgPath4"
                :id_submenu="68"
                :id_svg-path="69"
        />
      </div>
    </v-btn-toggle>
  </div>
</template>
<script>
import Submenu from '../ui/Submenu'
import ChangeSubmenuClick from "@/components/ui/ChangeContent/ChangeSubmenuClick.vue";
import { mdiTruckFast, mdiCarInfo, mdiAccountTieOutline, mdiAccountHeartOutline } from '@mdi/js'

export default {
  components: {ChangeSubmenuClick, Submenu },
  props: ['toggle_none'],
  name: 'PartnershipSubmenu',
  data () {
    return {
      svgPath1: mdiTruckFast,
      svgPath2: mdiCarInfo,
      svgPath3: mdiAccountTieOutline,
      svgPath4: mdiAccountHeartOutline
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  },
  methods: {
    pushLogistics () {
      this.$router.push('/logistics')
    },
    pushForSuppliers () {
      this.$router.push('/for_suppliers')
    },
    pushOurPartners () {
      this.$router.push('/our_partners')
    },
    pushOurClients () {
      this.$router.push('/our_clients')
    }
  }
}
</script>
