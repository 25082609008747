<template>
  <div v-if="show">
   <v-card class="logoCard"
           width="100%"
           max-width="95%"
   >
     <a v-if="linkComputed" :href="linkComputed">
       <img :src="pathImgComputed"/>
     </a>
     <img v-else :src="pathImgComputed"/>
    </v-card>
    <div v-if="isAuth">
      <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
      <v-btn v-if="this.changeData && this.addedData" @click="deleteAddedContent">Удалить</v-btn>
      <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать</v-btn>
      <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать</v-btn>
    </div>
  </div>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: [ 'pathImg',
    'link',
    'id_pathImg',
    'id_link',
    'added',
    'change',
    'hidden'
  ],
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let id_list = []
    if (this.id_pathImg) {
      id_list.push(this.id_pathImg)
    }
    if (this.id_link) {
      id_list.push(this.id_link)
    }
    let _link = this.valueDict(this.link, this.id_link)
    let _pathImg = this.valueDict(this.pathImg, this.id_pathImg)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let cont_item of content) {
          if (cont_item['id_content'] === parseInt(this.id_pathImg)) {
            _pathImg['value_rus'] = cont_item['value_rus']
            _pathImg['value_eng'] = cont_item['value_eng']
            if (cont_item['file']) { _pathImg['file'] = cont_item['file'] }
          }
          if (cont_item['id_content'] === parseInt(this.id_link)) {
            _link['value_rus'] = cont_item['value_rus']
            _link['value_eng'] = cont_item['value_eng']
          }
        }
        this.checkContent = true
      })
    return {
      checkContent: false,
      deleted: false,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      pathImgData: _pathImg,
      linkData: _link
    }
  },
  methods: {
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'file': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'pathImg': this.pathImgData, 'link': this.linkData }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'CardLogo',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_pathImg, this.id_link]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_pathImg, this.id_link]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_pathImg, this.id_link]
      })
      this.hiddenData = true
    }
  },
  computed: {
    pathImgComputed () {
      if (!this.changeData) {
        if (File.prototype.isPrototypeOf(this.pathImg)) {
          this.pathImgData['value_rus'] = URL.createObjectURL(this.pathImg)
          this.pathImgData['value_eng'] = URL.createObjectURL(this.pathImg)
          this.pathImgData['file'] = URL.createObjectURL(this.pathImg)
        } else {
          this.pathImgData['value_rus'] = this.pathImg
          this.pathImgData['value_eng'] = this.pathImg
        }
      }
      if (this.pathImgData['file']) { return this.pathImgData['file'] }
      if (this.$i18n.locale == 'ru') { return this.pathImgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.pathImgData['value_eng'] }
    },
    linkComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.linkData['value_rus'] = this.link }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.linkData['value_eng'] = this.link }
      if (this.$i18n.locale == 'ru') { return this.linkData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.linkData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    show () {
      if (this.deleted || (this.hidden && !this.isAuth)) {
        return false
      }
      return true
    }
  }
}
</script>
