<template>
  <div class="contentText">
    <v-form ref="form"
            v-model="valid"
            lazy-validation
    >
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-text-field v-model="fio"
                        :counter="50"
                        :rules="textFieldRules"
                        :label="fioLabelComputed"
                        class="inputStyle"
                        :prepend-inner-icon="fioSvgComputed"
                        required
                        outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-text-field v-model="vacantion"
                        :counter="50"
                        :rules="textFieldRules"
                        :label="vacantionLabelComputed"
                        :prepend-inner-icon="vacantionSvgComputed"
                        class="inputStyle"
                        required
                        outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-text-field v-model="city"
                        :label="cityLabelComputed"
                        class="inputStyle"
                        :rules="textFieldRules"
                        :prepend-inner-icon="citySvgComputed"
                        outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-file-input :label="fileLabelComputed"
                        @change="onUploadFiles"
                        :rules="fileRules"
                        class="inputStyle"
                        small-chips
                        outlined
                        :prepend-inner-icon="fileSvgComputed"
                        prepend-icon=""
                        multiple
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-text-field v-model="email"
                        :rules="emailRules"
                        :label="emailLabelComputed"
                        :prepend-inner-icon="emailSvgComputed"
                        class="inputStyle"
                        required
                        outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col ccols="12" lg="12" md="12" sm="12">
          <VuePhoneNumberInput v-model="phone"
                               v-on:update="updatePhone"
                               v-bind:translations="myTranslation"
                               required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="12">
          <v-textarea class="inputStyle mt-12"
                      :label="textLabelComputed"
                      outlined
                      :rules="textAreaRules"
                      rows="6"
                      v-model="text"
                      :prepend-inner-icon="textSvgComputed"
          />
        </v-col>
      </v-row>
      <div v-if="isAuth">
        <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
      </div>
      <v-row v-if="this.changeData">
        <v-col cols="12" lg="6" md="7" sm="6">
          <v-btn outlined
                 x-large
                 v-on:click="send()"
                 color="teal darken-4"
          >{{ $t('message.careerWorkForm6') }}</v-btn>
          <v-dialog v-model="dialog"
                    width="300"
          >
            <v-card
            >
              <v-card-text class="pt-6 pb-8 circle-check">
                <i class="material-icons mr-5 mt-3 ml-2">check_circle</i>
                <p>{{ $t('message.careerWorkForm9') }}</p>
              </v-card-text>
            </v-card>
          </v-dialog>
          <AlertLoading :loading="dialog_loading"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <span class="remark">{{ $t('message.careerWorkForm7') }} <a href="#">{{ $t('message.careerWorkForm8') }}</a>.</span>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import AlertLoading from './AlertLoading.vue'
export default {
  components: { VuePhoneNumberInput, AlertLoading },
  props: [ 'fioLabel',
    'vacantionLabel',
    'cityLabel',
    'fileLabel',
    'emailLabel',
    'textLabel',
    'fioSvg',
    'vacantionSvg',
    'citySvg',
    'fileSvg',
    'emailSvg',
    'textSvg',

    'id_fioLabel',
    'id_vacantionLabel',
    'id_cityLabel',
    'id_fileLabel',
    'id_emailLabel',
    'id_textLabel',
    'id_fioSvg',
    'id_vacantionSvg',
    'id_citySvg',
    'id_fileSvg',
    'id_emailSvg',
    'id_textSvg',
    'change'
  ],
  methods: { updatePhone: function (value) { },
    onUploadFiles: function (files) { this.files = files },
    send: async function () {
      await this.$refs.form.validate()
      if (this.valid) {
        this.$store.dispatch('mail/SEND_WORKFORM', {
          'fio': this.fio,
          'vacantion': this.vacantion,
          'city': this.city,
          'phone': this.phone,
          'email': this.email,
          'text': this.text,
          'files': this.files
        })
      }
    },
    getT18nData (i18n_data, id_content) {
      if (i18n_data == '') {
        return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
      }
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'fioLabel': this.fioLabelData,
        'vacantionLabel': this.vacantionLabelData,
        'cityLabel': this.cityLabelData,
        'fileLabel': this.fileLabelData,
        'emailLabel': this.emailLabelData,
        'textLabel': this.textLabelData,
        'fioSvg': this.fioSvgData,
        'vacantionSvg': this.vacantionSvgData,
        'citySvg': this.citySvgData,
        'fileSvg': this.fileSvgData,
        'emailSvg': this.emailSvgData,
        'textSvg': this.textSvgData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'WorkForm',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  },
  computed: {
    fioLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.fioLabelData['value_rus'] = this.fioLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.fioLabelData['value_eng'] = this.fioLabel }
      if (this.$i18n.locale == 'ru') { return this.fioLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.fioLabelData['value_eng'] }
    },
    vacantionLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.vacantionLabelData['value_rus'] = this.vacantionLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.vacantionLabelData['value_eng'] = this.vacantionLabel }
      if (this.$i18n.locale == 'ru') { return this.vacantionLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.vacantionLabelData['value_eng'] }
    },
    cityLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.cityLabelData['value_rus'] = this.cityLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.cityLabelData['value_eng'] = this.cityLabel }
      if (this.$i18n.locale == 'ru') { return this.cityLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.cityLabelData['value_eng'] }
    },
    fileLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.fileLabelData['value_rus'] = this.fileLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.fileLabelData['value_eng'] = this.fileLabel }
      if (this.$i18n.locale == 'ru') { return this.fileLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.fileLabelData['value_eng'] }
    },
    emailLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.emailLabelData['value_rus'] = this.emailLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.emailLabelData['value_eng'] = this.emailLabel }
      if (this.$i18n.locale == 'ru') { return this.emailLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.emailLabelData['value_eng'] }
    },
    textLabelComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textLabelData['value_rus'] = this.textLabel }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textLabelData['value_eng'] = this.textLabel }
      if (this.$i18n.locale == 'ru') { return this.textLabelData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textLabelData['value_eng'] }
    },
    fioSvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.fioSvgData['value_rus'] = this.fioSvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.fioSvgData['value_eng'] = this.fioSvg }
      if (this.$i18n.locale == 'ru') { return this.fioSvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.fioSvgData['value_eng'] }
    },
    vacantionSvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.vacantionSvgData['value_rus'] = this.vacantionSvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.vacantionSvgData['value_eng'] = this.vacantionSvg }
      if (this.$i18n.locale == 'ru') { return this.vacantionSvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.vacantionSvgData['value_eng'] }
    },
    citySvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.citySvgData['value_rus'] = this.citySvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.citySvgData['value_eng'] = this.citySvg }
      if (this.$i18n.locale == 'ru') { return this.citySvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.citySvgData['value_eng'] }
    },
    fileSvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.fileSvgData['value_rus'] = this.fileSvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.fileSvgData['value_eng'] = this.fileSvg }
      if (this.$i18n.locale == 'ru') { return this.fileSvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.fileSvgData['value_eng'] }
    },
    emailSvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.emailSvgData['value_rus'] = this.emailSvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.emailSvgData['value_eng'] = this.emailSvg }
      if (this.$i18n.locale == 'ru') { return this.emailSvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.emailSvgData['value_eng'] }
    },
    textSvgComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textSvgData['value_rus'] = this.textSvg }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textSvgData['value_eng'] = this.textSvg }
      if (this.$i18n.locale == 'ru') { return this.textSvgData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textSvgData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    myTranslation () {
      return {
        countrySelectorLabel: this.$t('message.partnershipForSuppliersForm6'),
        countrySelectorError: this.$t('message.partnershipForSuppliersForm6Error'),
        phoneNumberLabel: this.$t('message.partnershipForSuppliersForm7'),
        example: this.$t('message.partnershipForSuppliersForm7Example')
      }
    },
    dialog: {
      get () { return this.$store.state['mail']['workformLoad'] },
      set (value) {
        if (this.$store.getters['mail/workformStatus'] === 'ok') {
          this.$refs.form.reset()
          this.$store.dispatch('mail/SET_READY', 'workform')
        }
        this.$store.dispatch('mail/HIDE_FORM', 'workformLoad')
      }
    },
    dialog_loading: {
      get () {
        return this.$store.getters['mail/workformLoading']
      }
    }
  },
  name: 'WorkForm',
  data () {
    let id_list = [this.id_fioLabel,
      this.id_vacantionLabel,
      this.id_cityLabel,
      this.id_fileLabel,
      this.id_emailLabel,
      this.id_textLabel,
      this.id_fioSvg,
      this.id_vacantionSvg,
      this.id_citySvg,
      this.id_fileSvg,
      this.id_emailSvg,
      this.id_textSvg].filter(id => id !== undefined)
    let changeData = this.change !== undefined ? this.change : true
    let _fioLabel = this.getT18nData(this.fioLabel, this.id_fioLabel)
    let _vacantionLabel = this.getT18nData(this.vacantionLabel, this.id_vacantionLabel)
    let _cityLabel = this.getT18nData(this.cityLabel, this.id_cityLabel)
    let _fileLabel = this.getT18nData(this.fileLabel, this.id_fileLabel)
    let _emailLabel = this.getT18nData(this.emailLabel, this.id_emailLabel)
    let _textLabel = this.getT18nData(this.textLabel, this.id_textLabel)
    let _fioSvg = this.valueDict(this.fioSvg, this.id_fioSvg)
    let _vacantionSvg = this.valueDict(this.vacantionSvg, this.id_vacantionSvg)
    let _citySvg = this.valueDict(this.citySvg, this.id_citySvg)
    let _fileSvg = this.valueDict(this.fileSvg, this.id_fileSvg)
    let _emailSvg = this.valueDict(this.emailSvg, this.id_emailSvg)
    let _textSvg = this.valueDict(this.textSvg, this.id_textSvg)
    let letThis = this
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = letThis.$store.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_fioLabel)) {
              _fioLabel['value_rus'] = cont_item['value_rus']
              _fioLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_vacantionLabel)) {
              _vacantionLabel['value_rus'] = cont_item['value_rus']
              _vacantionLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_cityLabel)) {
              _cityLabel['value_rus'] = cont_item['value_rus']
              _cityLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_fileLabel)) {
              _fileLabel['value_rus'] = cont_item['value_rus']
              _fileLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_emailLabel)) {
              _emailLabel['value_rus'] = cont_item['value_rus']
              _emailLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_textLabel)) {
              _textLabel['value_rus'] = cont_item['value_rus']
              _textLabel['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_fioSvg)) {
              _fioSvg['value_rus'] = cont_item['value_rus']
              _fioSvg['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_vacantionSvg)) {
              _vacantionSvg['value_rus'] = cont_item['value_rus']
              _vacantionSvg['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_citySvg)) {
              _citySvg['value_rus'] = cont_item['value_rus']
              _citySvg['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_fileSvg)) {
              _fileSvg['value_rus'] = cont_item['value_rus']
              _fileSvg['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_emailSvg)) {
              _emailSvg['value_rus'] = cont_item['value_rus']
              _emailSvg['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_textSvg)) {
              _textSvg['value_rus'] = cont_item['value_rus']
              _textSvg['value_eng'] = cont_item['value_eng']
            }
          }
          letThis.checkContent = true
        })
    })
    return {
      checkContent: false,
      fioLabelData: _fioLabel,
      vacantionLabelData: _vacantionLabel,
      cityLabelData: _cityLabel,
      fileLabelData: _fileLabel,
      emailLabelData: _emailLabel,
      textLabelData: _textLabel,
      fioSvgData: _fioSvg,
      vacantionSvgData: _vacantionSvg,
      citySvgData: _citySvg,
      fileSvgData: _fileSvg,
      emailSvgData: _emailSvg,
      textSvgData: _textSvg,
      changeData: changeData,
      valid: true,
      fio: '',
      vacantion: '',
      city: '',
      text: '',
      files: [],
      textFieldRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 50) || 'Имя должно содержать не более 50 символов'
      ],
      email: '',
      emailRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\.\w{2,4}/.test(v) || 'Электронная почта должна быть действительной'
      ],
      phone: '',
      fileRules: [
        v => !!v || 'Обязательное поле'
      ],
      textAreaRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 200) || 'Поле должно содержать не более 200 символов'
      ]
    }
  }
}
</script>

<style scoped>

</style>
