import axios from 'axios'

// state
export const state = {
  limit: 100,
  offset: 0,
  filters: [],
  data: [],
  current: {}
}

// getters
export const getters = {
  limit: state => state.limit,
  offset: state => state.offset,
  filters: state => state.filters,
  get_data: state => state.data,
  get_current: state => state.current[0],
  group_by_id: state => id => state.data.filter(group => group.id == id)[0]
}

// mutations
export const mutations = {
  SET_CURRENT (state, current) {
    state.current = current
  },
  SET_DATA (state, data) { state.data = data },
  SET_FILTER (state, { limit, offset, filters }) {
    if (typeof (filters) !== 'undefined') {
      state.filters = filters
    };
    if (typeof (limit) !== 'undefined') {
      state.limit = limit
    };
    if (typeof (offset) !== 'undefined') {
      state.offset = offset
    };
    this.dispatch('groups/GET_DATA')
  }
}

// actions
export const actions = {
  'SET_FILTER': async function ({ commit }, { filters, limit, offset }) {
    const limit_ = limit !== undefined ? limit : state.limit
    const offset_ = offset !== undefined ? offset : state.offset
    var newFilter = { filters: filters, limit: limit_, offset: offset_ }
    commit('SET_FILTER', newFilter)
    let path = filters.length === 0 ? '/api/group' : '/api/group_product'
    const { data } = await axios.post(path, newFilter)
    commit('SET_DATA', { data: data['result'] })
  },
  'GET_DATA': async function ({ commit }) {
    let path = state.filters.length === 0 ? '/api/group' : '/api/group_product'
    const { data } = await axios.post(path, {
      'filters': state.filters,
      'limit': state.limit,
      'offset': state.offset
    })
    commit('SET_DATA', data['result'])
  },
  'SET_CURRENT':
      async function ({ commit }, current) {
        commit('SET_CURRENT', current)
      },
  'SET_CURRENT_BY_PRODUCT_ID':
      async function ({ commit }, product_id) {
        let newFilter = { filters: [product_id], limit: state.limit, offset: state.offset }
        let path = '/api/group_product'
        const { data } = await axios.post(path, newFilter)
        commit('SET_CURRENT', data['result'])
      }
}
