import axios from 'axios'
import Cookies from 'js-cookie'

export const state = {
  isAuth: false,
  isAuthCookie: Cookies.get('farminn_auth'),
  message: ''
}

export const getters = {
  isAuth: state => state.isAuth,
  isAuthCookie: state => state.isAuthCookie,
  checkAuth: state => {
    let cookie= false
    if (Cookies.get('farminn_auth') === 'true') {
      cookie = true
    }
    return state.isAuth || cookie
  },
  message: state => state.message
}

export const mutations = {
  SET_IS_AUTH (state, { value }) {
    state.isAuth = value
  },
  SET_IS_AUTH_COOKIE (state, { value }) {
    Cookies.set('farminn_auth', value, { expires: 30 })
  },
  SET_MESSAGE (state, { value }) {
    state.message = value
  }
}

export const actions = {
  'LOGIN': async function ({ commit }, { login, password }) {
    await axios.post('/api/check_authentication', {
      'login': login,
      'password': password
    }).then(function (response) {
      commit('SET_IS_AUTH', { value: response['data']['result'] })
      commit('SET_IS_AUTH_COOKIE', { value: response['data']['result'] })
      if (response['data']['result']) {
        commit('SET_MESSAGE', { value: 'Вы авторизированы' })
      } else {
        commit('SET_MESSAGE', { value: 'Не удалось авторизироваться' })
      }
    }).catch(function (error) {
      console.log(error)
    })
  },
  'LOGOUT': async function ({ commit }) {
    commit('SET_IS_AUTH', { value: false })
    commit('SET_IS_AUTH_COOKIE', { value: false })
    commit('SET_MESSAGE', { value: 'Вы не авторизированы' })
  },
  'SET_MESSAGE': async function ({ commit }, { message }) {
    commit('SET_MESSAGE', { value: message })
  }
}
