<template>
  <v-dialog v-model='show'>
    <v-snackbar
        v-model="show"
        timeout="-1"
        transition="dialog-top-transition"
        color="error"
        class="pa-0"
        centered
    >
      <v-alert prominent
               type="error"
               class="mb-0">
          {{ message }}
      </v-alert>
    </v-snackbar>
  </v-dialog>
</template>
<script>
export default {
  name: 'ErrorDialog',
  computed: {
    show: {
      get () {
        return this.$store.getters['error/show']
      },
      set (value) {
        this.$store.dispatch('error/SET_DEFAULT')
      } },
    message () { return this.$store.getters['error/message'] }
  }
}
</script>
