<template>
  <div>
    <a @click="push" :class="linkClass" >  {{ linkTextComputed }}</a>
    <div v-if="isAuth">
      <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
      <v-btn v-if="this.changeData && this.addedData" @click="deleteAddedContent">Удалить</v-btn>
      <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать</v-btn>
      <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать</v-btn>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PushLink',
  props: [
    'pushLink',
    'linkText',
    'linkClass',
    'id_pushLink',
    'id_linkText',
    'added',
    'change',
    'hidden'
  ],
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let id_list = [this.id_pushLink,
      this.id_linkText
    ]
    let _linkText = this.getT18nData(this.linkText, this.id_linkText)
    let _pushLink = this.valueDict(this.pushLink, this.id_pushLink)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let contItem of content) {
          if (contItem['id_content'] === parseInt(this.id_linkText)) {
            _linkText['value_rus'] = contItem['value_rus']
            _linkText['value_eng'] = contItem['value_eng']
          }
          if (contItem['id_content'] === parseInt(this.id_pushLink)) {
            _pushLink['value_rus'] = contItem['value_rus']
            _pushLink['value_eng'] = contItem['value_eng']
          }
        }
        this.checkContent = true
      })
    return {
      checkContent: false,
      deleted: false,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      pushLinkData: _pushLink,
      linkTextData: _linkText
    }
  },
  methods: {
    push () {
      this.$store.dispatch('products/SET_FILTER', { filters: [] })
      this.$router.push(this.pushLinkComputed)
    },
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'pushLink': this.pushLinkData,
        'linkText': this.linkTextData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'PushLink',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_pushLink, this.id_linkText]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_pushLink, this.id_linkText]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_pushLink, this.id_linkText]
      })
      this.hiddenData = true
    }
  },
  computed: {
    pushLinkComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.pushLinkData['value_rus'] = this.pushLink }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.pushLinkData['value_eng'] = this.pushLink }
      if (this.$i18n.locale == 'ru') { return this.pushLinkData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.pushLinkData['value_eng'] }
    },
    linkTextComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.linkTextData['value_rus'] = this.linkText }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.linkTextData['value_eng'] = this.linkText }
      if (this.$i18n.locale == 'ru') { return this.linkTextData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.linkTextData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
})
</script>

<style scoped>

</style>
