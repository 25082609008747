<template>
 <v-breadcrumbs v-if="this.$route.name!='main'"
                :items="breadCrumbs"
                class="breadcrumbs"
 >
   <template v-slot:item="{ item }">
     <v-breadcrumbs-item
        :to="item.to"
        :disabled="item.disabled"
        class="pt-5"
        exact
      >
        {{ $t(item.text) }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
import { mdiChevronRight } from '@mdi/js'
export default {
  name: 'Breadcrumbs',
  data () {
    return {
      svgDivider: mdiChevronRight,
      breadcrumbList: []
    }
  },
  computed: {
    breadCrumbs () {
      if (typeof this.$route.meta.breadCrumb !== 'function') {
        return this.$route.meta.breadCrumb
      }
      let funcsToChangeBreadCrumbText = {
        'IdGroupToGroupName': this.IdGroupToGroupName,
        'GetCurrentProductName': this.GetCurrentProductName,
        'GetCurrentGroupName': this.GetCurrentGroupName
      }
      let funcsToChangeBredCrumbProps = {
        'GetCurrentGroupId': this.GetCurrentGroupId
      }
      let returnBreadCrumbs = this.$route.meta.breadCrumb.call(this.$route).map(bredCrumb => {
        if (typeof bredCrumb.functionForText !== 'string') {
          return bredCrumb
        }
        let functionForText = bredCrumb.functionForText
        if ((typeof funcsToChangeBreadCrumbText[functionForText] === 'function') &&
            (typeof bredCrumb.text === 'string')) {
          let text = bredCrumb.text
          bredCrumb.text = funcsToChangeBreadCrumbText[functionForText](this.$route.params[text.slice(1)])
        }
        if (typeof bredCrumb.to === 'object') {
          if (typeof bredCrumb.to.params === 'object') {
            if (typeof bredCrumb.functions_for_params === 'object') {
              for (let propKey in bredCrumb.to.params) {
                for (let funcKey in bredCrumb.functions_for_params) {
                  if (propKey === funcKey) {
                    let func = bredCrumb.functions_for_params[funcKey]
                    if (bredCrumb.to.params[propKey] === null) {
                      bredCrumb.to.params[propKey] = funcsToChangeBredCrumbProps[func]()
                    } else {
                      bredCrumb.to.params[propKey] = funcsToChangeBredCrumbProps[func](propKey)
                    }
                  }
                }
              }
            }
          }
        }
        return bredCrumb
      })
      returnBreadCrumbs = returnBreadCrumbs.filter(breadCrumb => breadCrumb.text !== '' && typeof breadCrumb.text !== 'undefined')
      return returnBreadCrumbs
    },
    lang () { return this.$i18n.locale }
  },

  methods: {
    IdGroupToGroupName (idGroup) {
      if (!Array.isArray(this.$store.state.groups.data)) { return '' }
      if (this.lang === 'ru') { if (typeof this.$store.getters['groups/group_by_id'](idGroup) !== 'undefined') { return this.$store.getters['groups/group_by_id'](idGroup)['name_rus'] } }
      if (this.lang === 'en-US') { if (typeof this.$store.getters['groups/group_by_id'](idGroup) !== 'undefined') { return this.$store.getters['groups/group_by_id'](idGroup)['name_en'] } }
      return ''
    },
    GetCurrentProductName () {
      if (this.lang === 'ru') { if (typeof this.$store.state.products.current.name_rus !== 'undefined') { return this.$store.state.products.current.name_rus } }
      if (this.lang === 'en-US') { if (typeof this.$store.state.products.current.name_en !== 'undefined') { return this.$store.state.products.current.name_en } }
      return ''
    },
    GetCurrentGroupId () {
      if (typeof this.$store.getters['groups/get_current'] !== 'undefined') {
        return this.$store.getters['groups/get_current'].id
      }
      return ' '
    },
    GetCurrentGroupName () {
      if (this.lang === 'ru') {
        if (typeof this.$store.getters['groups/get_current'] !== 'undefined') {
          return this.$store.getters['groups/get_current']['name_rus']
        }
        return 'У товара нет категории'
      }
      if (this.lang === 'en-US') {
        if (typeof this.$store.getters['groups/get_current'] !== 'undefined') {
          return this.$store.getters['groups/get_current']['name_en']
        }
        return 'Product has not category'
      }
      return 'У товара нет категории'
    }
  }
}
</script>
