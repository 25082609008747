<template>
  <div>
    <v-btn block class="mx-auto subMenuCard text-center"
           width="100%"
           outlined
           v-on:click="push"
    >
      <v-icon class="my-lg-6 my-md-5 my-sm-5">{{ svgPathComputed }}</v-icon>
      <h5 class="my-lg-6 my-md-5 my-sm-5">{{ submenuComputed }}</h5>
    </v-btn>
  </div>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: ['push',
    'submenu',
    'svgPath',
    'id_submenu',
    'id_svgPath',
    'change'
  ],
  data () {
    let changeData = true
    if (this.change !== undefined) {
      changeData = this.change
    }
    let id_list = []
    if (this.id_submenu) {
      id_list.push(this.id_submenu)
    }
    if (this.id_svgPath) {
      id_list.push(this.id_svgPath)
    }
    let _svgPath = this.valueDict(this.svgPath, this.id_svgPath)
    let _submenu = this.getT18nData(this.submenu, this.id_submenu)
    let promiseStore = this.$store
    let letThis = this
    new Promise(function (resolve, reject) {
      promiseStore.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = promiseStore.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_svgPath)) {
              _svgPath['value_rus'] = cont_item['value_rus']
              _svgPath['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_submenu)) {
              _submenu['value_rus'] = cont_item['value_rus']
              _submenu['value_eng'] = cont_item['value_eng']
            }
          }
        })
    })
    return {
      svgPathData: _svgPath,
      submenuData: _submenu,
      changeData: changeData
    }
  },
  computed: {
    svgPathComputed () {
      if (!this.changeData) {
        this.svgPathData['value_rus'] = this.svgPath
        this.svgPathData['value_eng'] = this.svgPath
      }
      if (this.$i18n.locale == 'ru') { return this.svgPathData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.svgPathData['value_eng'] }
    },
    submenuComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.submenuData['value_rus'] = this.submenu }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.submenuData['value_eng'] = this.submenu }
      if (this.$i18n.locale == 'ru') { return this.submenuData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.submenuData['value_eng'] }
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    changeLocaleRu () { this.$i18n.locale = 'ru' },
    changeLocaleEn () { this.$i18n.locale = 'en-US' },
    pushChangeContent () {
      let content_to_change = { 'svgPath': this.svgPathData,
        'submenu': this.submenuData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'Submenu',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  }
}
</script>
