<template>
  <swiper-slide class="no-indentation">
    <v-container fluid class="swiperSlide">
      <v-row>
        <v-col cols="12" lg="5" md="6" sm="12" offset-lg="6" offset-md="5" offset-sm="0" style="padding: 0">
          <div data-swiper-parallax="-400" class="swiperBackground"></div>
          <div class="mx-auto swiperInfo">
            <div data-swiper-parallax="-100" class="swiperHeader">{{ slideHeaderComputed }}</div>
              <div data-swiper-parallax="-200" class="swiperText">
                <p>{{slideTextComputed}}</p>
              </div>
              <div data-swiper-parallax="-300" class="swiperBtn">
                <v-btn
                        color="white"
                        depressed
                        @click="push()"
                >{{ slideBtnTextComputed }}</v-btn>
                <div v-if="slideTextComputed=='' && slideHeaderComputed==''">
                    <br/>
                    <br/>
                    <br/>
                </div>
                <div v-if="isAuth">
                  <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
                  <v-btn v-if="this.changeData && this.addedData" @click="deleteAddedContent">Удалить</v-btn>
                  <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать</v-btn>
                  <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать</v-btn>
                </div>
              </div>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </swiper-slide>
</template>
<script>
import { swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'MainSliderSlide',
  props: ['slideHeader',
    'slideText',
    'slideBtnText',
    'slideBtnLink',
    'id_slideHeader',
    'id_slideText',
    'id_slideBtnText',
    'id_slideBtnLink',
    'change',
    'added',
    'hidden'
  ],
  components: {
    swiperSlide
  },
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let hidden = false
    let id_list = [this.id_slideHeader,
      this.id_slideText,
      this.id_slideBtnText,
      this.id_slideBtnLink].filter(id => id != undefined)
    let _slideHeader = this.getT18nData(this.slideHeader, this.id_slideHeader)
    let _slideText = this.getT18nData(this.slideText, this.id_slideText)
    let _slideBtnText = this.getT18nData(this.slideBtnText, this.id_slideBtnText)
    let _slideBtnLink = this.valueDict(this.slideBtnLink, this.id_slideBtnLink)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let cont_item of content) {
          if (cont_item['id_content'] === parseInt(this.id_slideHeader)) {
            _slideHeader['value_rus'] = cont_item['value_rus']
            _slideHeader['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_slideText)) {
            _slideText['value_rus'] = cont_item['value_rus']
            _slideText['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_slideBtnText)) {
            _slideBtnText['value_rus'] = cont_item['value_rus']
            _slideBtnText['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_slideBtnLink)) {
            _slideBtnLink['value_rus'] = cont_item['value_rus']
            _slideBtnLink['value_eng'] = cont_item['value_eng']
          }
        }
        this.checkContent = true
      })
    return {
      checkContent: false,
      deleted: false,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      slideHeaderData: _slideHeader,
      slideTextData: _slideText,
      slideBtnTextData: _slideBtnText,
      slideBtnLinkData: _slideBtnLink
    }
  },
  methods: {
    push () {
      this.$router.push(this.slideBtnLinkComputed)
    },
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'slideHeader': this.slideHeaderData,
        'slideText': this.slideTextData,
        'slideBtnText': this.slideBtnTextData,
        'slideBtnLink': this.slideBtnLinkData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'MainSliderSlide',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_slideHeader, this.id_slideText, this.id_slideBtnText, this.id_slideBtnLink]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_slideHeader, this.id_slideText, this.id_slideBtnText, this.id_slideBtnLink]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_slideHeader, this.id_slideText, this.id_slideBtnText, this.id_slideBtnLink]
      })
      this.hiddenData = true
    }
  },
  computed: {
    slideTextComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.slideTextData['value_rus'] = this.slideText }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.slideTextData['value_eng'] = this.slideText }
      if (this.$i18n.locale == 'ru') { return this.slideTextData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.slideTextData['value_eng'] }
    },
    slideHeaderComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.slideHeaderData['value_rus'] = this.slideHeader }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.slideHeaderData['value_eng'] = this.slideHeader }
      if (this.$i18n.locale == 'ru') { return this.slideHeaderData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.slideHeaderData['value_eng'] }
    },
    slideBtnTextComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.slideBtnTextData['value_rus'] = this.slideBtnText }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.slideBtnTextData['value_eng'] = this.slideBtnText }
      if (this.$i18n.locale == 'ru') { return this.slideBtnTextData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.slideBtnTextData['value_eng'] }
    },
    slideBtnLinkComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.slideBtnLinkData['value_rus'] = this.slideBtnLink }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.slideBtnLinkData['value_eng'] = this.slideBtnLink }
      if (this.$i18n.locale == 'ru') { return this.slideBtnLinkData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.slideBtnLinkData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  },
  updated () {
    this.$emit('slideUpdated')
  }
}
</script>

<style lang="scss" scoped>
  .swiper-slide {
    font-size: 18px;
    color:#000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
    background-color: transparent!important;
    justify-content: space-around!important;
  }
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image:  url(../../../public/img/slider/background1.png);
    opacity: 0.3;
  }
  .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
  }
  .swiper-slide .subtitle {
    font-size: 21px;
  }
  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
  }
</style>
