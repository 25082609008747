<template>
  <div v-if="show">
    <h1 class="contentSubTitle">{{ titleComputed }}</h1>
    <div v-if="isAuth">
      <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить заголовок</v-btn>
      <v-btn v-if="this.changeData && this.addedData" @click="deleteAddedContent">Удалить заголовок</v-btn>
      <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать заголовок</v-btn>
      <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать заголовок</v-btn>
      <v-btn v-if="this.changeData" @click="add_content">Добавить документ</v-btn>
    </div>
    <content-documents v-for="(content, index) in contentToShow" :key="index"
      :content="$t(content['content'])"
      :href="$t(content['href'])"
      :id_content="content['id_content']"
      :id_href="content['id_href']"
      :hidden="content['hidden']"
      :added="content['added']"
    />
  </div>
</template>
<script>
import ContentDocuments from '@/components/ui/ContentDocuments.vue'

export default {
  name: 'BlockDocuments',
  props: ['title',
    'id_title',
    'added',
    'type',
    'change',
    'hidden'
  ],
  components: { ContentDocuments },
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let addedContent = []
    let id_list = []
    if (this.id_title) {
      id_list.push(this.id_title)
    }
    let defaultContent =
      [{
        type: 'founding_documents',
        content: 'message.aboutUsDocument1',
        href: 'message.aboutUsDocument1HREF',
        id_content: 80,
        id_href: 81
      },
      {
        type: 'inner_documents',
        content: 'message.aboutUsDocument2',
        href: 'message.aboutUsDocument2HREF',
        id_content: 82,
        id_href: 83
      },
      {
        type: 'inner_documents',
        content: 'message.aboutUsDocument3',
        href: 'message.aboutUsDocument3HREF',
        id_content: 84,
        id_href: 85
      },
      {
        type: 'law_document',
        content: 'message.aboutUsDocument5',
        href: 'message.aboutUsDocument5HREF',
        id_content: 86,
        id_href: 87
      },
      {
        type: 'law_document',
        content: 'message.aboutUsDocument7',
        href: 'message.aboutUsDocument7HREF',
        id_content: 88,
        id_href: 89
      },
      {
        type: 'law_document',
        content: 'message.aboutUsDocument8',
        href: 'message.aboutUsDocument8HREF',
        id_content: 90,
        id_href: 91
      },
      {
        type: 'law_document',
        content: 'message.aboutUsDocument9',
        href: 'message.aboutUsDocument9HREF',
        id_content: 92,
        id_href: 93
      },
      {
        type: 'law_document',
        content: 'message.aboutUsDocument10',
        href: 'message.aboutUsDocument10HREF',
        id_content: 94,
        id_href: 95
      }]
    let typeData = 'BlockDocuments_' + this.type
    let _title = this.getT18nData(this.title, this.id_title)
    let letThis = this
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = letThis.$store.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_title)) {
              _title['value_rus'] = cont_item['value_rus']
              _title['value_eng'] = cont_item['value_eng']
            }
          }
          letThis.checkContent = true
        })
    })
    new Promise(function (resolve, reject) {
      let id_list = defaultContent.map(cont => [cont['id_content'], cont['id_href']])
      letThis.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': id_list })
        .then(() => {
          let hidden_content = letThis.$store.getters['contentChanges/hidden_content']
          for (let ind in hidden_content) {
            letThis.defaultContent[ind]['hidden'] = hidden_content[ind]
          }
          letThis.loadedDefaultContent = true
        })
    })
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
        'type': typeData
      }).then(() => {
        addedContent = letThis.$store.getters['contentChanges/added_content_by_type'](typeData)
        letThis.addedContent = addedContent
        letThis.loadedAddedContent = true
      })
    })
    return {
      loadedAddedContent: false,
      loadedDefaultContent: false,
      defaultContent: defaultContent,
      addedContent: addedContent,
      hiddenData: hiddenData,
      typeData: typeData,
      addedData: addedData,
      changeData: changeData,
      titleData: _title,
      deleted: false
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'title': this.titleData }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'BlockDocuments',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_title]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_title]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_title]
      })
      this.hiddenData = true
    },
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': this.typeData,
        'field_list': ['content', 'href']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  },
  computed: {
    titleComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.titleData['value_rus'] = this.title }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.titleData['value_eng'] = this.title }
      if (this.$i18n.locale == 'ru') { return this.titleData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.titleData['value_eng'] }
    },
    contentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent.filter(cont => 'BlockDocuments_' + cont['type'] === this.typeData)
      } else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false && 'BlockDocuments_' + cont['type'] === this.typeData)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['id_href'] = this.get_field_id(content, 'href')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    show () {
      return this.loadedDefaultContent && this.loadedAddedContent && !this.deleted
    }
  }
}
</script>
