<template>
    <div v-if="checkContent">
        <div class="contentNumeric">
            {{ numComputed }} {{ unitComputed }} {{ symbolComputed }}
        </div>
        <div class="contentText ml-0 mt-2">
            {{ contentComputed}}
        </div>
        <div v-if="isAuth">
            <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
            <v-btn v-if="this.changeData && this.addedData" @click="deleteAddedContent">Удалить</v-btn>
            <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать</v-btn>
            <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать</v-btn>
        </div>
    </div>
</template>
<script>
export default {
  props: ['num',
    'unit',
    'symbol',
    'content',
    'id_num',
    'id_unit',
    'id_symbol',
    'id_content',
    'hidden',
    'change',
    'added'
  ],
  name: 'ContentNumeric',
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let id_list = []
    if (this.id_num) {
      id_list.push(this.id_num)
    }
    if (this.id_unit) {
      id_list.push(this.id_unit)
    }
    if (this.id_symbol) {
      id_list.push(this.id_symbol)
    }
    if (this.id_content) {
      id_list.push(this.id_content)
    }
    let _num = this.getT18nData(this.num, this.id_num)
    let _unit = this.getT18nData(this.unit, this.id_unit)
    let _symbol = this.valueDict(this.symbol, this.id_symbol)
    let _content = this.getT18nData(this.content, this.id_content)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let cont_item of content) {
          if (cont_item['id_content'] === parseInt(this.id_num)) {
            _num['value_rus'] = cont_item['value_rus']
            _num['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_unit)) {
            _unit['value_rus'] = cont_item['value_rus']
            _unit['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_symbol)) {
            _symbol['value_rus'] = cont_item['value_rus']
            _symbol['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_content)) {
            _content['value_rus'] = cont_item['value_rus']
            _content['value_eng'] = cont_item['value_eng']
          }
        }
        this.checkContent = true
      })
    return {
      checkContent: false,
      deleted: false,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      numData: _num,
      unitData: _unit,
      symbolData: _symbol,
      contentData: _content
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      if (i18n_data == '') {
        return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
      }
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'num': this.numData,
        'unit': this.unitData,
        'symbol': this.symbolData,
        'content': this.contentData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'ContentNumeric',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_num, this.id_unit, this.id_symbol, this.id_content]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_num, this.id_unit, this.id_symbol, this.id_content]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_num, this.id_unit, this.id_symbol, this.id_content]
      })
      this.hiddenData = true
    }
  },
  computed: {
    symbolComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.symbolData['value_rus'] = this.symbol }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.symbolData['value_eng'] = this.symbol }
      if (this.$i18n.locale == 'ru') { return this.symbolData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.symbolData['value_eng'] }
    },
    unitComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.unitData['value_rus'] = this.unit }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.unitData['value_eng'] = this.unit }
      if (this.$i18n.locale == 'ru') { return this.unitData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.unitData['value_eng'] }
    },
    numComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.numData['value_rus'] = this.num }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.numData['value_eng'] = this.num }
      if (this.$i18n.locale == 'ru') { return this.numData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.numData['value_eng'] }
    },
    contentComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.contentData['value_rus'] = this.content }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.contentData['value_eng'] = this.content }
      if (this.$i18n.locale == 'ru') { return this.contentData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.contentData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    show () {
      if ((!this.checkContent) || this.deleted) {
        return false
      }
      return true
    }
  }
}
</script>
