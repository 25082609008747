<template>
  <v-container fluid class="product-posts ml-0">
    <v-row class="no-indentation">
      <v-col lg="8" md="8" sm="12" class="no-indentation">
        <h3 v-if="doctor_posts.length === 0 && patient_posts.length === 0">
          {{$t('message.productPostsHead1')}}
        </h3>
        <h3 v-if="doctor_posts.length > 0"
        >
          {{$t('message.productPostsHead1')}}
        </h3>
        <div v-for="post in doctor_posts" :key="post.id">
          <product-post v-if="post.display_on"
                        :name='post.name'
                        :text='post.text'
                        :post_man="post.post_man"
                        :pharm_man='post.pharm_man'
                        :created_view='post.created_view'
                        :email='post.email'
                        :photo='post.photo'
                        :phone='post.phone'
          />
        </div>
        <h3 v-if="patient_posts.length > 0"
            class="mt-10"
        >
          {{$t('message.productPostsHead2')}}
        </h3>
        <div v-for="post in patient_posts" :key="post.id">
          <product-post v-if="post.display_on"
                        class="product-posts__item"
                        :name='post.name'
                        :text='post.text'
                        :post_man="post.post_man"
                        :pharm_man='post.pharm_man'
                        :email='post.email'
                        :photo='post.photo'
                        :created_view='post.created_view'
                        :phone='post.phone'
          />
        </div>
      </v-col>
      <v-col lg="4" md="4" sm="12" class="text-center">
        <v-btn @click="change_dialog()"
               color="cyan darken-2"
               depressed
               x-large
               dark
        >
          {{$t('message.productPostsBtnLabel')}}
        </v-btn>
        <v-dialog v-model='dialog'
                  max-width="600"
        >
          <v-card class="post-form">
            <v-card-title>Оставить отзыв</v-card-title>
            <v-card-text>
              <post-form></post-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogStatus"
                hide-overlay
                persistent
                width="300"
                v-on:click:outside="closeDialogStatus"
      >
        <v-card color="primary"
                dark
        >
          <v-card-text>
            {{post_msg}}
          </v-card-text>
        </v-card>
      </v-dialog>
        <p class="item-post_comment mt-4">
          <i>Напишите свой отзыв. Он будет опубликован на сайте после проверки.</i>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductPost from '@/components/ui/ProductPost.vue'
import PostForm from '@/components/ui/PostForm.vue'

export default {
  name: 'ProductPosts',
  components: { ProductPost, PostForm },
  methods: {
    change_dialog () { this.$store.dispatch('post/SHOW_FORM', 'post') },
    closeDialogStatus: function () {
      this.$store.dispatch('post/HIDE_FORM', 'postLoad')
    }
  },
  computed: {
    doctor_posts () {
      return this.$store.state.products.current.post.filter(post => post.pharm_man === true)
    },
    patient_posts () {
      return this.$store.state.products.current.post.filter(post => post.pharm_man === false)
    },
    dialog: {
      get () {
        return this.$store.state['post']['post']
      },
      set () { this.$store.dispatch('post/HIDE_FORM', 'post') }
    },
    post_msg () {
      return this.$store.state['post']['postLoadState']
    },
    dialogStatus: {
      get () {
        return this.$store.state['post']['postLoad']
      },
      set (value) {
        this.$store.dispatch('post/HIDE_FORM', 'postLoad')
      }
    }
  }
}
</script>

<style scoped>

</style>
