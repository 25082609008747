<template>
  <div :class="this.class_div">
    {{ textComputed }}
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon style="width:20px; height:20px; margin-top: -3px"  v-on="on">{{ svgPathComputed }}</v-icon>
      </template>
      <span>{{ tolltipTextComputed }}</span>
    </v-tooltip>
    <div v-if="isAuth">
      <v-btn v-if="changeData" @click="pushChangeContent">Изменить</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TooltipContent',
  props: ['text',
    'svgPath',
    'tolltipText',
    'id_text',
    'id_svgPath',
    'id_tolltipText',
    'class_div',
    'change'
  ],
  data () {
    let changeData = this.change !== undefined ? this.change : true
    let id_list = [this.id_text,
      this.id_svgPath,
      this.id_tolltipText].filter(id => id !== undefined)
    let _text = this.getT18nData(this.text, this.id_text)
    let _svgPath = this.valueDict(this.svgPath, this.id_svgPath)
    let _tolltipText = this.getT18nData(this.tolltipText, this.id_tolltipText)
    let letThis = this
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = letThis.$store.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_text)) {
              _text['value_rus'] = cont_item['value_rus']
              _text['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_tolltipText)) {
              _tolltipText['value_rus'] = cont_item['value_rus']
              _tolltipText['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_svgPath)) {
              _svgPath['value_rus'] = cont_item['value_rus']
              _svgPath['value_eng'] = cont_item['value_eng']
            }
          }
        })
    })
    return {
      textData: _text,
      tolltipTextData: _tolltipText,
      svgPathData: _svgPath,
      changeData: changeData
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length!=0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length!=0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': this.id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'text': this.textData,
        'svgPath': this.svgPathData,
        'tolltipText': this.tolltipTextData
      }
      console.log('content_to_change')
      console.log(content_to_change)
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'TooltipContent',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  }, computed: {
    textComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textData['value_rus'] = this.text }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textData['value_eng'] = this.text }
      if (this.$i18n.locale == 'ru') { return this.textData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textData['value_eng'] }
    },
    tolltipTextComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.tolltipTextData['value_rus'] = this.tolltipText }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.tolltipTextData['value_eng'] = this.tolltipText }
      if (this.$i18n.locale == 'ru') { return this.tolltipTextData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.tolltipTextData['value_eng'] }
    },
    svgPathComputed () {
      if (!this.changeData) {
        this.svgPathData['value_rus'] = this.svgPath
        this.svgPathData['value_eng'] = this.svgPath
      }
      if (this.$i18n.locale == 'ru') { return this.svgPathData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.svgPathData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>

<style scoped>

</style>
