// import Vue from 'vue'

// import VueRouter, { Route, RouteConfig } from 'vue-router'

const Error404 = () => import(/* webpackChunkName: "errors" */ '@/views/errors/404')
const Main = () => import(/* webpackChunkName: "about" */ '@/views/Main')
const Products = () => import(/* webpackChunkName: "about" */ '@/views/Products')
const ProductsPage = () => import(/* webpackChunkName: "about" */ '@/views/ProductsPage')
const AboutCompany = () => import(/* webpackChunkName: "about" */ '@/views/AboutCompany')
const Partnership = () => import(/* webpackChunkName: "about" */ '@/views/Partnership')
const Career = () => import(/* webpackChunkName: "about" */ '@/views/Career')
const Contacts = () => import(/* webpackChunkName: "about" */ '@/views/Contacts')
const Management = () => import(/* webpackChunkName: "about" */ '@/views/Management')
const Documents = () => import(/* webpackChunkName: "about" */ '@/views/Documents')
const TypeOfActivities = () => import(/* webpackChunkName: "about" */ '@/views/TypeOfActivities')
const AnticorruptionPolicy = () => import(/* webpackChunkName: "about" */ '@/views/AnticorruptionPolicy')
const AllNews = () => import(/* webpackChunkName: "about" */ '@/views/AllNews')
const NewsPage = () => import(/* webpackChunkName: "about" */ '@/views/NewsPage')
const Logistics = () => import(/* webpackChunkName: "about" */ '@/views/Logistics')
const ForSuppliers = () => import(/* webpackChunkName: "about" */ '@/views/ForSuppliers')
const OurPartners = () => import(/* webpackChunkName: "about" */ '@/views/OurPartners')
const OurClients = () => import(/* webpackChunkName: "about" */ '@/views/OurClients')
const ChangeContent = () => import(/* webpackChunkName: "change_content" */ '@/views/ChangeContent')
const CheckLogin = () => import(/* webpackChunkName: "change_content" */ '@/views/CheckLogin')

// Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          }
        ]
      }
    }
  },
  {
    path: '/products',
    name: 'products',
    component: Products,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu5',
            to: { name: 'products' }
          }
        ]
      }
    }
  },
  {
    path: '/products/:id_group',
    name: 'products_with_group',
    component: Products,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu5',
            to: { name: 'products' }
          },
          {
            text: ':id_group',
            functionForText: 'IdGroupToGroupName'
          }
        ]
      },
      sitemap: {
        slugs: [{
          id_group: 'id_group'
        }]
      }
    }
  },
  {
    path: '/products_page/:id_product',
    name: 'products_page',
    component: ProductsPage,
    props: true,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu5',
            to: { name: 'products' }
          },
          {
            text: '',
            to: { name: 'products_with_group', params: { 'id_group': null } },
            functions_for_params: { 'id_group': 'GetCurrentGroupId' },
            functionForText: 'GetCurrentGroupName'

          },
          {
            text: ':id_product',
            functionForText: 'GetCurrentProductName'
          }
        ]
      },
      sitemap: {
        slugs: [{
          id_product: 'id_product'
        }]
      }
    }
  },
  {
    path: '/about_company',
    name: 'about_company',
    component: AboutCompany,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1'
          }
        ]
      }
    }
  },
  {
    path: '/management',
    name: 'management',
    component: Management,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1',
            to: { name: 'about_company' }
          },
          {
            text: 'message.aboutUsSubmenu1'
          }
        ]
      }
    }
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1',
            to: { name: 'about_company' }
          },
          {
            text: 'message.aboutUsSubmenu2'
          }
        ]
      }
    }
  },
  {
    path: '/types_of_activities',
    name: 'types_of_activities',
    component: TypeOfActivities,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1',
            to: { name: 'about_company' }
          },
          {
            text: 'message.aboutUsSubmenu3'
          }
        ]
      }
    }
  },
  {
    path: '/anticorruption_policy',
    name: 'anticorruption_policy',
    component: AnticorruptionPolicy,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1',
            to: { name: 'about_company' }
          },
          {
            text: 'message.aboutUsSubmenu4'
          }
        ]
      }
    }
  },
  {
    path: '/news',
    name: 'news',
    component: AllNews,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1',
            to: { name: 'about_company' }
          },
          {
            text: 'message.aboutUsSubmenu5'
          }
        ]
      }
    }
  },
  {
    path: '/news_page/:page_number/:news_number',
    name: 'news_page_with_page_and_number',
    component: NewsPage,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1',
            to: { name: 'about_company' }
          },
          {
            text: 'message.aboutUsSubmenu5',
            to: { name: 'news' }
          }
        ]
      },
      sitemap: {
        slugs: [{
          page_number: 'page_number',
          news_number: 'news_number'
        }]
      }
    }
  },
  {
    path: '/news_page/:page_number',
    name: 'news_page_with_page',
    component: NewsPage,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1',
            to: { name: 'about_company' }
          },
          {
            text: 'message.aboutUsSubmenu5',
            to: { name: 'news' }
          }
        ]
      },
      sitemap: {
        slugs: [{
          page_number: 'page_number'
        }]
      }
    }
  },
  {
    path: '/news_page',
    name: 'news_page',
    component: NewsPage,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu1',
            to: { name: 'about_company' }
          },
          {
            text: 'message.aboutUsSubmenu5',
            to: { name: 'news' }
          }
        ]
      }
    }
  },
  {
    path: '/partnership',
    name: 'partnership',
    component: Partnership,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu2'
          }
        ]
      }
    }
  },
  {
    path: '/logistics',
    name: 'logistics',
    component: Logistics,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu2',
            to: { name: 'partnership' }
          },
          {
            text: 'message.partnershipSubmenu1'
          }
        ]
      }
    }
  },
  {
    path: '/for_suppliers',
    name: 'for_suppliers',
    component: ForSuppliers,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu2',
            to: { name: 'partnership' }
          },
          {
            text: 'message.partnershipSubmenu2'
          }
        ]
      }
    }
  },
  {
    path: '/our_partners',
    name: 'our_partners',
    component: OurPartners,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu2',
            to: { name: 'partnership' }
          },
          {
            text: 'message.partnershipSubmenu3'
          }
        ]
      }
    }
  },
  {
    path: '/our_clients',
    name: 'our_clients',
    component: OurClients,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu2',
            to: { name: 'partnership' }
          },
          {
            text: 'message.partnershipSubmenu4'
          }
        ]
      }
    }
  },
  {
    path: '/career',
    name: 'career',
    component: Career,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu3'
          }
        ]
      }
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          },
          {
            text: 'message.submenu4'
          }
        ]
      }
    }
  },
  {
    path: '/change_content',
    name: 'change_content',
    component: ChangeContent,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          }
        ]
      }
    }
  },
  {
    path: '/check_login',
    name: 'check_login',
    component: CheckLogin,
    meta: {
      breadCrumb () {
        return [
          {
            text: 'message.submenu0',
            to: { name: 'main' }
          }
        ]
      }
    }
  },
  { path: '*', name: '404', component: Error404 }
]

export default routes
