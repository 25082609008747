<template>
  <v-snackbar height="88"
              color="blue-grey"
              dark
              centered
              width="100%"
              v-model="loading"
  >
    <v-icon large class="ml-4 mr-2">
      mdi-timer-sand
    </v-icon>
      {{ $t('message.wait') }}
    <v-progress-linear indeterminate
                        color="white"
                        class="mt-4 mb-0"
    />
  </v-snackbar>
</template>

<script>
export default {
  name: 'AlertLoading',
  props: [ 'loading' ]
}
</script>

<style scoped>

</style>
