<template>
  <v-form v-model="valid"
          ref="form"
          lazy-validation
          class="pa-0"
  >
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field v-model="name"
                        ref="input_name"
                        :counter="50"
                        :rules="textFieldRules"
                        label="Как вас зовут?"
                        required
                        outlined
          />
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-text-field v-model="email"
                        ref="input_email"
                        :counter="50"
                        :rules="emailRules"
                        label="Ваш e-mail"
                        required
                        outlined
          />
        </v-col>
        <v-col cols="12">
          <VuePhoneNumberInput v-model="phone"
                               :translations="myTranslation"
                               v-on:update="updatePhone"
                               required
                               class="mt-lg-2 mt-md-0"
            />
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea v-model="text"
                      ref="input_text"
                        :counter="200"
                        :rules="textAreaRules"
                        label="Текст отзыва"
                        class="inputStyle"
                        required
                        outlined
          />
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="4" class="checkbox-doctor">
          <v-checkbox v-model="pharm_man"
                      v-on:click="validate_post_man()"
                      v:on:change="validate()"
                      label="Я врач">
          </v-checkbox>
        </v-col>
        <v-col cols="8" class="pb-0 pl-0 doctors-profession">
          <v-text-field v-model="post_man"
                        ref="input_post_man"
                        :counter="50"
                        :rules="postManRules"
                        label="Ваша профессия и стаж"
                        :disabled="disable_field"
                        required
                        outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-file-input v-model="photo"
                        ref="input_photo"
                        :rules="photoRules"
                        label="Ваше фото"
                        show-size
                        outlined
          />
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn @click="send"
                 color="cyan darken-2"
                 depressed
                 x-large
                 dark
          >
            Отправить отзыв
          </v-btn>
        </v-col>
        <v-snackbar v-model="dialog"
                    :timeout="timeout"
                    height="88"
                    color="blue accent-4"
                    absolute
                    centered
        >
        </v-snackbar>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>

import VuePhoneNumberInput from 'vue-phone-number-input'

export default {
  name: 'PostForm',
  components: { VuePhoneNumberInput },
  data () {
    return {
      timeout: 3000,
      disable_field: true,
      valid: true,
      name: '',
      pharm_man: false,
      post_man: '',
      email: '',
      text: '',
      photo: null,
      phone: '',
      good: this.$store.state.products.current.id,
      postManRules: [
        v => !!v || 'Обязательное поле для врачей',
        v => (v && v.length <= 50) || 'Поле должно содержать не более 50 символов'
      ],
      textFieldRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 50) || 'Поле должно содержать не более 50 символов'
      ],
      textAreaRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length <= 200) || 'Поле должно содержать не более 200 символов'
      ],
      emailRules: [
        v => !!v || 'Обязательное поле',
        v => /.+@.+\.\w{2,4}/.test(v) || 'Электронная почта должна быть действительной'
      ],
      photoRules: [
        v => !!v || 'Обязательно прикрепите фото'
      ]
    }
  },
  methods: {
    send: async function () {
      await this.$refs.form.validate()
      if (!this.pharm_man) {
        this.$refs.input_post_man.resetValidation()
      }
      if (this.$refs.input_name.valid &
          this.$refs.input_email.valid &
          this.$refs.input_photo.valid &
          this.$refs.input_text.valid &
          (this.pharm_man & this.$refs.input_post_man.valid || !this.pharm_man)) {
        this.$store.dispatch('post/SEND_POST', {
          'name': this.name,
          'pharm_man': this.pharm_man,
          'post_man': this.post_man,
          'email': this.email,
          'text': this.text,
          'photo': this.photo,
          'phone': this.phone,
          'good': this.good })
        this.$store.dispatch('post/HIDE_FORM', 'post')
      }
    },
    updatePhone: function (value) {
      console.log(value)
    },
    closeDialog: function () {
      this.$store.dispatch('post/HIDE_FORM', 'postLoad')
    },
    validate_post_man: function () {
      if (this.pharm_man) {
        this.disable_field = false
        this.$refs.input_post_man.validate()
      } else {
        this.$refs.input_post_man.resetValidation()
        this.disable_field = true
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.$store.state['post']['postLoad']
      },
      set (value) {
        if (this.$store.getters['mail/status'] === 'ok') {
          this.$refs.form.reset()
        }
        this.$store.dispatch('post/HIDE_FORM', 'postLoad')
      }
    },
    myTranslation () {
      return {
        countrySelectorLabel: this.$t('message.partnershipForSuppliersForm6'),
        countrySelectorError: this.$t('message.partnershipForSuppliersForm6Error'),
        phoneNumberLabel: this.$t('message.partnershipForSuppliersForm7'),
        example: this.$t('message.partnershipForSuppliersForm7Example')
      }
    }
  }
}
</script>

<style scoped>

</style>
