<template>
  <div>
    <v-icon class="my-lg-2 my-md-2 my-sm-2 mr-lg-2 mr-md-2 mr-sm-2">{{ svgPathComputed }}</v-icon>
    {{ textComputed }}
    <div v-if="isAuth">
      <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
      <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать</v-btn>
      <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IconText',
  props: [ 'text',
    'svgPath',
    'id_text',
    'id_svgPath',
    'change',
    'hidden',
    'added'
  ],
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let id_list = []
    if (this.id_text) {
      id_list.push(this.id_text)
    }
    if (this.id_svgPath) {
      id_list.push(this.id_svgPath)
    }
    let _text = this.getT18nData(this.text, this.id_text)
    let _svgPath = this.valueDict(this.svgPath, this.id_svgPath)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let cont_item of content) {
          if (cont_item['id_content'] === parseInt(this.id_text)) {
            _text['value_rus'] = cont_item['value_rus']
            _text['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_svgPath)) {
            _svgPath['value_rus'] = cont_item['value_rus']
            _svgPath['value_eng'] = cont_item['value_eng']
          }
        }
        this.checkContent = true
      })
    return {
      checkContent: false,
      deleted: false,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      textData: _text,
      svgPathData: _svgPath
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      if (i18n_data == '') {
        return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
      }
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'text': this.textData,
        'svgPath': this.svgPathData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'IconText',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_text, this.id_svgPath]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_text, this.id_svgPath]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_text, this.id_svgPath]
      })
      this.hiddenData = true
    }
  },
  computed: {
    textComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textData['value_rus'] = this.text }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textData['value_eng'] = this.text }
      if (this.$i18n.locale == 'ru') { return this.textData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textData['value_eng'] }
    },
    svgPathComputed () {
      if (!this.changeData) {
        this.svgPathData['value_rus'] = this.svgPath
        this.svgPathData['value_eng'] = this.svgPath
      }
      if (this.$i18n.locale == 'ru') { return this.svgPathData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.svgPathData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>

<style scoped>

</style>
