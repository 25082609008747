<template>
  <v-container fluid class="no-indentation">
    <v-row class="no-indentation">
      <v-col class="no-indentation" cols="12">
        <v-card class="licenseCard">
          <v-card-text>
              <!-- swiper1 -->
            <swiper
                    v-if="content_loaded"
                    :options="swiperOptionTop"
                    class="gallery-top"
                    ref="swiperTop">
                <license-slide v-for="(content, index) in contentToShow" :key="index"
                  :path-img="content['pathImg']"
                  :id_path-img="content['id_pathImg']"
                  :added="content['added']"
                  :hidden="content['hidden']"
                />
              <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
              <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            </swiper>
              <v-btn v-if="isAuth" @click="add_content">Добавить слайд</v-btn>
              <!-- swiper2 Thumbs -->
              <swiper
                      v-if="content_loaded"
                      :options="swiperOptionThumbs"
                      class="gallery-thumbs"
                      ref="swiperThumbs">
                <license-slide v-for="(content, index) in contentToShow" :key="index"
                  :path-img="content['pathImg']"
                  :id_path-img="content['id_pathImg']"
                  :hidden="content['hidden']"
                  :added="content['added']"
                  :thumbs="true"
                />
              </swiper>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import LicenseSlide from "@/components/ui/LicenseSlide.vue";
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {LicenseSlide, swiper, swiperSlide },
  data () {
    let addedContent = []
    let defaultContent =
      [{
        pathImg: '/img/licenses/lic_001.jpg',
        id_pathImg: 125
      },
      {
        pathImg: '/img/licenses/lic_002.jpg',
        id_pathImg: 126
      },
      {
        pathImg: '/img/licenses/lic_003.jpg',
        id_pathImg: 127
      },
      {
        pathImg: '/img/licenses/lic_004.jpg',
        id_pathImg: 128
      },
      {
        pathImg: '/img/licenses/lic_005.jpg',
        id_pathImg: 129
      },
      {
        pathImg: '/img/licenses/lic_006.jpg',
        id_pathImg: 130
      },
      {
        pathImg: '/img/licenses/lic_007.jpeg',
        id_pathImg: 131
      },
      {
        pathImg: '/img/licenses/lic_008.jpeg',
        id_pathImg: 132
      },
      {
        pathImg: '/img/licenses/lic_009.jpeg',
        id_pathImg: 133
      },
      {
        pathImg: '/img/licenses/lic_010.jpeg',
        id_pathImg: 134
      }]
    let letThis = this
    new Promise(function (resolve, reject) {
      let id_list = defaultContent.map(cont => [cont['id_pathImg']])
      letThis.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': id_list })
        .then(() => {
          let hidden_content = letThis.$store.getters['contentChanges/hidden_content']
          for (let ind in hidden_content) {
            letThis.defaultContent[ind]['hidden'] = hidden_content[ind]
          }
          letThis.loadedDefaultContent = true
        })
    })
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
        'type': 'LicenseSlide'
      }).then(() => {
        addedContent = letThis.$store.getters['contentChanges/added_content_by_type']('LicenseSlide')
        letThis.addedContent = addedContent
        letThis.loadedAddedContent = true
      })
    })
    return {
      loadedAddedContent: false,
      loadedDefaultContent: false,
      defaultContent: defaultContent,
      addedContent: addedContent,
      swiperOptionTop: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        grabCursor: true,
        progressbarOpposite: true
      },
      swiperOptionThumbs: {
        spaceBetween: 8,
        slidesPerView: 3,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        grabCursor: true,
        progressbarOpposite: true,
        direction: 'horizontal'
      }
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    contentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent
      } else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_pathImg'] = this.get_field_id(content, 'pathImg')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    content_loaded () {
      if (this.loadedDefaultContent && this.loadedAddedContent) {
        let letThis = this
        letThis.$nextTick(() => {
          const swiperTop = letThis.$refs.swiperTop.swiper
          const swiperThumbs = letThis.$refs.swiperThumbs.swiper
          swiperTop.controller.control = swiperThumbs
          swiperThumbs.controller.control = swiperTop
        })
        return true
      }
      return false
    }
  },
  methods: {
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'LicenseSlide',
        'field_list': ['pathImg']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  }
}
</script>
