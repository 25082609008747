<template>
  <div :class="divClass" v-if="!deleted">
    <h1 v-if="titleComputed" :class="titleClass">{{ titleComputed }}</h1>
    <h1 v-if="subTitleComputed" :class="subTitleClass">{{ subTitleComputed }}</h1>
    <div v-if="contentComputed" :class="textClass" v-html="contentComputed"></div>
    <div v-if="isAuth">
      <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
      <v-btn v-if="this.changeData && this.addedData" @click="deleteAddedContent">Удалить</v-btn>
      <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать</v-btn>
      <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title',
    'subTitle',
    'content',
    'id_title',
    'id_subTitle',
    'id_content',
    'hidden',
    'change',
    'textClass',
    'titleClass',
    'subTitleClass',
    'divClass',
    'added'
  ],
  name: 'ContentText',
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let id_list = []
    if (this.id_title) {
      id_list.push(this.id_title)
    }
    if (this.id_subTitle) {
      id_list.push(this.id_subTitle)
    }
    if (this.id_content) {
      id_list.push(this.id_content)
    }
    let _title = this.getT18nData(this.title, this.id_title)
    let _subTitle = this.getT18nData(this.subTitle, this.id_subTitle)
    let _content = this.getT18nData(this.content, this.id_content)
    let letThis = this
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
        .then(() => {
          let content = letThis.$store.getters['contentChanges/content']
          for (let cont_item of content) {
            if (cont_item['id_content'] === parseInt(letThis.id_title)) {
              _title['value_rus'] = cont_item['value_rus']
              _title['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_subTitle)) {
              _subTitle['value_rus'] = cont_item['value_rus']
              _subTitle['value_eng'] = cont_item['value_eng']
            }
            if (cont_item['id_content'] === parseInt(letThis.id_content)) {
              _content['value_rus'] = cont_item['value_rus']
              _content['value_eng'] = cont_item['value_eng']
            }
          }
          letThis.checkContent = true
        })
    })
    return {
      checkContent: false,
      deleted: false,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      titleData: _title,
      subTitleData: _subTitle,
      contentData: _content
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      if (!i18n_data) {
        return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
      }
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      if (i18n_data['value_rus'] != undefined && i18n_data['value_eng'] != undefined){
        return { 'value_rus': i18n_data['value_rus'], 'value_eng': i18n_data['value_eng'], 'id_content': id_content }
      }

      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'title': this.titleData,
        'subTitle': this.subTitleData,
        'content': this.contentData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'ContentText',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_title, this.id_subTitle, this.id_content]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_title, this.id_subTitle, this.id_content]
      })
      this.hiddenData = false
    },
    hideContent () {
      console.log('[this.id_title, this.id_subTitle, this.id_content]')
      console.log([this.id_title, this.id_subTitle, this.id_content])
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_title, this.id_subTitle, this.id_content]
      })
      this.hiddenData = true
    }
  },
  computed: {
    titleComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.titleData['value_rus'] = this.title }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.titleData['value_eng'] = this.title }
      if (this.$i18n.locale == 'ru') { return this.titleData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.titleData['value_eng'] }
    },
    subTitleComputed() {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.subTitleData['value_rus'] = this.subTitle }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.subTitleData['value_eng'] = this.subTitle }
      if (this.$i18n.locale == 'ru') { return this.subTitleData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.subTitleData['value_eng'] }
    },
    contentComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.contentData['value_rus'] = this.content }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.contentData['value_eng'] = this.content }
      if (this.$i18n.locale == 'ru') { return this.contentData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.contentData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>
