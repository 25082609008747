<template>
  <div :class="divClass">
    <span>{{ textBeforeComputed }}</span>
    <i class="material-icons footerInfo">{{svgPath}}</i>
    <span>{{ textAfterComputed }}</span>
     <div v-if="isAuth">
      <v-btn v-if="this.changeData" @click="pushChangeContent">Изменить</v-btn>
      <v-btn v-if="this.changeData && this.addedData" @click="deleteAddedContent">Удалить</v-btn>
      <v-btn v-if="this.changeData && this.hiddenData && !this.addedData" @click="showContent">Показать</v-btn>
      <v-btn v-if="this.changeData && !this.hiddenData && !this.addedData" @click="hideContent">Спрятать</v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: "FooterIconText",
  props: [ 'textBefore',
    'svgPath',
    'textAfter',
    'id_textBefore',
    'id_textAfter',
    'id_svgPath',
    'divClass',
    'change',
    'hidden',
    'added',
  ],
  data () {
    let addedData = this.added !== undefined ? this.added : false
    let changeData = this.change !== undefined ? this.change : true
    let hiddenData = this.hidden !== undefined ? this.hidden : false
    let id_list = []
    if (this.id_textBefore) {
      id_list.push(this.id_textBefore)
    }
    if (this.id_svgPath) {
      id_list.push(this.id_svgPath)
    }
    if (this.id_textAfter) {
      id_list.push(this.id_textAfter)
    }
    let _textBefore = this.getT18nData(this.textBefore, this.id_textBefore)
    let _textAfter = this.getT18nData(this.textAfter, this.id_textAfter)
    let _svgPath = this.valueDict(this.svgPath, this.id_svgPath)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let cont_item of content) {
          if (cont_item['id_content'] === parseInt(this.id_textBefore)) {
            _textBefore['value_rus'] = cont_item['value_rus']
            _textBefore['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_svgPath)) {
            _svgPath['value_rus'] = cont_item['value_rus']
            _svgPath['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_textAfter)) {
            _textBefore['value_rus'] = cont_item['value_rus']
            _textBefore['value_eng'] = cont_item['value_eng']
          }
        }
        this.checkContent = true
      })
    return {
      checkContent: false,
      deleted: false,
      addedData: addedData,
      changeData: changeData,
      hiddenData: hiddenData,
      textBeforeData: _textBefore,
      textAfterData: _textAfter,
      svgPathData: _svgPath
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      if (i18n_data == '') {
        return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
      }
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': id_content }
    },
    valueDict (value, id_content) {
      return { 'value_rus': value,
        'value_eng': value,
        'id_content': id_content }
    },
    pushChangeContent () {
      let content_to_change = { 'textBefore': this.textBeforeData,
        'textAfter': this.textAfterData,
        'svgPath': this.svgPathData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'FooterIconText',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    },
    deleteAddedContent () {
      this.$store.dispatch('contentChanges/DELETE_ADDED_CONTENT', {
        'id_list': [this.id_textBefore, this.id_svgPath, this.id_textAfter]
      })
      this.deleted = true
    },
    showContent () {
      this.$store.dispatch('contentChanges/SHOW_CHANGED_CONTENT', {
        'id_list': [this.id_textBefore, this.id_svgPath, this.id_textAfter]
      })
      this.hiddenData = false
    },
    hideContent () {
      this.$store.dispatch('contentChanges/HIDE_CHANGED_CONTENT', {
        'id_list': [this.id_textBefore, this.id_svgPath, this.id_textAfter]
      })
      this.hiddenData = true
    }
  },
  computed: {
    textBeforeComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textBeforeData['value_rus'] = this.textBefore }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textBeforeData['value_eng'] = this.textBefore }
      if (this.$i18n.locale == 'ru') { return this.textBeforeData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textBeforeData['value_eng'] }
    },
    textAfterComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.textAfterData['value_rus'] = this.textAfter }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.textAfterData['value_eng'] = this.textAfter }
      if (this.$i18n.locale == 'ru') { return this.textAfterData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.textAfterData['value_eng'] }
    },
    svgPathComputed () {
      if (!this.changeData) {
        this.svgPathData['value_rus'] = this.svgPath
        this.svgPathData['value_eng'] = this.svgPath
      }
      if (this.$i18n.locale == 'ru') { return this.svgPathData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.svgPathData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>


<style scoped>

</style>