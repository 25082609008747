<template>
  <v-toolbar class="no-indentation locale-toolbar">
    <div class="localeLogoPlace">
      <v-btn :to="{ name: 'main',
                params: { main: 'Главная' },
             }"
             icon
             :ripple="false"
             class="logo"
      >
        <img src="../../../public/img/logos/logo22.png"/>
      </v-btn>
    </div>
    <div class="flex-grow-1"></div>
      <v-toolbar-items class="no-indentation">
        <v-btn text v-on:click="changeLocaleRu()">RU</v-btn>
        <span style="padding: 14px 16px">|</span>
        <v-btn text v-on:click="changeLocaleEn()">EN</v-btn>
      </v-toolbar-items>
  </v-toolbar>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  data () {
    return { locale: this.$i18n.locale }
  },
  methods: {
    changeLocaleRu () {
      this.$store.dispatch('lang/setLocale', { 'locale': 'ru' })
      this.$i18n.locale = 'ru'
      this.$emit('update')
    },
    changeLocaleEn () {
      this.$store.dispatch('lang/setLocale', { 'locale': 'en-US' })
      this.$i18n.locale = 'en-US'
      this.$emit('update')
    }
  }
}
</script>
