import Vue from 'vue'

/**
 * Подключаем директивы
 */
const directiveContext = require.context('./directives', false, /.*\.js$/)

directiveContext.keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), directiveContext(file)])
  .forEach(([name, directive]) => Vue.directive(name, directive.default || directive))

/**
 * Подключаем фильтры
 */
const filterContext = require.context('./filters', false, /.*\.js$/)

filterContext.keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), filterContext(file)])
  .forEach(([name, filter]) => Vue.filter(name, filter.default || filter))

/**
 * Подключаем компоненты
 */
const componentContext = require.context('./ui', false, /.*\.vue$/)

componentContext.keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ''), componentContext(file)])
  .forEach(([name, component]) => {
    Vue.component(name, component.default || component)
  })
