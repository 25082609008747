import i18n from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common['Authorization'] =
        `Token ${token}` // `Bearer ${token}`
  }

  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }
  // request.headers['X-Socket-Id'] = Echo.socketId()
  if (process.env.NODE_ENV !== 'production') {
    request.baseURL = 'http://127.0.0.1:8000'
  } else {
    request.baseURL = ''
  }
  return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const { status } = error.response
  // Ошибка сервера
  if (status >= 500) {
    store.dispatch('error/SET_MSG', i18n.t('message.server_error'))
    store.dispatch('error/SHOW_ERROR')
  }
  // Страничка не найдена
  if (status === 404) {
    router.push({ name: '404' })
  }

  if (status === 401 && store.getters['auth/check']) {
    store.dispatch('error/SET_MSG', i18n.t('message.auth_error'))
    store.dispatch('error/SHOW_ERROR')
  }

  return Promise.reject(error)
})
