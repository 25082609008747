<template>
  <div>
    <v-container v-for="certificate in product_certificates"
                 :key="certificate.certificate_id"
                 fluid
                 class="no-indentation"
    >
      <v-row class="no-indentation">
        <v-col cols="6" class="sertificate-swiper-top">
          <swiper :options="swiperOptionTop"
                  :ref="'swiperTop'+certificate.certificate_id"
          >
            <swiper-slide v-for="img in certificate.img_urls"
                          :key="img.page_num"
            >
              <img :src="img.dataurl" style="width: inherit" />
            </swiper-slide>
            <div class="swiper-button-next swiper-button-next__sertificate swiper-button-white"
                 slot="button-next"
            />
            <div class="swiper-button-prev swiper-button-prev__sertificate swiper-button-white"
                 slot="button-prev"
            />
          </swiper>
          <swiper :options="swiperOptionThumbs"
                  :ref="'swiperThumbs'+certificate.certificate_id"
                  class="gallery-thumbs"
          >
            <swiper-slide v-for="img in certificate.img_urls" :key="img.page_num">
              <v-img :src="img.dataurl" />
            </swiper-slide>
          </swiper>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols = 12 class="sertificate-file-href">
          <a :href="certificate.file" :download="certificate.file">
            {{$t('message.productSertificateDownloadLabel')}} {{certificate.file_name}}
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'ProductSertificate',
  components: { swiper, swiperSlide },
  computed: {
    product_certificates () {
      return this.$store.state['products']['current']['certificate']
    }
  },
  data () {
    return {
      swiperOptionTop: {
        loop: true,
        spaceBetween: 10,
        loopedSlides: 2,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        grabCursor: true,
        progressbarOpposite: true
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 2,
        spaceBetween: 8,
        slidesPerView: 3,
        touchRatio: 0.2,
        centeredSlides: true,
        slideToClickedSlide: true,
        grabCursor: true,
        progressbarOpposite: true,
        direction: 'horizontal'
      }
    }
  },
  methods: {
    get_file_name (certificateUrl) {
      return certificateUrl.split('/').slice(-1)[0]
    }
  },
  mounted () {
    this.$nextTick(() => {
      for (let sertificate of this.$store.state['products']['current']['certificate']) {
        const swiperTop = this.$refs['swiperTop' + sertificate.certificate_id][0].swiper
        const swiperThumbs = this.$refs['swiperThumbs' + sertificate.certificate_id][0].swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      }
    })
  }
}
</script>
