import axios from 'axios'

// state
export const state = {
  feedback: false,
  feedbackLoading: false,
  feedbackLoad: false,
  feedbackStatus: 'ready',
  work: false,
  workformLoading: false,
  workformLoad: false,
  workformStatus: 'ready',
  offergoodsformLoading: false,
  offergoodsformLoad: false,
  offergoodsformStatus: 'ready',
  reportformLoading: false,
  reportformLoad: false,
  reportformStatus: 'ready'
}

// getters
export const getters = {
  feedback: state => state.feedback,
  feedbackLoading: state => state.feedbackLoading,
  feedbackLoad: state => state.feedbackLoad,
  feedbackStatus: state => state.feedbackStatus,
  work: state => state.work,
  workformLoading: state => state.workformLoading,
  workformLoad: state => state.workformLoad,
  workformStatus: state => state.workformStatus,
  offergoodsformLoad: state => state.offergoodsformLoad,
  offergoodsformLoading: state => state.offergoodsformLoading,
  offergoodsformStatus: state => state.offergoodsformStatus,
  reportformLoading: state => state.reportformLoading,
  reportformLoad: state => state.reportformLoad,
  reportformStatus: state => state.reportformStatus
}

// mutations
export const mutations = {
  'SHOW_LOADER' (state, { form, value }) { state[form + 'Load'] = value },
  'SHOW_FORM' (state, { form, value }) { state[form] = value },
  'SET_STATUS' (state, { form, value }) { state[form + 'Status'] = value },
  'SET_LOADING' (state, { form, value }) { state[form + 'Loading'] = value }
}

// actions
export const actions = {
  'SEND_FEDBACK': async function ({ commit }, { name, email, text }) {
    commit('SET_LOADING', { 'form': 'feedback', 'value': true })
    axios.post('/api/feed_back', {
      name: name,
      email: email,
      text: text
    })
      .then(function (response) {
        commit('SET_LOADING', { 'form': 'feedback', 'value': false })
        commit('SHOW_FORM', { 'form': 'feedback', 'value': false })
        commit('SHOW_LOADER', { 'form': 'feedback', 'value': true })
        commit('SET_STATUS', { 'form': 'feedback', 'value': response['data']['send'] })
      })
      .catch(function (error) {
        commit('SET_LOADING', { 'form': 'feedback', 'value': false })
        console.log(error)
      })
  },
  'SEND_REPORTFORM': async function ({ commit }, { name, contactName, email, phone, text }) {
    commit('SET_LOADING', { 'form': 'reportform', 'value': true })
    axios.post('/api/report', {
      name: name,
      contactName: contactName,
      email: email,
      phone: phone,
      text: text
    })
      .then(function (response) {
        commit('SET_LOADING', { 'form': 'reportform', 'value': false })
        commit('SHOW_LOADER', { 'form': 'reportform', 'value': true })
        commit('SET_STATUS', { 'form': 'reportform', 'value': response['data']['send'] })
      })
      .catch(function (error) {
        commit('SET_LOADING', { 'form': 'reportform', 'value': false })
        console.log(error)
      })
  },
  'SEND_OFFERGOODSFORM': async function ({ commit }, { name, company, site, region, contactName, phone, email, text, files }) {
    var formData = new FormData()
    formData.append('name', name)
    formData.append('company', company)
    formData.append('site', site)
    formData.append('region', region)
    formData.append('contactName', contactName)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('text', text)
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i], files[i].name)
    }
    commit('SET_LOADING', { 'form': 'offergoodsform', 'value': true })
    await axios.post('/api/offer_goods', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(function (response) {
        commit('SET_LOADING', { 'form': 'offergoodsform', 'value': false })
        commit('SHOW_LOADER', { 'form': 'offergoodsform', 'value': true })
        commit('SET_STATUS', { 'form': 'offergoodsform', value: response['data']['send'] })
      })
      .catch(function (error) {
        commit('SET_LOADING', { 'form': 'offergoodsform', 'value': false })
        console.log(error)
      })
  },
  'SEND_WORKFORM': async function ({ commit }, { fio, vacantion, city, phone, email, text, files }) {
    var formData = new FormData()
    formData.append('fio', fio)
    formData.append('vacantion', vacantion)
    formData.append('city', city)
    formData.append('phone', phone)
    formData.append('email', email)
    formData.append('text', text)
    for (var i = 0; i < files.length; i++) {
      formData.append('files[]', files[i], files[i].name)
    }
    commit('SET_LOADING', { 'form': 'workform', 'value': true })
    axios.post('/api/work', formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
      .then(function (response) {
        commit('SET_LOADING', { 'form': 'workform', 'value': false })
        commit('SHOW_LOADER', { 'form': 'workform', 'value': true })
        commit('SET_STATUS', { 'form': 'workform', value: response['data']['send'] })
      })
      .catch(function (error) {
        commit('SET_LOADING', { 'form': 'workform', 'value': false })
        console.log(error)
      })
  },
  'SHOW_FORM': async function ({ commit }, form) {
    commit('SHOW_FORM', { 'form': form, 'value': true })
  },
  'HIDE_FORM': async function ({ commit }, form) {
    commit('SHOW_FORM', { 'form': form, 'value': false })
  },
  'SET_READY': async function ({ commit }, form) {
    commit('SET_STATUS', { 'form': form, 'value': 'ready' })
  }
}
