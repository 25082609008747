import axios from 'axios'

export const state = {
  content: [],
  content_to_change: {},
  type_to_change: '',
  added_content: [],
  hide_content: false,
  hidden_content: []
}

export const getters = {
  content: state => state.content,
  content_to_change: state => state.content_to_change,
  type_to_change: state => state.type_to_change,
  added_content: state => state.added_content,
  last_added_content: state => state.added_content[state.added_content.length-1],
  added_content_by_type: state => type => state.added_content.filter(content => content.type === type),
  hide_content: state => state.hide_content,
  hidden_content: state => state.hidden_content,
}

export const mutations = {
  SET_CONTENT (state, { value }) {
    state.content = value
  },
  SET_CONTENT_TO_CHANGE (state, { value }) {
    state.content_to_change = value
  },
  SET_TYPE_TO_CHANGE (state, { value }) {
    state.type_to_change = value
  },
  SET_ADDED_CONTENT (state, { value }){
    state.added_content = value
  },
  ADD_ADDED_CONTENT (state, { value }){
    state.added_content.push(value)
  },
  DELETE_ADDED_CONTENT (state, { id_list }) {
    let added_content=state.added_content
    for (let i in added_content){
      if (!added_content[i]){ continue }
      let checked= false
      while (!checked) {
        checked = true
        if (added_content[i]['changes'].length != 0) {
          for (let j in added_content[i]['changes']) {
            if (id_list.includes(added_content[i]['changes'][j]['id_content'])){
              added_content[i]['changes'].splice(j, 1)
              checked = false
              break
            }
          }
        }
      }
    }
    let checked = false
    while (!checked) {
      checked = true
      for (let i in added_content){
        if (!added_content[i]){ continue }
        if (added_content[i]['changes'].length==0){
          added_content.splice(i, 1)
          checked = false
          break
        }
      }
    }
  },
  SHOW_CHANGED_CONTENT (state){
    state.hide_content=false
  },
  HIDE_CHANGED_CONTENT (state) {
    state.hide_content=true
  },
  SET_HIDDEN_CONTENT (state, { value }) {
    state.hidden_content=value
  }
}

export const actions = {
  'GET_CONTENT': async function ({ commit }, { id_list }) {
    await axios.post('/api/client_change_list', {
      'id_list': id_list
    }).then(function (response) {
      commit('SET_CONTENT', { value: response['data']['result'] })
    }).catch(function (error) {
      console.log(error)
    })
  },
  'SAVE_CONTENT_CHANGES': async function ({ commit }, { valueList }) {
    axios.post('/api/update_client_change', {
      value_list: valueList
    }).then(function (response) {
      let fileList = valueList.filter(value => typeof value['file'] === 'object')
      let axios_list= []
      for (let file_value of fileList) {
        let form_data = new FormData()
        form_data.append('id_content', file_value['id_content'])
        form_data.append('file', file_value['file'])
        let file_axios = axios.post('/api/update_client_change_file', form_data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        axios_list.push(file_axios)
      }
      Promise.all(axios_list).catch(function (error) {
        console.log(error)
      })
      commit('SET_CONTENT', { value: response['data']['result'] })
    }).catch(function (error) {
      console.log(error)
    })
  },
  'SET_CONTENT_TO_CHANGE': async function ({ commit }, { type_to_change, content_to_change }) {
    commit('SET_CONTENT_TO_CHANGE', { value: content_to_change })
    commit('SET_TYPE_TO_CHANGE', { value: type_to_change })
  },
  'SET_TYPE_TO_CHANGE': async function ({ commit }, { value }) {
    commit('SET_TYPE_TO_CHANGE', { value: value })
  },
  'ADD_NEW_CONTENT': async function ({ commit }, {type, field_list}){
    await axios.post('/api/add_client_change_new', {
      type: type,
      field_list: field_list
    }).then(function (response) {
      commit('ADD_ADDED_CONTENT', { value: response['data']['result'] })
    }).catch(function (error) {
      console.log(error)
    })
  },
  'GET_ADDED_CONTENT': async function ({ commit }, { type }) {
    await axios.post('/api/list_client_change_new',{
      type: type
    }).then(function (response) {
      commit('SET_ADDED_CONTENT', { value: response['data']['result'] })
    }).catch(function (error) {
      console.log(error)
    })
  },
  DELETE_ADDED_CONTENT: async function ({ commit }, { id_list }){
    axios.post('/api/delete_client_change_new',{
      id_list: id_list
    }).then(function (response){
      commit('DELETE_ADDED_CONTENT', {id_list: id_list})
    }).catch(function (error){
      console.log(error)
    })
  },
  HIDE_CHANGED_CONTENT: async function ({ commit }, { id_list }){
    axios.post('/api/add_client_change_hidden', {
      id_list: id_list
    }).then(function (){
      commit("HIDE_CHANGED_CONTENT")
    }).catch(function (error){
      console.log(error)
    })
  },
  SHOW_CHANGED_CONTENT: async function ({ commit }, { id_list }){
    axios.post('/api/delete_client_change_hidden', {
      id_list: id_list
    }).then(function (){
      commit("SHOW_CHANGED_CONTENT")
    }).catch(function (error){
      console.log(error)
    })
  },
  CHECK_HIDDEN_CONTENT: async function ({ commit }, { id_list }){
    await axios.post('/api/check_client_change_hidden',{
      id_list: id_list
    }).then(function (response){
      if (response['data']['result']){
        commit("HIDE_CHANGED_CONTENT")
      }
      else {
        commit("SHOW_CHANGED_CONTENT")
      }
    }).catch(function (error){
      console.log(error)
    })
  },
  CHECK_HIDDEN_CONTENT_LIST: async function ({ commit }, { id_list }){
    await axios.post('/api/check_client_change_hidden_list',{
      id_list: id_list
    }).then(function (response){
      commit("SET_HIDDEN_CONTENT",{value: response['data']['result']})
    }).catch(function (error){
      console.log(error)
    })
  }
}
