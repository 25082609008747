<template>
   <v-card class="mx-auto borderCard"
           width="100%"
           height="100%"
           outlined
   >
       <v-list-item two-line class="pt-lg-12 pt-md-11 pt-sm-12 pt-4 text-center">
           <v-list-item-content>
          <v-list-item-title class="headlineCardSlogan mt-4 mt-lg-4 mt-sm-4">{{ titleComputed }}</v-list-item-title>
          <v-list-item-subtitle class="subtitleCardSlogan mt-4 mb-4 mt-lg-8 mb-lg-12 mt-sm-8 mb-sm-6">"{{ subTitleComputed }}"</v-list-item-subtitle>
          <div v-if="isAuth">
            <v-btn v-if="changeData" @click="pushChangeContent">Изменить</v-btn>
          </div>
        </v-list-item-content>
       </v-list-item>
    </v-card>
</template>
<script>
import '../../plugins/i18n.js'
export default {
  props: [
    'title',
    'subTitle',
    'id_title',
    'id_subTitle',
    'change'
  ],
  data () {
    let changeData = this.change !== undefined ? this.change : true
    let id_list = [this.id_title,
      this.id_subTitle
    ].filter(id => id !== undefined)
    let _title = this.getT18nData(this.title, this.id_title)
    let _subTitle = this.getT18nData(this.subTitle, this.id_subTitle)
    this.$store.dispatch('contentChanges/GET_CONTENT', { 'id_list': id_list })
      .then(() => {
        let content = this.$store.getters['contentChanges/content']
        for (let cont_item of content) {
          if (cont_item['id_content'] === parseInt(this.id_title)) {
            _title['value_rus'] = cont_item['value_rus']
            _title['value_eng'] = cont_item['value_eng']
          }
          if (cont_item['id_content'] === parseInt(this.id_subTitle)) {
            _subTitle['value_rus'] = cont_item['value_rus']
            _subTitle['value_eng'] = cont_item['value_eng']
          }
        }
      })
    return {
      changeData: changeData,
      titleData: _title,
      subTitleData: _subTitle,
      absolute: true,
      overlay: false
    }
  },
  methods: {
    getT18nData (i18n_data, id_content) {
      let ru_value = Object.entries(this.$i18n.messages['ru']['message']).filter(value => value[1] == i18n_data)
      if (ru_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][ru_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][ru_value[0][0]],
          'id_content': id_content
        }
      }
      let eng_value = Object.entries(this.$i18n.messages['en-US']['message']).filter(value => value[1] == i18n_data)
      if (eng_value.length != 0) {
        return { 'value_rus': this.$i18n.messages['ru']['message'][eng_value[0][0]],
          'value_eng': this.$i18n.messages['en-US']['message'][eng_value[0][0]],
          'id_content': id_content
        }
      }
      return { 'value_rus': i18n_data, 'value_eng': i18n_data, 'id_content': this.id_content }
    },
    pushChangeContent () {
      let content_to_change = {
        'title': this.titleData,
        'subTitle': this.subTitleData
      }
      this.$store.dispatch('contentChanges/SET_CONTENT_TO_CHANGE', {
        'type_to_change': 'CardsWithSlogan',
        'content_to_change': content_to_change
      })
      this.$router.push('/change_content')
    }
  },
  computed: {
    titleComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.titleData['value_rus'] = this.title }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.titleData['value_eng'] = this.title }
      if (this.$i18n.locale == 'ru') { return this.titleData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.titleData['value_eng'] }
    },
    subTitleComputed () {
      if (!this.changeData & this.$i18n.locale == 'ru') { this.subTitleData['value_rus'] = this.subTitle }
      if (!this.changeData & this.$i18n.locale == 'en-US') { this.subTitleData['value_eng'] = this.subTitle }
      if (this.$i18n.locale == 'ru') { return this.subTitleData['value_rus'] }
      if (this.$i18n.locale == 'en-US') { return this.subTitleData['value_eng'] }
    },
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>
