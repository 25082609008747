<template>
  <div>
    <p v-if="lang=='ru'" class="slider-category">
      {{ this.$props.name_group_rus }}
    </p>
    <p v-else class="slider-category">
      {{ this.$props.name_group_en }}
    </p>
    <v-slide-group
        show-arrows="always"
        class="slider-card">
      <v-slide-item v-for="item in products"
                    v-slot="{ active }"
                    :key="item.id"
      >
        <v-card class="ma-4 slider-item__card"
                :to="{ name: 'products_page' ,params: {id_product: item.id}}"
                :ripple="false"
        >
          <v-container fluid>
            <v-row>
              <v-col cols="5"
                     class="slider-item__card-img pa-0 pl-2"
              >
                <v-img v-if="item.photo"
                       :src="item.photo"
                />
                <v-img v-else
                       src="../../../public/img/no-photo.png"
                />
              </v-col>
              <v-col cols="7"
                     class="slider-item__card-text pa-0 pr-2 pl-4"
              >
                <div v-if="lang==='ru'">{{ item.name_rus }}</div>
                <div v-if="lang==='en-US'">{{ item.name_en }}</div>
                <div class="order">
                  <v-btn text
                         :ripple="false"
                         :to="{ name: 'products_page', params: {id_product: item.id} }"
                  >
                    {{$t('message.productsSliderProductsLabel1')}}
                  </v-btn>
                  <v-btn icon
                         :to="{ name: 'products_page', params: {id_product: item.id} }"
                  >
                    <v-icon>{{ svgBtnPath1 }}</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import '../../plugins/i18n'
import 'swiper/dist/css/swiper.css'
import { mdiCartPlus } from '@mdi/js'
export default {
  data () {
    this.$store.dispatch('slidersProducts/SET_SLIDER', { 'slider': 'cardio', 'filters': [1] })
    return {
      svgBtnPath1: mdiCartPlus
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    }
  },
  props: ['id_group', 'name_group_en', 'name_group_rus', 'products'],
  watch: {
    lang1 () { return this.$i18n.locale }
  }
}
</script>
